import onmount from 'onmount'
import $ from 'jquery'
import Sortable from 'sortablejs'
import '@selectize/selectize/dist/js/selectize.min.js'

onmount('.js-channels-list-fields', function() {
  const customChannelContainer = this.querySelector('.js-custom-channel')
  const selectizeAddGroup = customChannelContainer.querySelector('.selectize-add')
  const selectizeHelper = selectizeAddGroup.querySelector('.js-helper')

  $(this).find('.js-mode-selectize').selectize({
    onChange: function(value) {
      if (value === 'custom') {
        customChannelContainer.classList.remove('hidden')
      } else if (value === 'latest') {
        customChannelContainer.classList.add('hidden')
      }
    }
  })

  const container = this
  let selectValue = ''
  const staticSelectize = $(this).find('.js-select-page').selectize({
    maxItems: 1,
    onChange: function(value) {
      if (!value) {
        return
      }

      const opt = container.querySelector(`.option[data-value="${value}"]`)
      const parent = opt?.parentNode
      selectValue = {id: value, title: opt?.innerText, channel: parent?.dataset.group}
    }
  })
  const selectApi = staticSelectize[0].selectize

  // Empty the select field by default
  selectApi.clear()

  const addButton = this.querySelector('.js-add-page')
  const sortableContainer = this.querySelector('#sortable')

  const initializeSortable = () => new Sortable(sortableContainer, {
    filter: '.close',
    onFilter: function(evt) {
      const parent = evt.target.parentNode
      parent.remove()
      initializeSortable()
    }
  })

  const checkItemPresence = (id) => {
    const item = sortableContainer.querySelector(`.item[data-id="${id}"]`)
    return item
  }

  initializeSortable()

  addButton.addEventListener('click', () => {
    if (!selectValue) {
      selectizeHelper.innerText = 'Please select an item'
      selectizeHelper.classList.remove('hidden')
      return
    }

    // Check if item is already added
    if (checkItemPresence(selectValue.id)) {
      selectizeHelper.innerText = 'You already added this item'
      selectizeHelper.classList.remove('hidden')
      return
    } else {
      !selectizeHelper.classList.contains('hidden') && selectizeHelper.classList.add('hidden')
    }
    
    const icon = '<svg class="svg icon close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>Close</title><polygon points="21.53 3.53 20.47 2.47 12 10.94 3.53 2.47 2.47 3.53 10.94 12 2.47 20.47 3.53 21.53 12 13.06 20.47 21.53 21.53 20.47 13.06 12 21.53 3.53"/></svg>'
    sortableContainer.insertAdjacentHTML('beforeend',
      `
        <div class="item sortable-item" data-id=${selectValue.id}>
          <input type="hidden" name="channel_page_module[channels_list_module_configuration][page_ids][]" value=${selectValue.id} />
          <div class="title">
            <div class="name">${selectValue.title}</div>
            <div class="sub">${selectValue.channel}</div>
          </div>
          ${icon}
        </div>
      ` 
    )
    
    initializeSortable()
    selectApi.clear()
    selectValue = ''
  })

})
