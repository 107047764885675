import { ConfirmDialog } from '../confirmable';
import { Dialog } from '../dialog';
import { humanFileSize } from '../utils';

/**
 * Sub-dialog that allows configuring an image attachment for a post
 *
 * In order to keep everything in sync, only use the open and close methods on
 * this element and not the global dialog helpers.
 */
export class AddImageDialog extends Dialog {
  private readonly actionClose: Element;
  private readonly actionConfirm: Element;
  private readonly actionDismiss: Element;

  private readonly inputDescription: HTMLInputElement;

  private file: File | undefined;

  private targetPreview: Element;
  private targetFilename: Element;
  private targetFilesize: Element;

  constructor(
    private readonly component: HTMLElement,
    private readonly parent: Dialog & {
      setImage(description: string, previewUrl: string): void;
      clearImage(): void;
    }
  ) {
    super(component.id);

    this.actionClose = this.component.querySelector('[data-action="close"]')!;
    this.actionDismiss = this.component.querySelector(
      '[data-action="dismiss"]'
    )!;
    this.actionConfirm = this.component.querySelector(
      '[data-action="confirm"]'
    )!;

    this.targetPreview = this.component.querySelector(
      '[data-target="image-preview"]'
    )!;
    this.targetFilename = this.component.querySelector(
      '[data-target="filename"]'
    )!;
    this.targetFilesize = this.component.querySelector(
      '[data-target="filesize"]'
    )!;

    this.inputDescription = this.component.querySelector<HTMLInputElement>(
      'input[data-target="description"]'
    )!;

    this.open = this.open.bind(this);
    this.cancel = this.cancel.bind(this);
    this.close = this.close.bind(this);
    this.destroy = this.destroy.bind(this);

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);

    this.actionDismiss.addEventListener('click', this.cancel);
    this.actionClose.addEventListener('click', this.cancel);
    this.actionConfirm.addEventListener('click', this.onConfirm);

    const form = this.component.closest<HTMLFormElement>('form')!;
    form.addEventListener('submit', this.onFormSubmit);
  }

  public get autofocus(): HTMLElement {
    return this.component.querySelector('input')!;
  }

  public destroy(): void {
    this.actionDismiss.removeEventListener('click', this.cancel);
    this.actionClose.removeEventListener('click', this.cancel);
    this.actionConfirm.removeEventListener('click', this.onConfirm);

    const form = this.component.closest<HTMLFormElement>('form')!;
    form.removeEventListener('submit', this.onFormSubmit);
  }

  /**
   * Opens itself, attempting to replace dialogs that are already out there,
   * namely the "base" create post dialog.
   *
   * @param source the source of opening the dialog, usually a button.
   */
  public open(
    source?: string | Node,
    focusFirst?: string | Node,
    clearOnOpen: boolean = false
  ): this {
    console.debug(`[new-post] add image`);

    // Can't open without a file
    if (!this.file) {
      return this;
    }

    return super.open(source, focusFirst, clearOnOpen);
  }

  /**
   * When closing this dialog, we want to go back to the original create post
   * dialog, instead of closing out everything.
   */
  public close(
    event?: Event,
    replaced: boolean = false,
    destroyed: boolean = false
  ): boolean {
    console.debug('[close] add image dialog', this);

    this.file = undefined;
    this.inputDescription.value = '';

    if (super.close(event, replaced, destroyed)) {
      this.parent.open();
      return true;
    }

    return false;
  }

  public cancel(): void {
    const dialog = new ConfirmDialog(
      'discard-image-dialog',
      this.inputDescription
    );

    // When it's confirmed, clear this (removing the dirty flag)
    dialog.onConfirm = (): void => {
      this.parent.clearImage();

      this.clear();
      this.close(undefined, false);
    };

    dialog.open(this.inputDescription);
  }

  public clear(): void {
    super.clear();
  }

  public withFile(file: File): this {
    this.file = file;

    // Remove old image, if any
    this.targetPreview.querySelector('img')?.remove();

    // Set metadata
    this.targetFilename.textContent = file.name;
    this.targetFilesize.textContent = humanFileSize(file.size);

    // Set image preview
    const image = document.createElement('img');
    image.src = URL.createObjectURL(file);
    this.targetPreview.prepend(image);

    return this;
  }

  private onFormSubmit(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }

  private onConfirm(e: Event): void {
    const image = this.targetPreview.querySelector<HTMLImageElement>('img')!;
    this.parent.setImage(this.inputDescription!.value || '', image.src);

    this.close();
  }

  public get id(): string {
    return this.component.id;
  }

  public get isOpen(): boolean {
    return !this.component.hasAttribute('hidden');
  }
}
