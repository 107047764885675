import { Controller } from '@hotwired/stimulus';
import { AutoRemoveDialog } from '../src/brandless/components/AutoRemoveDialog';
import { upgradeAll } from '../src/brandless/ujs';

export default class extends Controller {
  static targets = ['button', 'content'];

  open() {
    const originalDialog = document.getElementById('add-event-to-calendar');
    const dialogClone = originalDialog.cloneNode(true);
    dialogClone.id = `add-event-${this.element.dataset.eventId}-to-calendar`;

    document.body.appendChild(dialogClone);
    const dialog = new AutoRemoveDialog(dialogClone.id);
    const dialogContent = dialogClone.querySelector('[data-content]');
    dialogContent.innerHTML = this.contentTarget.innerHTML;

    dialog.open(this.buttonTarget);
    upgradeAll();
  }
}
