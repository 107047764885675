import { Controller } from '@hotwired/stimulus';
import { useDebounce, useThrottle } from 'stimulus-use';

export default class extends Controller {
  static targets = ['liveVideoContainer'];
  static debounces = ['fixDirectMessagingOverlap'];
  static throttles = ['switchBackToInfoTab'];

  connect() {
    useDebounce(this, { wait: 200 });
    useThrottle(this, { wait: 200 });

    this.messagingContainer = document.querySelector('#messaging-container');
    if (this.messagingContainer) {
      this.fixDirectMessagingOverlap();
    }
  }

  disconnect() {
    if (!this.messagingContainer) return;
    this.messagingContainer.style.removeProperty('right');
    this.messagingContainer.style.removeProperty('width');
  }

  fixDirectMessagingOverlap() {
    if (!this.messagingContainer) return;

    if (window.innerWidth > 768) {
      this.messagingContainer.style.right = '260px';
      this.messagingContainer.style.width = 'calc(100% - 260px)';
    } else {
      this.messagingContainer.style.removeProperty('right');
      this.messagingContainer.style.removeProperty('width');
    }
  }

  expandMessages() {
    this.messagingContainer.style.removeProperty('right');
    this.messagingContainer.style.removeProperty('width');
  }

  scaleDownMessages() {
    if (window.innerWidth > 768) {
      this.messagingContainer.style.right = '260px';
      this.messagingContainer.style.width = 'calc(100% - 260px)';
    } else {
      this.messagingContainer.style.removeProperty('right');
      this.messagingContainer.style.removeProperty('width');
    }
  }

  switchBackToInfoTab() {
    if (window.innerWidth > 768) {
      this.liveVideoContainerTarget.setAttribute('data-tabs-index', 0);
      const tabsController =
        this.application.getControllerForElementAndIdentifier(
          this.liveVideoContainerTarget,
          'tabs'
        );
      tabsController.showTab();
    }
  }
}
