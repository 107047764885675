import onmount from 'onmount'
import $ from 'jquery'

onmount('.js-channel-form', function(mountEvent) {
  const channelForm = this

  $(channelForm).find('.js-plain-selectize').selectize({
    maxItems: null,
  })
})
