import Glider from 'glider-js'
import onmount from 'onmount'

type RailsBreakpoint = {
  breakpoint: number
  settings: {
    slidesToShow: number
    slidesToScroll: number
  }
}

type RailsSetup = RailsBreakpoint[]

function defaultSetup(isConnect: boolean): RailsSetup {
  return [{
    breakpoint: 768,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3
    }
  },{
    breakpoint: 1200,
    settings: {
      slidesToShow: isConnect ? 3 : 4,
      slidesToScroll: isConnect ? 3 : 4,
    }
  }, {
    breakpoint: 1600,
    settings: {
      slidesToShow: isConnect ? 3 : 4,
      slidesToScroll: isConnect ? 3 : 4
    }
  }]
}

function videoRail(parent: HTMLElement, slidesCount?: RailsSetup): void {
  const sideNavToggle = document.querySelector('.js-toggle-width')
  const attribute = parent.getAttribute('data-type')
  const isEpisode = attribute == 'episode' ? true : false
  const isConnect = attribute == 'connect' ? true : false
  const prev = parent.querySelector('.glider-prev')
  const prevId = prev.getAttribute('id')
  const next = parent.querySelector('.glider-next')
  const nextId = next.getAttribute('id')

  const content = parent.querySelector('.glider')

  const railSetup = slidesCount ? slidesCount : defaultSetup(isConnect)

  // Hides previous button by default
  prev.classList.add('-hide')

  const glider = new Glider(content, {
    slidesToShow: 1,
    slidesToScroll: 1,
    rewind: false,
    skipTrack: true,
    scrollPropagate: true,
    arrows: {
      prev: `#${prevId}`,
      next: `#${nextId}`
    },
    responsive: railSetup
  });

  // Fixes stacking behavior when back button is clicked
  const cards = parent.querySelectorAll('.rail-card');
  if (cards.length == 0) {
    console.debug("No cards in rail-container", parent);
    return;
  }
  const gliderTrack = parent.querySelector('.glider-track');
  const gliderWidth = (cards.length * cards[0].scrollWidth) + (16 * (cards.length - 1))

  if (window.innerWidth < 992) {
    gliderTrack.setAttribute('style', 'width:' + gliderWidth + 'px')
  }

  window.addEventListener('resize', function() {
    const isMobile = window.innerWidth < 768
    const gutter = isMobile ? 12 : 16
    const gliderWidth = (cards.length * cards[0].scrollWidth) + (gutter * (cards.length - 1))
    if (window.innerWidth < 992) {
      gliderTrack.setAttribute('style', 'width:' + gliderWidth + 'px')
    }
  })

  function toggleSliderButtons() {
    function defaultSlides() {
      const width = window.innerWidth
      if (width >= 992 && width < 1200) {
        return 3
      } else if (width >= 1200 && width < 1600) {
        return isConnect ? 3 : 4
      } else if (width >= 1600) {
        return isConnect ? 3 : isEpisode ? 4 : 5
      }
    }

    const slidesLength = defaultSlides()

    const totalCards = cards.length
    const nextMaxCycle = Math.ceil((totalCards / slidesLength) - 1)
    let currNext = 0

    // Hides next arrow if number of cards is lest than the length of slides
    if (totalCards <= slidesLength) {
      next.classList.add('-hide')
    } else {
      next.classList.remove('-hide')
    }

    function togglePrev() {
      if (currNext !== 0) {
        prev.classList.remove('-hide')
      } else {
        prev.classList.add('-hide')
      }
    }

    function toggleNext() {
      if (currNext === nextMaxCycle) {
        next.classList.add('-hide')
      } else {
        next.classList.remove('-hide')
      }
    }

    prev.addEventListener('click', function() {
      currNext -= 1
      togglePrev()
      toggleNext()
    })

    next.addEventListener('click', function() {
      currNext += 1
      toggleNext()
      togglePrev()
    })
  }

  toggleSliderButtons()

  window.addEventListener('resize', function() {
    toggleSliderButtons()
  })

  // This maintains the video thumbnail's ratio to 16x9 in any breakpoint
  cards.forEach(function(card) {
    const img = card.querySelector('.thumbnail');
    const imgUnit = img.scrollWidth / 16
    const imgHeight = imgUnit * 9
    prev.setAttribute('style', 'height:' + imgHeight + 'px')
    next.setAttribute('style', 'height:' + imgHeight + 'px')
    img.setAttribute('style', 'height:' + imgHeight + 'px');

    window.addEventListener('resize', function() {
      const imgUnit = img.scrollWidth / 16
      const imgHeight = imgUnit * 9

      prev.setAttribute('style', 'height:' + imgHeight + 'px')
      next.setAttribute('style', 'height:' + imgHeight + 'px')
      img.setAttribute('style', 'height:' + imgHeight + 'px');
    })
  })

  sideNavToggle.addEventListener('click', () => {
    const gliderSlides = parent.querySelectorAll('.glider-slide')
    const gliderSlide = gliderSlides[0]
    const style = window.getComputedStyle(gliderSlide)
    const width = style.getPropertyValue('width')
    const railContent = parent.querySelector('.rail-content')
    // TODO: Fix. js-app-container does not exist anymore
    const appContainer = document.querySelector('.js-app-container')
    const isCollapsed = appContainer.classList.contains('-collapsed')

    // Prevents from continuing when breakpoint is mobile or tablet
    if (window.innerWidth < 992) return

    // When triggered
    // 240px - 64px = 176px
    const railContentWidth = isCollapsed ? railContent.scrollWidth + 176 : railContent.scrollWidth - 158
    const railCount = window.innerWidth >= 1200 ? 4 : 3

    gliderSlides.forEach((slide) => {
      const imgWidth = railContentWidth / railCount
      const imgUnit = imgWidth / 16
      const imgHeight = imgUnit * 9
      const img = slide.querySelector('.card-thumbnail')
      slide.setAttribute('style', `height: auto; width: ${railContentWidth / railCount}px;`)
      prev.setAttribute('style', `height: ${imgHeight}px`)
      next.setAttribute('style', `height: ${imgHeight}px`)
      img.setAttribute('style', `height: ${imgHeight}px`)
    })

  })
}
onmount<HTMLElement>('.rail-container', function() {
  videoRail(this)
});


const recommendedVideoRail = [
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3
    }
  }
]

onmount<HTMLElement>('.js-connect-recommended-videos', function() {
  videoRail(
    this,
    recommendedVideoRail
  )
});
