import onmount from 'onmount'

onmount('.js-tab-buttons', function() {
  const buttons = this.querySelectorAll('a')
  const hiddenClass = this.getAttribute('data-hidden-class')
  let contents = []

  if (buttons.length === 0) return

  buttons.forEach(function(button) {
    const href = button.getAttribute('href')
    const targetContents = document.querySelectorAll(href)
    contents = [...contents, ...targetContents as any]

    button.addEventListener('click', function(e) {
      e.preventDefault()

      resetButtons(buttons)
      button.classList.add('-active')

      resetContents(contents, hiddenClass)
      setContents(targetContents, hiddenClass)
    })
  })
})

function resetButtons(buttons) {
  buttons.forEach(function(button) {
    button.classList.remove('-active')
  })
}

function resetContents(contents, hiddenClass) {
  contents.forEach(function(content) {
    content.classList.add(hiddenClass)
  })
}

function setContents(contents, hiddenClass) {
  contents.forEach(function(content) {
    content.classList.remove(hiddenClass)
  })
}

function flatten(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}
