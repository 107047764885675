import onmount from 'onmount'

onmount('.js-accordion-pane', function() {
  const titleTab = this.querySelector('.title')
  const plusIcon = titleTab.querySelector('.icon')
  const body = this.querySelector('.body')
  let isActive = false

  titleTab.addEventListener('click', function(){
    isActive = !isActive 

    if (isActive) {
      plusIcon.classList.add('-active')
      body.setAttribute('style', 'height:' + body.scrollHeight + 'px')
    } else {
      plusIcon.classList.remove('-active')
      body.setAttribute('style', 'height: 0')
    }
  })
})
