import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';
import consumer from '../../src/channels/consumer';
import Rails from '@rails/ujs';
import $ from 'jquery';

const MAX_HEIGHT = 84;
export default class extends Controller {
  static targets = ['textarea', 'messagesContainer', 'form', 'currentUserId'];

  // TODO: Use `stimulus-use` to register an `appear()` callback, which
  // will fire a Rails.ajax call to a LiveVideoChatMessagesController#index
  // that will bring the list of chat messages and `innerHtml` them inside
  // this.element.
  //
  // If you want to do infinite scrolling, then have a look Humphrey is doing
  // it in the `conversation_controller.js` in the Direct Messages branch.
  // It broadly follows this article:
  // https://dev.to/julianrubisch/infinite-horizontal-slider-with-cableready-and-the-intersection-observer-api-4o4i
  connect() {
    useIntersection(this, { threshold: 0 });

    this.subscription = consumer.subscriptions.create(
      {
        channel: 'VideoChatChannel',
        video_slug: this.data.get('video-slug'),
      },
      {
        received: (data) => {
          if (data.action == 'destroy') {
            let $chat = $(this.messagesContainerTarget).find(
              `[data-chat-id=${data.chat_id}]`
            );
            $chat.remove();
          } else if (
            data.action == 'create' &&
            data.sender_id != this.currentUserIdTarget.value
          ) {
            this.messagesContainerTarget.insertAdjacentHTML(
              'beforeend',
              data.html
            );
            this.scrollToBottom();
          }
        },
      }
    );
  }

  loadChatMessages() {
    $.ajax({
      url: this.data.get('url'),
      method: 'GET',
      dataType: 'html',
      success: (html) => {
        this.messagesContainerTarget.innerHTML = html;
        this.scrollToBottom({ immediate: true });
      },
    });
  }

  appear(entry) {
    if (!this.data.has('chats-loaded')) {
      this.loadChatMessages();
      this.data.set('chats-loaded', true);
    }
  }

  messageSent(event) {
    event.preventDefault();
    let [_data, _status, xhr] = event.detail;
    if (!xhr.response) return;
    let chatMessage = JSON.parse(xhr.response).html;
    this.textareaTarget.value = '';
    this.textareaTarget.focus();

    this.messagesContainerTarget.insertAdjacentHTML('beforeend', chatMessage);

    this.setTextareaHeight();
    this.scrollToBottom();
  }

  disconnect() {
    if (this.subscription) consumer.subscriptions.remove(this.subscription);
  }

  focusBackOnInput() {
    this.textareaTarget.focus();
  }

  onKeyDown(event) {
    if (event.keyCode == 13 && !event.shiftKey) {
      event.preventDefault();
      Rails.fire(this.formTarget, 'submit');
    }
  }

  scrollToBottom({ immediate } = {}) {
    const { scrollHeight, clientHeight } = this.messagesContainerTarget;
    const scrollBy = scrollHeight - clientHeight;

    if (scrollHeight >= clientHeight) {
      if (!immediate && this.messagesContainerTarget.scrollBy) {
        this.messagesContainerTarget.scrollBy({
          top: scrollBy,
          behavior: 'smooth',
        });
      } else {
        this.messagesContainerTarget.scrollTop = scrollBy;
      }
    }
  }

  setTextareaHeight(event) {
    this.textareaTarget.style.height = 'inherit';
    const scrollHeight = this.textareaTarget.scrollHeight;

    const height = MAX_HEIGHT > scrollHeight ? scrollHeight : MAX_HEIGHT;
    this.textareaTarget.style.height = `${height}px`;
  }
}
