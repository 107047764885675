import { Controller } from '@hotwired/stimulus';
import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

class CustomImageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.setAttribute('src', value.url);
    node.setAttribute('alt', value.alt || '');
    if (value.customAttributes) {
      Object.keys(value.customAttributes).forEach((attr) => {
        node.setAttribute(`data-${attr}`, value.customAttributes[attr]);
      });
    }
    return node;
  }

  static value(node) {
    let value = {
      url: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
    };
    node.getAttributeNames().forEach((attr) => {
      if (attr.startsWith('data-')) {
        value.customAttributes = value.customAttributes || {};
        value.customAttributes[attr.slice(5)] = node.getAttribute(attr);
      }
    });
    return value;
  }
}

CustomImageBlot.blotName = 'customImage';
CustomImageBlot.tagName = 'img';
Quill.register(CustomImageBlot);

export default class extends Controller {
  connect() {
    const textarea = this.element.querySelector('[data-target="textarea"]');
    const form = this.element.closest('form');
    const submitButton = form?.querySelector('input[type="submit"]');
    console.log('form', form);
    const hiddenInput = this.element.querySelector('input[type="hidden"]');
    const imageUploadUrl = this.element.dataset.imageUploadUrl;
    const imageUploadLoadingState = this.element.querySelector(
      '[data-image-upload-state="loading"]'
    );

    if (!textarea) {
      return;
    }

    const defaultToolbar = [
      [{ header: [false, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ color: [] }, { background: [] }], // Ensure these are correct
      ['link'],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ];

    const toolbarWithImage = [
      [{ header: [false, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ color: [] }, { background: [] }], // Ensure these are correct
      ['link', 'image'],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ];

    const quill = new Quill(textarea, {
      theme: 'snow',
      modules: {
        toolbar: imageUploadUrl ? toolbarWithImage : defaultToolbar,
      },
    });

    quill.root.innerHTML = hiddenInput.value;

    quill.on('text-change', () => {
      hiddenInput.value = quill.root.innerHTML;
    });

    if (!imageUploadUrl) {
      return;
    }

    // Image Uploads

    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('image', () => {
      console.log('handling image upload');
      const range = quill.getSelection();
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      input.addEventListener('change', async () => {
        const file = input.files[0];
        const toolbar = this.element.querySelector('[role="toolbar"]');

        if (file) {
          quill.enable(false);
          toolbar.classList.add('opacity-30', 'cursor-not-allowed');
          textarea.classList.add('opacity-30', 'cursor-not-allowed');
          imageUploadLoadingState.classList.remove('hidden');
          imageUploadLoadingState.classList.add('flex');
          if (submitButton) {
            submitButton.disabled = true;
          }

          const formData = new FormData();
          formData.append('video_page_module_image[image]', file);

          try {
            const response = await fetch(imageUploadUrl, {
              method: 'POST',
              body: formData,
            });
            const result = await response.json();
            if (response.ok) {
              const imageUrl = result.url;

              console.log('result', result);
              quill.insertEmbed(range.index, 'customImage', {
                url: imageUrl,
                alt: '',
                customAttributes: {
                  'image-id': result.id,
                },
              });
            } else {
              console.error(result.errors);
            }
          } catch (error) {
            console.error('Upload failed:', error);
          } finally {
            // Re-enable the editor after uploading
            quill.enable(true);
            toolbar.classList.remove('opacity-30', 'cursor-not-allowed');
            textarea.classList.remove('opacity-30', 'cursor-not-allowed');
            imageUploadLoadingState.classList.add('hidden');
            imageUploadLoadingState.classList.remove('flex');
            if (submitButton) {
              submitButton.disabled = false;
            }
          }
        }
      });

      input.click();
    });
  }
}
