import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    if (this.element.dataset.controller.includes('video--fetch-player')) {
      this.isPlayerRendered = true;
    }

    this.intervalId = setInterval(() => this.getLiveStatus(), 10000);
  }

  disconnect() {
    if (this.isLive) {
      clearInterval(this.intervalId);
    }
  }

  getLiveStatus() {
    $.ajax({
      url: this.element.dataset.playerLiveStatusPath,
      success: (res) => {
        const { live_status } = res;

        console.log(`Live status: ${live_status}`);

        if (live_status === 'current' && !this.isPlayerRendered) {
          this.isPlayerRendered = true;
          console.log('Fetching player!');

          // Add player controller
          this.element.setAttribute(
            'data-controller',
            `${this.element.dataset.controller} video--fetch-player`
          );
        } else if (live_status === 'ended') {
          clearInterval(this.intervalId);
          this.renderPlaceholder(live_status);
        }
      },
      error: (err) => {
        console.log('Error');
      },
    });
  }

  renderPlaceholder(status = 'ended') {
    const imagePath = this.element.getAttribute(`data-placeholder-${status}`);
    const player = `
      <div class="vjs-fluid">
        <div class="vjs-poster" style="background-image: url(${imagePath});"></div>
      </div>
    `;

    this.element.innerHTML = player;
  }
}
