import Tribute from 'tributejs';
import type { TributeCollection } from 'tributejs';
import { register, upgrade } from './ujs';
import { fetchResource } from './fetch';

const DEBOUNCE_IN_MS = 200;

type MentionSuggestion = {
  id: string;
  name: string;
  meta: string | null;
  image_url: string | null | undefined;
  url: string;
  tag: string;
};

type HashtagSuggestion = {
  id: string;
  name: string;
  meta: string;
  url: string;
};

type EmojiSuggestion = {
  name: string;
  unicode: string;
  decorated: string;
};

const TRIBUTE_MENTION: TributeCollection<MentionSuggestion> = {
  trigger: '@',

  iframe: null,

  selectClass: 'highlight',
  containerClass: 'post__autocomplete-options',
  itemClass: 'option',

  selectTemplate: (item): string => {
    // Empty span at end to fix mentions in IE11
    // https://github.com/zurb/tribute/issues/213#issuecomment-513257925

    return `<span
      contenteditable="false"
      class="tag"
      data-type="mention"
      data-value="${item.original.tag}">@${item.original.name}</span><span />`;
  },

  menuItemTemplate: (item): string => {
    return `<img src="${item.original.image_url}"> <span class="type--name">${
      item.string
    }</span><span class="type--meta">${item.original.meta || ''}</span>`;
  },

  noMatchTemplate: undefined,
  lookup: 'name',
  fillAttr: 'name',
  values: (text, cb) => {
    cb([]);
  },
  // loadingItemTemplate: undefined,
  requireLeadingSpace: true,
  allowSpaces: true,
  replaceTextSuffix: '\n',
  positionMenu: true,
  autocompleteMode: false,
  searchOpts: {
    pre: '<span>',
    post: '</span>',
    skip: false, // true will skip local search, useful if doing server-side search
  },
  menuShowMinLength: 0,
};

const TRIBUTE_HASHTAG: TributeCollection<HashtagSuggestion> = {
  trigger: '#',

  iframe: null,

  selectClass: 'highlight',
  containerClass: 'post__autocomplete-options',
  itemClass: 'option',

  selectTemplate: (item): string => {
    // Empty span at end to fix mentions in IE11
    // https://github.com/zurb/tribute/issues/213#issuecomment-513257925
    return `<span
      contenteditable="false"
      class="tag"
      data-type="hashtag">#${item.original.name}</span><span />`;
  },

  menuItemTemplate: (item): string => {
    return `<span class="type--name">${
      item.string
    }</span><span class="type--meta">${item.original.meta || ''}</span>`;
  },

  noMatchTemplate: (): string => {
    return '<span style="visibility: none;"></span>';
  },
  lookup: 'name',
  fillAttr: 'name',
  values: (text, cb) => {
    cb([]);
  },
  // loadingItemTemplate: undefined,
  requireLeadingSpace: true,
  allowSpaces: false,
  replaceTextSuffix: '\n',
  positionMenu: true,
  autocompleteMode: false,
  searchOpts: {
    pre: '<span>',
    post: '</span>',
    skip: false, // true will skip local search, useful if doing server-side search
  },
  menuShowMinLength: 0,
};

const TRIBUTE_EMOJI: TributeCollection<EmojiSuggestion> = {
  trigger: ':',

  iframe: null,

  selectClass: 'highlight',
  containerClass: 'post__autocomplete-options',
  itemClass: 'option',

  selectTemplate: (item): string => {
    return item.original.decorated;
  },

  menuItemTemplate: (item): string => {
    return `<span class="type--name">${item.original.decorated}</span><span class="type--meta">${item.original.name}</span>`;
  },

  noMatchTemplate: undefined,
  lookup: 'name',
  fillAttr: 'decorated',
  values: (text, cb) => {
    cb([]);
  },
  // loadingItemTemplate: undefined,
  requireLeadingSpace: true,
  allowSpaces: false,
  replaceTextSuffix: '\n',
  positionMenu: true,
  autocompleteMode: false,
  searchOpts: {
    pre: '<span>',
    post: '</span>',
    skip: false, // true will skip local search, useful if doing server-side search
  },
  menuShowMinLength: 1,
};

let tributeSingletonAbortController: AbortController | undefined;
let tributeLastValue = '';
let tributeTimer: number | undefined;

function debounce(): Promise<void> {
  return new Promise((resolve) => {
    if (tributeTimer) {
      clearTimeout(tributeTimer);
    }

    tributeTimer = setTimeout(() => {
      tributeTimer = undefined;
      resolve();
    }, DEBOUNCE_IN_MS);
  });
}

type AutocompleteResource<K extends string, R> = Record<
  K,
  { _links: { self: { href: string } }; options: R[] }
>;

async function autocomplete<R = unknown>(
  url: string,
  accept: string
): Promise<R[]> {
  if (tributeSingletonAbortController) {
    tributeSingletonAbortController.abort();
  }

  const __ac = new AbortController();

  tributeSingletonAbortController = __ac;
  tributeLastValue = url;

  await debounce();

  const results = await fetchResource<
    'autocomplete',
    AutocompleteResource<'autocomplete', R>
  >('autocomplete', url, {
    accept: [accept, 'application/json; q=0.8'].join(', '),
    signal: __ac.signal,
  });

  if ('content' in results.autocomplete) {
    throw new Error('Autocompletion returned wrapped HTML, expected JSON');
  }

  const options = results.autocomplete.options;

  if (tributeSingletonAbortController === __ac) {
    tributeSingletonAbortController = undefined;
  }

  // Check if query has changed since the request started
  const current = tributeLastValue;
  if (current !== url) {
    throw new Error('Query is stale');
  }

  return options;
}

export class Tributable {
  private tribute!: Tribute<
    HashtagSuggestion | MentionSuggestion | EmojiSuggestion
  >;

  constructor(
    protected readonly component: HTMLElement,
    protected readonly container?: HTMLElement
  ) {
    const tributeMention: typeof TRIBUTE_MENTION = {
      ...TRIBUTE_MENTION,
      menuContainer: container || this.component,
      values: (text, cb): void => {
        autocomplete<MentionSuggestion>(
          this.autocompleteUrl('mentions', text),
          'application/vnd.reachora.mention.v1.autocomplete+json'
        )
          .then((results) => {
            cb(results);
          })
          .catch(() => cb([]));
      },
    };

    const tributeHashtag: typeof TRIBUTE_HASHTAG = {
      ...TRIBUTE_HASHTAG,
      menuContainer: container || this.component,
      values: (text, cb): void => {
        autocomplete<HashtagSuggestion>(
          this.autocompleteUrl('hashtags', text),
          'application/vnd.reachora.hashtag.v1.autocomplete+json'
        )
          .then((results) => {
            cb(results);
          })
          .catch(() => cb([]));
      },
    };

    const tributeEmoji: typeof TRIBUTE_EMOJI = {
      ...TRIBUTE_EMOJI,
      menuContainer: container || this.component,
      values: (text, cb): void => {
        autocomplete<EmojiSuggestion>(
          this.autocompleteUrl('emojis', text),
          'application/vnd.reachora.emoji.v1.autocomplete+json'
        )
          .then((results) => {
            cb(results);
          })
          .catch(() => cb([]));
      },
    };

    this.tribute = new Tribute({
      collection: [
        tributeMention,
        tributeHashtag,
        tributeEmoji,
      ] as unknown as Array<
        TributeCollection<{
          [key: string]: unknown;
        }>
      >,
    });

    this.tribute.attach(this.component);
  }

  public get isActive(): boolean {
    return this.tribute.isActive;
  }

  public show(type: 'mentions' | 'hashtags' | 'emojis'): void {
    this.tribute.showMenuForCollection(this.component, this.indexFor(type));
  }

  public destroy(): void {
    this.tribute.detach(this.component);
  }

  protected autocompleteUrl(
    type: 'mentions' | 'hashtags' | 'emojis',
    q: string
  ): string {
    const urls = JSON.parse(
      document.head.querySelector<HTMLMetaElement>(
        'meta[name="wondr-autocomplete-urls"]'
      )?.content || '{}'
    );

    // Replace dots because Rails is really bad at handling dots. Taking care of
    // stopping Rails to interpret .something as format can be achieved by
    // adding a constraint to the route, but it will still interpret .. as a
    // path-up action.
    //

    return urls[type].replace(
      /\{q\}|%7Bq%7D/,
      encodeURIComponent(q.replace(/\./g, ''))
    );
  }

  private indexFor(type: 'mentions' | 'hashtags' | 'emojis'): number {
    return ['mentions', 'hashtags', 'emojis'].indexOf(type);
  }
}

export function tributables(): void {
  upgrade('[data-tributable]');
}

export function tributable(component: HTMLElement): Tributable {
  return new Tributable(component);
}

register('[data-tributable]', ({ element }) => tributable(element).destroy);
