import $ from 'jquery'
import onmount from 'onmount'
import '@selectize/selectize/dist/js/selectize.min.js'

///// Init
onmount('.js-page-module-form', function(mountEvent) {
  const wrapper = $(this)
  const form = wrapper.find('.js-edit-module-form')

  form.find("input[type=submit]").click(function() { $(this).attr("clicked", "true") })

  form.on('ajax:success', function(response) {
    let submitClicked = $("input[type=submit][clicked=true]").val()

    if (submitClicked != null) {
      const html = response.detail[0].html
      wrapper.replaceWith(html)

      // Run onmount again to pick up new module added to the page.
      onmount()
    }
  })

  ///// Init selectize for all controls with the appropriate class
  $(this).find('.js-plain-selectize').selectize({
    onChange: function(value) {
      const input = this.$input[0]
      const toggleValue = input.dataset.toggleReveal
      const toggleClass = input.dataset.toggleClass

      if (toggleValue && toggleClass) {
        const toggleElem = wrapper.find(toggleClass)

        if (toggleValue === value) {
          toggleElem.removeClass('hidden')
        } else {
          toggleElem.addClass('hidden')
        }
      }
    }
  })
})
