const assignFocus = (selector) => {
  const selectorElement = document.querySelector(selector);
  const focusElement = selectorElement.querySelector('[focus]');
  if (focusElement) focusElement.focus();
};

const listenForAjax = (controller, operation) => {
  if (typeof controller['beforeAfterAjax'] === 'function') {
    controller['beforeAfterAjax'].call(controller.element);
  }

  if (typeof controller[`before${operation}`] === 'function') {
    controller[`before${operation}`].call(controller.element);
  }

  return new Promise((resolve, reject) => {
    controller.element.addEventListener(
      'ajax:success',
      (event) => {
        const [data, status, xhr] = event.detail;

        resolve(xhr);

        const selector = controller.data.get('selector');
        if (selector) assignFocus(selector);

        if (typeof controller['afterAjaxSuccess'] === 'function') {
          controller['afterAjaxSuccess'].call(controller.element, event.detail);
        }

        if (typeof controller[`${operation}Success`] === 'function') {
          controller[`${operation}Success`].call(
            controller.element,
            event.detail
          );
        }
      },
      { once: true }
    );

    controller.element.addEventListener(
      'ajax:error',
      (event) => {
        const [data, status, xhr] = event.detail;

        if (typeof controller['afterAjaxError'] === 'function') {
          controller['afterAjaxError'].call(controller.element, event.detail);
        }

        if (typeof controller[`${operation}Error`] === 'function') {
          controller[`${operation}Error`].call(
            controller.element,
            event.detail
          );
        }
      },
      { once: true }
    );

    controller.element.addEventListener(
      'ajax:complete',
      (event) => {
        const [data, status, xhr] = event.detail;

        if (typeof controller['afterAjaxAfter'] === 'function') {
          controller['afterAjaxAfter'].call(controller.element, event.detail);
        }

        if (typeof controller[`${operation}After`] === 'function') {
          controller[`${operation}After`].call(controller.element, xhr);
        }
      },
      { once: true }
    );
  });
};

export const useAfterAjax = (controller) => {
  Object.assign(controller, {
    insertAdjacentHtml() {
      listenForAjax(this, 'insertAdjacentHtml').then((xhr) => {
        const position = this.data.get('position');
        const selector = this.data.get('selector');

        const selectorElement = document.querySelector(selector);
        selectorElement.insertAdjacentHTML(
          position || 'beforeend',
          xhr.response
        );
      });
    },
    outerHtml() {
      listenForAjax(this, 'outerHtml').then((xhr) => {
        const selector = this.data.get('selector');

        const selectorElement = document.querySelector(selector);
        selectorElement.outerHTML = xhr.response;
      });
    },
  });
};
