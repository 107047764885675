import { Controller } from '@hotwired/stimulus';
import consumer from '../../src/channels/consumer';

export default class extends Controller {
  static targets = ['items', 'item', 'list'];
  connect() {
    this.subscribe();

    if (!this.element.dataset.sound) return;
    this.audio = new Audio(this.element.dataset.sound);
    this.audio.load();
  }

  subscribe() {
    const controller = this;
    this.subscription = consumer.subscriptions.create(
      {
        channel: 'ConversationsPaneChannel',
      },
      {
        connected() {
          console.log('Connected to the conversation pane');
        },
        received(data) {
          controller.updateConversationList(data);
          controller.countUnreads();
          controller.notify(data.notifiable);
        },
      }
    );
  }

  disconnect() {
    if (this.subscription) consumer.subscriptions.remove(this.subscription);
  }

  countUnreads() {
    if (!this.hasItemTarget) return;

    setTimeout(() => {
      this.updateHeaderCounter(this.totalUnreadCount);
    }, 100);
  }

  notify(notifiable) {
    if (!this.audio) return;

    /*
      https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide#autoplay_availability
      The audio will only play if at least one of the criteria here is met.
      
      TODO: 
      - Check if it's possible to surpass the criteria
      - Test this functionality on `unread_controller` instead
    */
    if (notifiable && document.hidden) {
      this.audio
        .play()
        .catch((err) => console.debug('Failed to play sound notification'));
    }
  }

  updateConversationList(data) {
    if (this.hasItemsTarget) {
      this.itemsTarget.innerHTML = data.html;
    } else {
      this.listTarget.innerHTML =
        '<div data-target="messaging--conversations-pane.items"></div>';
      const items = this.listTarget.querySelector(
        '[data-target="messaging--conversations-pane.items"]'
      );
      items.innerHTML = data.html;
      this.listTarget.removeAttribute('data-target');
    }
  }

  updateHeaderCounter(count) {
    const detail = { unread: count };
    const init = { bubbles: true, cancelable: true, detail: detail };
    const evt = new CustomEvent('unreadCounter:update', init);

    document.dispatchEvent(evt);
  }

  get totalUnreadCount() {
    return Array.prototype.map
      .call(this.itemTargets, (el) => parseInt(el.dataset.unread))
      .filter((count) => count)
      .reduce((acc, curr) => acc + curr, 0);
  }
}
