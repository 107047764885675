import { Eventable } from './eventable';
import { register, upgrade } from './ujs';

export type LikeEvent = {
  id: string;
  state: boolean;
};

/**
 * Makes a component marked with [data-likable] respond to like events.
 *
 * Items that can be liked, such as posts, can have one or more actions to
 * like or unlike. These actions can be ujs-enabled forms, meaning they will
 * submit using XHR, and not refresh the page. These responses emit follow
 * events that can be captured.
 *
 * In order to make an element change its visibility, add the following data
 * attributes:
 *
 * - data-likable: 'true' | 'false'
 * - data-type: 'post' | 'comment'
 * - data-id: 'id'
 *
 * The component marked with [data-likable] will hide itself when the follow
 * state matches its value ('true' or 'false').
 *
 * @example
 *
 * <form action="/posts/1/like"
 *       method="post"
 *       data-remote
 *       data-likable="true"
 *       data-type="post"
 *       data-id="1">
 *   <button type="submit">Like</button>
 * </form>
 */
export class Likable extends Eventable<LikeEvent> {
  constructor(component: HTMLElement) {
    super(component, 'likable', 'like');
  }
}

export function likables(): void {
  upgrade('[data-likable]');
}

export function likable(component: HTMLElement): Likable {
  return new Likable(component);
}

register('[data-likable]', ({ element }) => likable(element).destroy);
