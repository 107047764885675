import { Controller } from '@hotwired/stimulus';

const MAX_CHECK_INTERVAL = 1000 * 60 * 15; // 15 minutes
const CHECK_INTERVAL = 1000 * 30;

export default class extends Controller {
  public static values = {
    url: String,
  };

  private declare urlValue: string | undefined;
  private refreshTimer: number | undefined;
  private lastInterval: number | undefined;

  public connect(): void {
    this.startChecking();
    this.element[this.identifier] = this;
  }

  public disconnect(): void {
    this.stopRefreshing();
  }

  public fetch(): void {
    const firstArticle = document.querySelector<HTMLUnknownElement>(
      '#feeds-page article:not(.card--pinned)'
    );

    if (!firstArticle) {
      return;
    }

    if (firstArticle.dataset.newContentAvailable) {
      return;
    }

    const timestamp = firstArticle.dataset.postTimestamp;

    if (!timestamp) {
      return;
    }

    fetch(`${this.urlValue}?after=${timestamp}`, {
      headers: {
        Accept:
          'application/vnd.reachora.feed.v1.newer, application/problem+json; q=0.1',
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        }

        throw response;
      })
      .then((html) => {
        if (!html) {
          return;
        }

        this.element.innerHTML = html;
        firstArticle.dataset.newContentAvailable = 'true';
      })
      .then(
        () => {
          // Schedule new fetch
          //
          // This method allows for exponential back-off, linear restoration. The
          // latter is important so that when one request goes through, not all
          // timers reset.
          this.lastInterval = Math.max(this.lastInterval - 5, CHECK_INTERVAL);
        },
        () => {
          // Schedule new fetch
          //
          // This is the exponential backoff part
          this.lastInterval = Math.min(
            MAX_CHECK_INTERVAL,
            this.lastInterval ** 2
          );
        }
      )
      .then(() => this.schedule());
  }

  private startChecking(): void {
    this.lastInterval = CHECK_INTERVAL;
    this.schedule();
  }

  private schedule(): void {
    if (!this.lastInterval) {
      return;
    }

    this.refreshTimer = setTimeout(() => {
      this.fetch();
    }, this.lastInterval);
  }

  private stopRefreshing(): void {
    this.lastInterval = undefined;

    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
  }
}
