/**
 * This content is based on files which are licensed according to the W3C
 * Software License at
 * https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 * @see {Dialog} for more information
 */

import {
  isFocusable,
  KEY_CODES,
  KEY_NAMES,
  KEY_NAMES_LEGACY,
  remove,
} from './aria';
import { toggleHidden } from './utils';

/*
 * When util functions move focus around, set this true so the focus listener
 * can ignore the events.
 */
let __IgnoreUtilFocusChanges = false;
const DIALOG_OPEN_BODY_CLASS = 'has-dialog';
const DIALOG_BACKDROP_CLASS = 'dialog-backdrop';

/**
 * Set focus on descendant nodes until the first focusable element is found.
 *
 * @param element DOM node for which to find the first focusable descendant.
 * @returns true if a focusable element is found and focus is set.
 */
export function focusFirstDescendant(element: Element): boolean {
  for (let i = 0; i < element.childNodes.length; i++) {
    const child = element.childNodes[i];
    if (
      attemptFocus(child as HTMLElement) ||
      focusFirstDescendant(child as Element)
    ) {
      return true;
    }
  }
  return false;
}

/**
 * Find the last descendant node that is focusable.
 *
 * @param element DOM node for which to find the last focusable descendant.
 * @returns true if a focusable element is found and focus is set.
 */
export function focusLastDescendant(element: Node): boolean {
  for (let i = element.childNodes.length - 1; i >= 0; i--) {
    const child = element.childNodes[i];
    if (attemptFocus(child as HTMLElement) || focusLastDescendant(child)) {
      return true;
    }
  }
  return false;
}

/**
 * @desc Set Attempt to set focus on the current node.
 * @param element The node to attempt to focus on.
 * @returns true if element is focused.
 */
function attemptFocus(element: HTMLElement | null | undefined): boolean {
  if (!isFocusable(element)) {
    return false;
  }

  __IgnoreUtilFocusChanges = true;

  try {
    element.focus();
  } catch (_) {
    /* noop */
  }

  __IgnoreUtilFocusChanges = false;

  return document.activeElement === element;
}

const OpenDialogList: Dialog[] = [];

/**
 * @returns the last opened dialog (the current dialog)
 */
export function getCurrentDialog(): Dialog | undefined {
  if (OpenDialogList && OpenDialogList.length) {
    return OpenDialogList[OpenDialogList.length - 1];
  }
}

export function closeCurrentDialog(): boolean {
  const currentDialog = getCurrentDialog();
  if (currentDialog) {
    currentDialog.cancel();
    return true;
  }

  return false;
}

function handleEscape(event: KeyboardEvent): void {
  if (
    (event.key === KEY_NAMES.ESC || event.key === KEY_NAMES_LEGACY.ESC) &&
    closeCurrentDialog()
  ) {
    event.stopPropagation();
  }
}

document.addEventListener('turbolinks:load', () => {
  document.addEventListener('keyup', handleEscape);
});

document.addEventListener('turbolinks:before-render', () => {
  document.removeEventListener('keyup', handleEscape);
});

const VALID_ROLES = ['dialog', 'alertdialog'];

/**
 * Dialog object providing modal focus management.
 *
 * In order to prepare the DOM for a dialog, the container element must have
 * various attributes, a valid id and the hidden attribue:
 *
 * - a unique and valid id
 * - [hidden]
 * - [role="dialog"]
 * - [aria-modal="true"]
 *
 * You can assist screen readers by adding the [aria-labelledby] attribute and
 * point its value to the "id" of the element that is the title inside the
 * dialog.
 *
 * Closing a dialog via unobtrusive javascript is also possible. Add a button
 * with [data-action="close"] in order to make that button close the dialog.
 *
 * If no element is given to {Dialog#open}, it will look for [data-autofocus] to
 * determine which element to focus on open, or get the first focusable element.
 *
 * @example
 *
 * <!-- Assuming this action is handled and created a
 *      new Dialog('my-dialog').open('button')
 *   -->
 * <button id="button" data-action="dialog" data-dialog="my-dialog">
 *   My Dialog
 * </button>
 *
 * <div role="dialog"
 *      id="my-dialog"
 *      aria-labelledby="my-dialog-title"
 *      aria-modal="true"
 *      hidden
 * >
 *  <h2 id="my-dialog">This is my dialog</h2>
 * </div>
 *
 */
export class Dialog {
  public readonly dialogNode: HTMLElement;
  private readonly backdropNode: Element;

  private focusAfterClosed: HTMLElement | undefined;
  private focusFirst: HTMLElement | undefined;
  private preNode: HTMLDivElement | undefined;
  private postNode: HTMLDivElement | undefined;

  private lastFocus: Element | undefined;

  /**
   *
   * @param dialogId The ID of the element serving as the dialog container.
   */
  constructor(dialogId: string) {
    this.dialogNode = document.getElementById(dialogId)!;
    if (this.dialogNode === null) {
      throw new Error('No element found with id="' + dialogId + '".');
    }

    // Protect the programmer by ensuring the role is an accessible one.
    const role = this.dialogNode.getAttribute('role') || '';
    const isDialog = role
      .trim()
      .split(/\s+/g)
      .some((token) => VALID_ROLES.indexOf(token) !== -1);

    if (!isDialog) {
      throw new Error(
        'Dialog() requires a DOM element with ARIA role of ' +
          VALID_ROLES.join('or ')
      );
    }

    // Wrap in an individual backdrop element if one doesn't exist
    // Native <dialog> elements use the ::backdrop pseudo-element, which
    // works similarly.
    if (
      this.dialogNode.parentNode instanceof Element &&
      this.dialogNode.parentNode.classList.contains(DIALOG_BACKDROP_CLASS)
    ) {
      this.backdropNode = this.dialogNode.parentNode;
    } else if (this.dialogNode.querySelector(`.${DIALOG_BACKDROP_CLASS}`)) {
      this.backdropNode = this.dialogNode.querySelector(
        `.${DIALOG_BACKDROP_CLASS}`
      )!;
    } else {
      this.backdropNode = document.createElement('div');
      this.backdropNode.className = DIALOG_BACKDROP_CLASS;
      this.dialogNode.parentNode!.insertBefore(
        this.backdropNode,
        this.dialogNode
      );
      this.backdropNode.appendChild(this.dialogNode);
    }

    // Initialize this dialog
    this.open = this.open.bind(this);
    this.cancel = this.cancel.bind(this);
    this.close = this.close.bind(this);

    this.onBackdrop = this.onBackdrop.bind(this);

    // Click seems the right candidate, but it's not. A click is registered on
    // mouseup, and that happens when selecting content on the screen and
    // releasing. The release point will be clicked, even when the mousedown
    // was inside the dialog.
    //
    // Especially when selecting text, this leads to unwanted "backdrop" events.
    this.backdropNode.addEventListener('mousedown', this.onBackdrop);
  }

  private onBackdrop(e: Event): void {
    if (e.target !== e.currentTarget) {
      return;
    }

    console.debug('[backdrop]', this);
    e.preventDefault();

    // Necessary to prevent the click from travelling across the backdrop after
    // this is closed.
    e.stopPropagation();

    this.cancel();
  }

  public cancel(): void {
    this.close();
  }

  public destroy(): void {
    this.close(undefined, false, true);

    this.backdropNode.removeEventListener('mousedown', this.onBackdrop);
  }

  public clear(): void {
    this.dialogNode.querySelectorAll('input').forEach((input) => {
      const type = input.getAttribute('type');
      if (type === 'hidden' || type === 'submit') {
        return;
      }

      if (type === 'checkbox' || type === 'radio') {
        input.checked = false;
        return;
      }

      /* if (type === 'file') {
        input.files = null;
        return;
      } */

      input.value = '';
    });

    this.dialogNode.querySelectorAll('textarea').forEach((textarea) => {
      textarea.value = '';
    });
  }

  /**
   * Opens the dialog, takes focus and applies the backdrop
   *
   * @param focusAfterClosed Either the DOM node or the ID of the DOM node to
   *    focus when the dialog closes. Should be a focusable element that caused
   *    this dialog to open.
   * @param focusFirst Optional parameter containing either the DOM node or the
   *    ID of the DOM node to focus when the dialog opens. If not specified, it
   *    looks for [data-autofocus] or falls back to the first focusable element
   *    in the dialog. This element will receive focus on open.
   * @param clearOnOpen if true, clears forms on open.
   */
  public open(
    focusAfterClosed?: string | Node,
    focusFirst?: string | Node,
    clearOnOpen: boolean = false
  ): this {
    // Get the item to focus when this dialog is closed. This is usually the
    // source of opening this dialog, such as a button.
    if (typeof focusAfterClosed === 'string') {
      this.focusAfterClosed = document.getElementById(focusAfterClosed)!;
    } else if (focusAfterClosed instanceof HTMLElement) {
      this.focusAfterClosed = focusAfterClosed;
    } else if (
      focusAfterClosed === undefined &&
      this.focusAfterClosed === undefined
    ) {
      throw new Error(
        'the focusAfterClosed parameter is required for the aria.Dialog constructor.'
      );
    }

    // Get the item to focus when this dialog is opened.
    if (typeof focusFirst === 'string') {
      this.focusFirst = document.getElementById(focusFirst)!;
    } else if (focusFirst instanceof HTMLElement) {
      this.focusFirst = focusFirst;
    }

    toggleHidden(this.dialogNode, false);
    this.backdropNode.classList.add('active');

    // Disable scroll on the body element
    document.body.classList.add(DIALOG_OPEN_BODY_CLASS);

    // Bracket the dialog node with two invisible, focusable nodes. While this
    // dialog is open, we use these to make sure that focus never leaves the
    // document even if dialogNode is the first or last node.
    //
    // This also allows the dialog to exist anywhere in the tree, instead of
    // having to exist at the start or end of the body.
    const preDiv = document.createElement('div');
    this.preNode = this.dialogNode.parentNode!.insertBefore(
      preDiv,
      this.dialogNode
    );
    this.preNode.tabIndex = 0;

    const postDiv = document.createElement('div');
    this.postNode = this.dialogNode.parentNode!.insertBefore(
      postDiv,
      this.dialogNode.nextSibling
    );
    this.postNode.tabIndex = 0;

    // If this modal is opening on top of one that is already open, get rid of
    // the document focus listener of the open dialog; in other words only this
    // dialog may have focus.
    if (OpenDialogList.length > 0) {
      getCurrentDialog()!.captureFocus = false;
    }
    this.captureFocus = true;

    // Mark this as an open dialog
    OpenDialogList.push(this);
    if (clearOnOpen) {
      this.clear();
    }

    this.focus();
    this.lastFocus = document.activeElement || undefined;
    return this;
  }

  /**
   * Close this dialog
   *
   * 1. Hides the current top dialog,
   * 2. removes listeners of the top dialog,
   * 3. restore listeners of a parent dialog if one was open under the one that just closed,
   * 4. and sets focus on the element specified for focusAfterClosed.
   *
   * @param _event a DOM event that causes this to be closed. This argument
   *   allows to directly bind to this function, instead of having to redirect
   *   the event first without the event.
   * @param _replaced when using {replace}, this is true. This argument can be
   *   used to have different behaviour when a dialog is replaced, vs. when it
   *   is closed.
   * @param _destroyed when using {destroy}, this is true. This argument can be
   *   used to have different behaviour when a dialog is destroyed, vs. when it
   *   is closed.
   */
  public close(
    event?: Event,
    _replaced: boolean = false,
    _destroyed: boolean = false
  ): boolean {
    const index = OpenDialogList.indexOf(this);
    if (index === -1) {
      return false;
    }

    // Prevent the click unless a link was clicked. If a link was clicked, but
    // that link triggers the close action, the link should still be followed.
    // If a button was clicked, it should still do what it normally does.
    if (
      event &&
      (!(event.currentTarget instanceof HTMLElement) ||
        (event.currentTarget.tagName !== 'A' &&
          event.currentTarget.tagName !== 'BUTTON'))
    ) {
      event?.preventDefault();
    }

    const isActiveDialog = index === OpenDialogList.length - 1;
    OpenDialogList.splice(index, 1);

    this.captureFocus = false;

    toggleHidden(this.dialogNode, true);
    this.backdropNode.classList.remove('active');

    remove(this.preNode!);
    remove(this.postNode!);

    if (isActiveDialog) {
      this.focusAfterClosed?.focus();

      if (OpenDialogList.length > 0) {
        getCurrentDialog()!.captureFocus = true;
      }
    }

    // Nothing active
    if (OpenDialogList.length === 0) {
      document.body.classList.remove(DIALOG_OPEN_BODY_CLASS);
    }

    return true;
  }

  /**
   * Hides the current dialog and replaces it with another.
   *
   * Use this if you don't want to layer dialogs (which is usually true).
   *
   * @param dialog the new dialog that will replace the current
   * @param newFocusAfterClosed Optional ID or DOM node specifying where to
   *    place focus when the new dialog closes. If not specified, focus will be
   *    placed on the element specified by the dialog being replaced.
   * @param newFocusFirst Optional ID or DOM node specifying where to place
   *    focus in the new dialog when it opens. If not specified, the first
   *    focusable element will receive focus.
   * @param clearOnOpen
   */
  public replace<T extends Dialog>(
    dialog: T,
    newFocusAfterClosed?: string | Node,
    newFocusFirst?: string | Node,
    clearOnOpen: boolean = false
  ): T {
    this.close(undefined, true);

    return dialog.open(newFocusAfterClosed, newFocusFirst, clearOnOpen);
  }

  public focus(): void {
    if (this.focusFirst) {
      this.focusFirst.focus();
    } else {
      const autofocus =
        this.dialogNode.querySelector<HTMLElement>('[data-autofocus]');
      attemptFocus(autofocus) || focusFirstDescendant(this.dialogNode);
    }
  }

  public set captureFocus(flag: boolean) {
    if (flag) {
      document.addEventListener('focus', this.trapFocus, true);
    } else {
      document.removeEventListener('focus', this.trapFocus, true);
    }
  }

  private trapFocus(event: Event): void {
    if (__IgnoreUtilFocusChanges) {
      return;
    }

    const currentDialog = getCurrentDialog()!;

    if (currentDialog.dialogNode.contains(event.target as HTMLElement)) {
      currentDialog.lastFocus = event.target as HTMLElement;
    } else {
      focusFirstDescendant(currentDialog.dialogNode);

      if (currentDialog.lastFocus === document.activeElement) {
        focusLastDescendant(currentDialog.dialogNode);
      }

      currentDialog.lastFocus = document.activeElement || undefined;
    }
  }
}

/**
 * Helper function to open a new dialog; Same as constructing a new dialog.
 */
export function createDialog(dialogId: string): Dialog {
  return new Dialog(dialogId);
}

/**
 * Helper function to close a specific dialog.
 *
 * The dialog will only close if the passed in "closing element" is contained
 * within the current (top) dialog.
 *
 * @param closeButton the closing (source) element
 */
export function closeDialog(closeButton?: HTMLElement): void {
  const topDialog = getCurrentDialog();
  if (
    topDialog &&
    (!closeButton || topDialog.dialogNode.contains(closeButton))
  ) {
    topDialog.close();
  }
}

/**
 * Helper function to replace the current (top) dialog with a different dialog.
 *
 * The dialog will only be replaced if the currently focused element is inside
 * the current (top) dialog.
 *
 * @param newDialogId
 * @param newFocusAfterClosed
 * @param newFocusFirst
 */
export function replaceDialog(dialog: Dialog): void {
  const topDialog = getCurrentDialog();
  if (topDialog) {
    topDialog.replace(dialog);
  } else {
    dialog.open();
  }
}

declare const window: Window & {
  createDialog: typeof createDialog;
  closeDialog: typeof closeDialog;
  replaceDialog: typeof replaceDialog;
};

window.createDialog = createDialog;
window.closeDialog = closeDialog;
window.replaceDialog = replaceDialog;
