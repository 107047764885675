import onmount from 'onmount'
import $ from 'jquery'

onmount('.js-newsletter-subscription-form', function() {
  const $form = $(this)

  $form.bind('ajax:complete', function() {
    $form.html(`
      <b-cell>
        <h3>Success!</h3>
        <p>Please check your email for confirmation</p>
      </b-cell>
    `)
  })
})
