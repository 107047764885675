import { Controller } from '@hotwired/stimulus';
import { v4 as uuidv4 } from 'uuid';

export default class extends Controller {
  getUuid(event) {
    const element = event.currentTarget;
    if (element.tagName === 'A' || element.tagName === 'INPUT') {
      return element.getAttribute('data-uuid');
    } else {
      const childButton = element.getElementsByTagName('button')[0];
      return childButton.getAttribute('data-uuid');
    }
  }

  updateElement(event) {
    event.currentTarget.tagName === 'A'
      ? this.updateLink(event)
      : this.updateButton(event);
  }

  updateLink(event) {
    const link = event.currentTarget;
    const uuid = uuidv4();

    link.setAttribute('data-uuid', uuid);

    console.debug(
      'New link data attribute : ' + link.getAttribute('data-uuid')
    );

    const href = link.getAttribute('href');
    const [basePath, paramsAsString] = href.split('?');
    const params = new URLSearchParams(paramsAsString);

    params.delete('uuid');
    params.append('uuid', uuid);

    const newPath = `${basePath}?${params.toString()}`;

    link.setAttribute('href', newPath);

    console.debug('New href attribute : ' + link.getAttribute('href'));
  }

  updateButton(event) {
    const form = event.currentTarget;
    const uuid = uuidv4();

    const action = form.getAttribute('action');
    const [basePath, paramsAsString] = action.split('?');
    const params = new URLSearchParams(paramsAsString);

    params.delete('uuid');
    params.append('uuid', uuid);

    const newAction = `${basePath}?${params.toString()}`;

    form.setAttribute('action', newAction);

    console.debug('New form action : ', form.getAttribute('action'));

    const childButton = form.getElementsByTagName('button')[0];
    childButton.setAttribute('data-uuid', uuid);

    console.debug(
      'New button data attribute : ' + childButton.getAttribute('data-uuid')
    );
  }
}
