import { ConfirmDialog } from '../confirmable';
import { Dialog } from '../dialog';
import { fetch } from '../fetch';

import Uppy, { UploadedUppyFile } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import Dashboard from '@uppy/dashboard';

/**
 * Sub-dialog that allows configuring an image attachment for a post
 *
 * In order to keep everything in sync, only use the open and close methods on
 * this element and not the global dialog helpers.
 */

type UppyUploadedFile = {
  extension: string;
  name: string;
  xhrUpload: {
    endpoint: string;
  };

  preview: string;

  meta: {
    alt?: string;
    key: string;
  };

  progress: {
    bytesTotal: number;
  };
};
export class AddImageAlbumDialog extends Dialog {
  private readonly uploaderContainer: Element;

  private readonly inputImages: HTMLInputElement;

  public uploader: Uppy.Uppy<Uppy.StrictTypes>;

  constructor(
    private readonly component: HTMLElement,
    private readonly parent: Dialog & {
      setImages(images: Array<{ alt?: string; url: string }>): void;
      clearImage(): void;
    }
  ) {
    super(component.id);

    this.uploaderContainer = this.component.querySelector(
      '[data-target="image-uploader"]'
    )!;
    this.inputImages = this.component.querySelector<HTMLInputElement>(
      'input[data-target="images"]'
    )!;

    this.open = this.open.bind(this);
    this.cancel = this.cancel.bind(this);
    this.close = this.close.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);

    const form = this.component.closest<HTMLFormElement>('form')!;
    form.addEventListener('submit', this.onFormSubmit);

    const uppyEndpoints = document.head.querySelector<HTMLMetaElement>(
      'meta[name="wondr-uppy-endpoints"]'
    )!.content;
    const postImagesEndpoint = JSON.parse(uppyEndpoints).images;

    this.uploader = new Uppy({
      restrictions: {
        maxFileSize: 1024 * 1024 * 3, // 3MB
        allowedFileTypes: ['image/jpg', 'image/jpeg', 'image/png'],
        maxNumberOfFiles: 6,
      },
    })
      .use(Dashboard, {
        target: this.uploaderContainer,
        showProgressDetails: true,
        inline: true,
        doneButtonHandler: () => {
          this.onConfirm();
        },
        proudlyDisplayPoweredByUppy: false,
        fileManagerSelectionType: 'files',
        theme: 'auto',
        note: 'Upload a maximum of 6 photos',
        metaFields: [
          {
            id: 'alt',
            name: 'Alt',
            placeholder: 'Add text describing the image',
          },
        ],
      })
      .use(AwsS3, {
        companionUrl: postImagesEndpoint,
        allowedMetaFields: ['alt'],
      })
      .on('complete', (result) => {
        const files = result.successful as unknown as UppyUploadedFile[];

        if (!files.length) {
          return;
        }

        const data: Array<{ alt?: string; url: string }> = files.map(
          (file) => ({
            alt: file.meta.alt,
            url: file.xhrUpload.endpoint + '/' + file.meta.key,
          })
        );

        this.parent.setImages(data);
      });
  }

  public get autofocus(): HTMLElement {
    return this.component.querySelector('input')!;
  }

  /**
   * Opens itself, attempting to replace dialogs that are already out there,
   * namely the "base" create post dialog.
   *
   * @param source the source of opening the dialog, usually a button.
   */
  public open(
    source?: string | Node,
    focusFirst?: string | Node,
    clearOnOpen: boolean = false
  ): this {
    console.debug(`[new-post] add image album`);
    return super.open(source, focusFirst, clearOnOpen);
  }

  /**
   * When closing this dialog, we want to go back to the original create post
   * dialog, instead of closing out everything.
   */
  public close(
    event?: Event,
    replaced: boolean = false,
    destroyed: boolean = false
  ): boolean {
    console.debug('[close] add image album dialog', this);

    if (super.close(event, replaced, destroyed)) {
      this.parent.open();
      return true;
    }

    return false;
  }

  public cancel(): void {
    const dialog = new ConfirmDialog('discard-image-dialog', this.inputImages);

    // When it's confirmed, clear this (removing the dirty flag)
    dialog.onConfirm = (): void => {
      this.parent.clearImage();
      this.uploader.cancelAll();

      this.clear();
      this.close(undefined, false);
    };

    dialog.open(this.inputImages);
  }

  public clear(): void {
    super.clear();
  }

  private onFormSubmit(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }

  private onConfirm(): void {
    this.close();
    this.uploader.reset();
  }

  public get id(): string {
    return this.component.id;
  }

  public get isOpen(): boolean {
    return !this.component.hasAttribute('hidden');
  }
}
