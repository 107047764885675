import { emit } from './emitter';
import { AbortController } from './fetch';
import { fetchPage, PageResponse } from './infinitable';
import { register, upgrade, upgradeAll } from './ujs';

/**
 * Marking an elements with data-replacable will make its content remotely
 * replacable. This is similar to {Infinitable}, but instead of appending, it
 * will be replacing.
 *
 * Replacable components take a data-replacable-interval attribute. If this
 * attribute is missing, no further replacements will be scheduled. If an
 * interval is given, it must also provide a data-href where to fetch the new
 * content.
 *
 * The following attributes are optional:
 *
 * [data-accept] the Accept value, defaults to
 *    application/vnd.reachora.replacement.v1,
 *    application/vnd.reachora.replacement.v1+json
 *
 * [data-method] the HTTP method, defaults to GET. NOTE: will *not*
 *    automatically inject CSRF tokens for non-GET requests
 *
 * [data-id-prefix] the optional ID prefix that allows returned replacable's ids
 *    to be prefixed so they become unique
 *
 * The returning HTML (or JSON with content key/value) should only be a single
 * HTML element at the root that is also [data-replacable]. This means you must
 * have an Accept value that does *not* include the layout.
 *
 * @see /app/controllers/connect/notifications_controller.rb
 * @see /app/views/connect/notifications
 *
 * Each element INSIDE the returned root element is considered separately when
 * replacing.
 *
 * - if [data-id-prefix] + the element id already exists on the page, it's
 *     replaced with the new element with the same (prefixed) id.
 * - if it doesn't exist on the page, it's added after the last element in the
 *     original list.
 *
 * ⚠️ This means the order in the response is NOT retained. This is on purpose.
 *
 * Finally all elements with ids that are NOT present in the new response are
 * removed from the list.
 *
 * There is an exception for any element that is currently focused or contains
 * focus. That element's replacement is deferred until it loses focus. Elements
 * that should have been removed are immediately removed. The only way to remove
 * this behaviour on an element level is by making it [data-markable].
 *
 * @see {Markable}
 * @see /app/webpacker/src/brandless/markable
 *
 * @example
 *
 * <ol
 *  class="notifications"
 *  data-replacable
 *  data-replacable-interval="5000"
 *  data-accept="application/vnd.wondr.notification.v1.collection"
 *  data-href="https://example.org"
 *  data-id-prefix="full-">
 *
 * </ol>
 */
export class Replacable {
  private readonly abortController: AbortController;
  private lastTimeout: number | undefined;
  private actualInterval: number | undefined;
  private cleanUpQueue: (() => void) | undefined;
  private hasReplacedAtLeastOnce = false;

  constructor(public readonly node: HTMLElement) {
    this.abortController = new AbortController();

    this.replace = this.replace.bind(this);
    this.onReplace = this.onReplace.bind(this);
    this.onReplaceError = this.onReplaceError.bind(this);

    this.destroy = this.destroy.bind(this);

    this.scheduleReplacement();
  }

  private get interval(): number {
    return Number(this.node.getAttribute('data-replacable-interval'));
  }

  private set interval(next: number) {
    if (Number.isNaN(next)) {
      this.node.removeAttribute('data-replacable-interval');
    } else {
      this.node.setAttribute('data-replacable-interval', String(next));
    }
  }

  private get href(): string | null {
    return this.node.getAttribute('data-href');
  }

  private set href(next: string | null) {
    if (next) {
      this.node.setAttribute('data-href', next);
    } else {
      this.node.removeAttribute('data-href');
    }
  }

  private get emitEvent(): string | null {
    return this.node.getAttribute('data-emit');
  }

  private get accept(): string {
    if (this.node.hasAttribute('data-accept')) {
      return this.node.getAttribute('data-accept') || '';
    }

    // This default is likely not acceptable, and that's fine. The error message
    // in the response, when given, will tell the developer what to use instead.
    return 'application/vnd.reachora.replacement.v1, application/vnd.reachora.replacement.v1+json';
  }

  private get method(): string {
    if (this.node.hasAttribute('data-method')) {
      return this.node.getAttribute('data-method') || '';
    }

    return 'get';
  }

  private get idPrefix(): string | null {
    return this.node.getAttribute('data-id-prefix') || '';
  }

  private scheduleReplacement(): void {
    if (Number.isNaN(this.interval)) {
      return;
    }

    const punchThrough = !this.hasReplacedAtLeastOnce;
    this.lastTimeout = setTimeout(
      () => this.replace(punchThrough),
      this.actualInterval || this.interval
    );
  }

  public destroy(): void {
    this.abortController.abort();
    this.hasReplacedAtLeastOnce = false;

    if (this.cleanUpQueue) {
      this.cleanUpQueue();
      this.cleanUpQueue = undefined;
    }

    clearTimeout(this.lastTimeout);
  }

  private onReplace({ page: { content, _links } }: PageResponse): void {
    console.debug(`[replacable] received content ${_links?.self?.href || ''}`);
    this.hasReplacedAtLeastOnce = true;

    // The following is a security risk with user-generated content. It is the
    // server's responsibility to correctly escape any user-generated content.
    const hydrator = document.createElement('div');
    hydrator.innerHTML = content;

    const root = hydrator.firstElementChild!;

    // Transfer new replacable properties (if any) from the new root
    this.href = _links?.self?.href || root?.getAttribute('data-href') || null;

    this.actualInterval = Math.max(
      this.interval,
      (this.actualInterval || 0) - this.interval
    );
    this.interval = Number(root?.getAttribute('data-replacable-interval'));

    // Remove the queuedReplacement, as a new one will be created (if still
    // applicable). This ensures queuedReplacements don't run on stale data.
    if (this.cleanUpQueue) {
      this.cleanUpQueue();
      this.cleanUpQueue = undefined;
    }

    // Now the content is replaced
    const prefix = this.idPrefix;
    const keep: string[] = [];

    // Why start with the last element? It's much more performant to shrink a
    // node's children from the end than to shrink it from the start. But more
    // importantly, this keeps the "action" button on the screen. This tricks
    // browsers to anchor onto this button and not increase the scroll height.
    while (root.lastElementChild) {
      const id = root.lastElementChild.id;

      if (id) {
        const prefixedId = prefix ? [prefix, id].filter(Boolean).join('') : id;
        const existing = document.getElementById(prefixedId);

        keep.push(prefixedId);

        root.lastElementChild.id = prefixedId;

        if (existing) {
          // If the current focus is on or inside the element that is about to
          // be replaced, wait with replacing so the focus is retained. This
          // solves a whole ordeal of issues for keyboard users, with the slight
          // downside that a notification may be outdated whilst focused.
          //
          // If that downside becomes too great, activeElements should be DOM
          // merged (classes, content, etc.) to overcome this, instead of being
          // replaced.
          if (this.hasFocus(existing)) {
            const element = root.lastElementChild;
            root.removeChild(element);

            // If the focused element is an HTMLElement, use the blur event to
            // still replace the node after focus moves out / away from the
            // active element.
            this.queueReplacementOnBlur(existing, element);
          } else {
            existing.replaceWith(root.lastElementChild);
          }
          continue;
        }
      }

      this.node.insertBefore(
        root.lastElementChild,
        this.node.firstElementChild
      );
    }

    // Remove all the elements (with IDs) that no longer exist in the response
    this.node.childNodes.forEach((node) => {
      if (node instanceof HTMLElement && node.id) {
        if (keep.indexOf(node.id) === -1) {
          if (
            document.activeElement === node ||
            node.contains(document.activeElement)
          ) {
            this.node.focus(); // Move focus back up.
          }

          node.remove();
        }
      }
    });

    // Make all elements just inserted interactive.
    upgradeAll();

    if (this.emitEvent) {
      emit(this.emitEvent, { replacable: this, root });
    }

    this.scheduleReplacement();
  }

  private onReplaceError(error: Error): void {
    if (error instanceof DOMException) {
      // likely abort error
      return;
    }

    // Exponential back-off pattern, eg.
    // 5 seconds
    // 10 seconds
    // 20 seconds
    // ...
    // 15 minutes
    this.actualInterval = Math.min(
      (this.actualInterval || this.interval) * 2,
      15 * 60 * 60
    );

    console.error(
      `[replacable] something went wrong, trying again in ${
        this.actualInterval / 1000
      } seconds`,
      error
    );

    this.scheduleReplacement();
  }

  private replace(punchThrough = false): Promise<void> {
    const href = this.href;

    if (!href) {
      return Promise.reject(
        new Error(
          'Expected data-href to be valid or data-replacable-interval to not be present'
        )
      );
    }

    return fetchPage(
      href,
      this.method,
      this.accept,
      this.abortController.signal,
      punchThrough
    )
      .then(this.onReplace)
      .catch(this.onReplaceError);
  }

  private hasFocus(
    element: HTMLElement,
    focusedElement = document.activeElement
  ): boolean {
    return !!(
      focusedElement &&
      (element === focusedElement || element.contains(focusedElement))
    );
  }

  private queueReplacementOnBlur(
    existing: HTMLElement,
    replacement: Element
  ): void {
    const focusedElement = document.activeElement;
    if (!(focusedElement instanceof HTMLElement)) {
      return;
    }

    console.log(
      '[replaceable] not replacing element yet, because it has focus',
      { existing, replacement }
    );

    const replaceWhenReady = (e: FocusEvent): void => {
      if (this.cleanUpQueue) {
        this.cleanUpQueue();
        this.cleanUpQueue = undefined;
      }

      const newFocus = e.target;

      // Focus is still inside the existing element (for example a different
      // link or button), so re-schedule the replacement.
      if (
        newFocus instanceof HTMLElement &&
        this.hasFocus(existing, newFocus as HTMLElement)
      ) {
        // Elements that opt-out of markable may have been removed when they are
        // pressed or followed. So what is done here is check if an element has
        // been opting out of marking (a common source triggering new content
        // when replacing) and if so, see if the new data still contains an
        // unmarkable element.
        //
        // - If the current element is unmarkable, and the new content still has
        //   unmarkable, it's likely unchanged, so don't replace the element.
        // - If the current element is unmarkable, but the new content no longer
        //   has that content, it's likely that the element was just marked and
        //   its content changed.
        //
        // This has as added benefit that something that was previously markable
        // but isn't anymore is always replaced.
        //
        const newFocusIsUnmarkable =
          newFocus && newFocus.closest('[data-markable="false"]');
        const replacementHasUnmarkable = replacement.querySelector(
          '[data-markable="false"]'
        );

        if (!newFocusIsUnmarkable || replacementHasUnmarkable) {
          return this.queueReplacementOnBlur(existing, replacement);
        }

        this.replaceElement(existing, replacement);

        // Restore focus to the closest element. This ensures that focus is not
        // completely lost when the previously focused element is removed or
        // replaced.
        if (replacement instanceof HTMLElement) {
          const focusable =
            replacement.querySelector('[tabindex]') ||
            replacement.closest('[tabindex], a[href], button');

          (focusable as HTMLElement).focus();
        }
      } else {
        this.replaceElement(existing, replacement);
      }
    };

    const replaceAfterBlur = (_: FocusEvent): void => {
      focusedElement.removeEventListener('blur', replaceAfterBlur);

      setTimeout(() => {
        if (
          // No longer replacable
          !this.node.contains(existing) ||
          // Already replaced
          this.node.contains(replacement)
        ) {
          return;
        }

        // Still focus inside
        if (
          document.activeElement &&
          this.node.contains(document.activeElement)
        ) {
          return;
        }

        this.replaceElement(existing, replacement);
      }, 100);
    };

    // Blur cannot be used as document.activeElement is always body during
    // execution of the blur event. Instead, focusin bubbles and has the element
    // that will be focused as target
    document.addEventListener('focusin', replaceWhenReady);

    // On blurring of the element in question we start a timer that is triggered
    // if no element has focus.
    focusedElement.addEventListener('blur', replaceAfterBlur);

    // Store clean up
    this.cleanUpQueue = (): void => {
      document.removeEventListener('focusin', replaceWhenReady);
      focusedElement.removeEventListener('blur', replaceAfterBlur);
    };
  }

  private replaceElement(existing: Element, replacement: Element): void {
    existing.replaceWith(replacement);
    upgradeAll();

    if (this.emitEvent) {
      emit(this.emitEvent, { replacable: this, existing, replacement });
    }
  }
}

export function replacables(): void {
  upgrade('[data-replacable]');
}

export function replacable(component: HTMLElement): Replacable {
  return new Replacable(component);
}

register<HTMLElement>(
  '[data-replacable]',
  ({ element }) => replacable(element).destroy
);
