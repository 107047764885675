import { Controller } from '@hotwired/stimulus';
import CableReady from 'cable_ready';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    this.pageTitle.content = document.title;
    this.initializeCount();
  }

  decrease(event) {
    const { count } = event.detail;
    if (!count) return;

    const currentCount = this.unreadCount;
    const computedCount = currentCount - count;
    const latestCount = computedCount < 0 ? 0 : computedCount;

    this.element.dataset.unread = latestCount;
    this.setDocumentTitle(latestCount);
  }

  update(event) {
    this.element.dataset.unread = event.detail.unread;
    this.setDocumentTitle(event.detail.unread);
  }

  setDocumentTitle(unreadCount) {
    const count = parseInt(unreadCount);

    const msg = count > 1 ? 'Messages' : 'Message';

    if (count > 0) {
      document.title = `(${count}) New ${msg}! | Wondr Medical`;
    } else {
      document.title = this.pageTitle.content;
    }

    this.updateFavicon(count > 0);
  }

  updateFavicon(notified) {
    const icons = [
      ...document.querySelectorAll('link[rel="icon"]'),
      ...document.querySelectorAll('link[rel="apple-touch-icon"]'),
      ...document.querySelectorAll('link[rel="shortcut icon"]'),
    ];

    icons.forEach((icon) => {
      const path = notified ? icon.dataset.notified : icon.dataset.default;
      icon.setAttribute('href', path);
    });
  }

  initializeCount() {
    const controller = this;

    $.ajax({
      url: this.data.get('src'),
      success: function (res) {
        controller.element.dataset.unread = res.unread;
        controller.setDocumentTitle(res.unread);
      },
      error: function (error) {
        console.warn(error);
      },
    });
  }

  get unreadCount() {
    return parseInt(this.element.dataset.unread);
  }

  get pageTitle() {
    return document.querySelector("meta[name='page-title']");
  }
}
