/**
 * Adding [data-iterable] to elements makes them iterable using the j and k
 * keyboard keys.
 *
 * It's important that these elements are focusable, so don't forget to add the
 * tabindex attribute (with a value of -1) if the iterable element isn't
 * normally focusable.
 *
 * When "iterating", the next (j) or previous (k) iterable will be focused, and
 * become the starting point when tabbing. In general, "rows" should be iterable
 * which means that individual posts should be marked with [data-iterable].
 */

import { isFocusable } from './aria';
import { onPageLoad } from './spa';

// Don't cache this, so it works when new elements are added
function iterables(): NodeListOf<HTMLElement> {
  return document.querySelectorAll<HTMLElement>('[data-iterable]');
}

function findIterableBoundary(element: HTMLElement): HTMLElement | undefined {
  const iterableList = iterables();

  let found: HTMLElement | false = false;

  iterableList.forEach((iterable) => {
    // Only call callback once
    if (found) {
      return;
    }

    // If we're currently inside the iterable, this must be the closest
    if (iterable.contains(element) || iterable === element) {
      found = iterable;
      return;
    }
  });

  // Fallback to the first item
  if (!found) {
    found = iterableList.item(0);
  }

  return found;
}

function focusPrevious(element: HTMLElement): void {
  console.debug('[iterable] previous');

  const actualBoundary =
    element.closest<HTMLElement>('[data-iterable]') ||
    findIterableBoundary(element);
  const iterableList = iterables();

  if (!actualBoundary) {
    return;
  }

  // When found, focus the previous one
  iterableList.forEach((iterable, index) => {
    if (iterable !== actualBoundary) {
      return;
    }

    for (let i = index - 1; i >= 0; i--) {
      const item = iterableList.item(i);
      if (isFocusable(item, true)) {
        item.focus();
        break;
      }
    }
  });
}

function focusNext(element: HTMLElement): void {
  console.debug('[iterable] next');

  const actualBoundary =
    element.closest<HTMLElement>('[data-iterable]') ||
    findIterableBoundary(element);
  const iterableList = iterables();

  if (!actualBoundary) {
    return;
  }

  // When found, focus the next one
  iterableList.forEach((iterable, index) => {
    if (iterable !== actualBoundary) {
      return;
    }

    for (let i = index + 1; i < iterableList.length; i++) {
      const item = iterableList.item(i);
      if (isFocusable(item, true)) {
        item.focus();
        break;
      }
    }
  });
}

function onIterate(e: KeyboardEvent): void {
  if (!e.target) {
    return;
  }

  const element = e.target as HTMLElement;

  // Inside input/textarea, we don't want to capture iteration keys
  if (
    element.tagName &&
    ['INPUT', 'TEXTAREA'].some((name) => element.tagName === name)
  ) {
    return;
  }

  // Editable
  if (element.isContentEditable) {
    return;
  }

  if (e.code === 'KeyJ' || e.key === 'j') {
    focusNext(element);
    return;
  }

  if (e.code === 'KeyK' || e.key === 'k') {
    focusPrevious(element);
    return;
  }
}

function enableIteration(): () => void {
  document.addEventListener('keypress', onIterate);
  return disableIteration;
}

function disableIteration(): void {
  document.removeEventListener('keypress', onIterate);
}

onPageLoad(enableIteration);
