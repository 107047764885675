import { emitter } from './emitter';
import { register, upgrade } from './ujs';
import { toggleHidden } from './utils';

type EventableEvent = {
  id: string;
  state: boolean;
};

/**
 * Generic component that responds to as specific emitted action, and takes care
 * of refocussing itself if there is a focusable alternative after the action.
 *
 * The default implementation of {#act} is to hide the component if its event
 * is emitted. It unhides the component if the event's opposite is emitted.
 * Classes that extend Eventable can override act to have their own behaviour.
 *
 * @see Bookmarkable
 * @see Followable
 * @see Likable
 */
export class Eventable<TypedEvent extends EventableEvent> {
  protected readonly activeState: 'true' | 'false';
  protected readonly targetId: string;

  constructor(
    protected readonly component: HTMLElement,
    protected readonly dataType: string,
    protected readonly eventType: string
  ) {
    this.activeState = component.getAttribute(`data-${this.dataType}`) as
      | 'true'
      | 'false';
    this.targetId = component.getAttribute('data-id')!;

    if (this.activeState !== 'true' && this.activeState !== 'false') {
      throw new Error(`Expect component to have [data-${this.dataType}]`);
    }

    this.onEvent = this.onEvent.bind(this);
    this.onEventRefocus = this.onEventRefocus.bind(this);
    this.destroy = this.destroy.bind(this);

    emitter.on(`${this.eventType}:${this.targetType}`, this.onEvent);

    // The ajax:success event is an artifact from Rails UJS. It's the default
    // trigger to try to refocus an eventable. Because the default behaviour of
    // eventable is to hide itself (when successfull) or show itself (when the
    // opposite of the targetState is evented), the focus on an element that
    // activates itself, is lost.
    //
    // This event listener runs _after_ the event is emitted (see emitter).
    this.component.addEventListener('ajax:success', this.onEventRefocus);
  }

  protected get targetType(): string {
    return this.component.getAttribute('data-type')!;
  }

  public destroy(): void {
    emitter.off(`${this.eventType}:${this.targetType}`, this.onEvent);

    this.component.removeEventListener('ajax:success', this.onEventRefocus);
  }

  /**
   * Act on a applicable event.
   *
   * Override this is a different type of action should take place.
   *
   * @param matchesState if true, the targetState has been emitted
   * @param _event the original event that sourced this
   */
  protected act(matchesState: boolean, _event: TypedEvent): void {
    toggleHidden(this.component, matchesState);
  }

  /**
   * Act on a refocus event.
   *
   * Override this if refocus should focus something else.
   *
   * @param pairedElement the paired element that was found.
   */
  protected refocus(pairedElement: HTMLElement | null): void {
    pairedElement?.focus();
  }

  /**
   * Gets the paired element (element within the same context boundary or
   * container that has the same event, but different state).
   */
  protected get pairedElement(): HTMLElement | null {
    if (!this.component.parentElement) {
      return null;
    }

    const fields = [
      `[data-${this.dataType}]:not([data-${this.dataType}="${this.activeState}"])`,
      `[data-type="${this.targetType}"]`,
      `[data-id="${this.targetId}"]`,
    ].join('');

    return this.component.parentElement.querySelector<
      HTMLAnchorElement | HTMLButtonElement
    >(
      [
        `a${fields}`,
        `button${fields}`,
        `input[type="submit"]${fields}`,
        `form${fields} > button`,
        `form${fields} > a`,
        `form${fields} > input[type="submit"]`,
      ].join(', ')
    );
  }

  private onEvent(event?: TypedEvent): void {
    if (!event) {
      return;
    }

    // Item needs to be this item's id
    const { id, state } = event;
    if (this.targetId !== String(id)) {
      return;
    }

    console.debug(
      `[${this.eventType}] ${this.targetType} (${this.targetId})`,
      state
    );

    this.act(this.activeState === String(state), event);
  }

  private onEventRefocus(_: Event): void {
    if (!this.component.parentElement) {
      return;
    }

    this.refocus(this.pairedElement);
  }
}
