import onmount from 'onmount'

onmount('.js-m-agenda', function() {
  /*
    1. Query sliderRow until they're present
    2. Capture the b-cells
    3. Find the b-cell with the tallest height
    4. Set the sliderRow's height
  */

  let sliderRows, buttons

  const sortByHighest = (a: number, b: number):number => {
    if (a < b) {
      return 1
    }

    if (a > b) {
      return -1;
    }

    return 0
  }



  const getSliderRow = (): void => {
    const rows = this.querySelectorAll('.slider-row')


    if (rows.length) {
      sliderRows = rows
      buttons = this.querySelectorAll('button[data-header-tab]')
      clearInterval(setSliderRow)

      if (window.innerWidth >= 992) {
        setSliderHeight(rows[0])
      }

    } else {
      console.log('Flickity has not yet loaded')
    }
  }

  const setSliderRow = setInterval(getSliderRow, 120)

  const getButtons = () => {
    const btns = this.querySelectorAll('button[data-header-tab]')

    if (btns.length) {
      buttons = btns
      clearInterval(setButtons)
      triggerButtons(btns)
    } else {
      console.log('Buttons not yet loaded')
    }
  }

  const setButtons = setInterval(getButtons, 120)

  // Initial State
  const setSliderHeight = (slider) => {
    if (!slider) {
      return
    }

    const bCells = slider.querySelectorAll('.b-cell')
    let maxHeight
    let heights = []

    const getHeight = () => {
      if (bCells.length === 1) {
        maxHeight = bCells[0].getBoundingClientRect().height
      } else if (bCells.length > 1) {
        bCells.forEach((bCell) => heights = [...heights, bCell.getBoundingClientRect().height])
        maxHeight = heights.sort((a, b) => sortByHighest(a, b))[0]
      }

      if (maxHeight > 0) {
        clearInterval(setHeight)
        slider.style.height = `${maxHeight}px`
      }
    }

    const setHeight = setInterval(getHeight, 120)
  }

  const triggerButtons = (buttons) => {
    if (!buttons.length) {
      return
    }

    buttons.forEach((button) => {
      const target = button.dataset.headerTab

      const targetContent = this.querySelector(`div[data-content-tab="${target}"]`)
      const contentSliderRow = targetContent.querySelector('.slider-row')
      const lessLinks = contentSliderRow.querySelectorAll('.js-less-link')
      const moreLinks = contentSliderRow.querySelectorAll('.js-more-link')

      moreLinks.forEach((link) => {
        link.addEventListener('click', () => {
          setTimeout(() => {
            setSliderHeight(contentSliderRow)
            console.log('Module resized')
          }, 60)
        })
      })

      lessLinks.forEach((link) => {
        link.addEventListener('click', () => {
          setTimeout(() => {
            setSliderHeight(contentSliderRow)
            console.log('Module resized')
          }, 60)
        })
      })

      button.addEventListener('click', () => {
        setSliderHeight(contentSliderRow)
      })
    })

  }

})