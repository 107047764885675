import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    this.onFeedback = this.onFeedback.bind(this);
  }

  connect() {
    this.element.addEventListener('click', this.onFeedback);
  }

  disconnect() {
    this.element.removeEventListener('click', this.onFeedback);
  }

  onFeedback() {
    const textEl = this.element.querySelector('[data-text]');
    const initialText = this.element.textContent;
    const element = textEl || this.element;

    element.textContent = 'Copied!';

    let timeout = setTimeout(() => {
      element.textContent = initialText;
      clearTimeout(timeout);
    }, 1000);
  }
}
