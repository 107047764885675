import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  send(event) {
    let data = event.currentTarget.dataset;

    $.ajax({
      url: '/tracking/recommendation_clicks',
      method: 'POST',
      data: {
        tracking: {
          activity: data.activity,
          item_type: data.itemType,
          item_id: data.itemId,
        },
      },
    });
  }
}
