import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['topic', 'reason', 'submit'];

  validate() {
    const topicsChecked = this.topicTargets.filter(
      (topic) => topic.checked
    ).length;
    const reasonsChecked = this.reasonTargets.filter(
      (reason) => reason.checked
    ).length;
    const minTopicsCount = Number(
      this.element.getAttribute('data-min-topics-count')
    );
    const minReasonsCount = Number(
      this.element.getAttribute('data-min-reasons-count')
    );
    if (topicsChecked >= minTopicsCount && reasonsChecked >= minReasonsCount) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }
}
