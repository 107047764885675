import onmount from 'onmount'
import $ from 'jquery'
import '@selectize/selectize/dist/js/selectize.min.js'

onmount('.js-edit-page-form-wrapper', function(mountEvent) {
  const wrapper                  = $(this)
  const generateSlugApiPath      = '/ch/' + wrapper.attr('data-channel') + '/p/generate_slug.json'
  const checkSlugValidityApiPath = '/ch/' + wrapper.attr('data-channel') + '/p/' + wrapper.attr('data-channel-page') + '/check_slug_validity.json'
  const form                     = wrapper.find('.js-edit-page-form')
  const pageTitle                = wrapper.find('.js-edit-page-title')
  const emptyPageTitleError      = wrapper.find('.js-edit-page-empty-title-error')
  const slugFormGroup            = wrapper.find('.js-edit-page-slug-form-group')
  const slug                     = wrapper.find('.js-edit-page-slug')
  const slugHint                 = wrapper.find('.js-edit-page-slug-hint')

  const emptySlugError           = wrapper.find('.js-edit-page-empty-slug-error')
  const invalidSlugError         = wrapper.find('.js-edit-page-invalid-slug-error')
  const submitButton             = wrapper.find('.js-edit-page-submit')

  const slugSuggestion           = wrapper.find('.js-edit-page-slug-suggestion')
  const slugSuggestionText       = wrapper.find('.js-edit-page-slug-suggestion-text')
  const applySlugSuggestion      = wrapper.find('.js-edit-page-apply-slug-suggestion')

  slugSuggestion.addClass('hidden')
  emptySlugError.addClass('hidden')
  invalidSlugError.addClass('hidden')
  emptyPageTitleError.addClass('hidden')

  const errors = [emptyPageTitleError, emptySlugError, invalidSlugError]
  const warnings = [slugSuggestion]
  let slugManuallyUpdated = false
  let suggestedSlug = ''

  const doneTypingInterval = 500;
  let titleTypingTimer;
  let slugTypingTimer;

  wrapper.find('.js-plain-selectize.-multiple').selectize({
    maxItems: null
  })

  wrapper.find('.js-plain-selectize.-single').selectize({
    maxItems: 1
  })

  pageTitle.on('keyup', function() {
    clearTimeout(titleTypingTimer);
    titleTypingTimer = setTimeout(function() {
      if(!slugManuallyUpdated && !pageTitle === '') {
        $.post(generateSlugApiPath, { title: pageTitle.val() })
          .done(function(data) {
            suggestedSlug = data.slug

            // Suggest the new slug if it differs from the old one :
            if(suggestedSlug !== slug.val()) {
              toggleAndHideOthers(slugSuggestion, [slugHint, emptySlugError, invalidSlugError])
              let suggestion = 'Newly recommended slug: "' + suggestedSlug +  '". '
              slugSuggestionText.text(suggestion)
            } else {
              toggleAndHideOthers(slugHint, [slugSuggestion, emptySlugError, invalidSlugError])
            }
            tryEnablingSubmit();
          })
          .fail(function() {
            // Fail quietly. The user will not receive a suggestion to edit their slug.
            tryEnablingSubmit();
          })
      } else {
        tryEnablingSubmit();
      }
    }, doneTypingInterval);
  })

  pageTitle.on('keydown', function() {
    clearTimeout(titleTypingTimer);
  })

  applySlugSuggestion.on('click', function(event) {
    event.preventDefault();
    slug.val(suggestedSlug)
    tryEnablingSubmit();
  })

  slug.on('keypress', function(event) {
    const char = String.fromCharCode(event.keyCode);

    // Char codes below allow, in order: backspace, left arrow key, right arrow key :
    if(char.match(/[^a-z0-9\-\x08\x37\x39]/)) event.preventDefault();

    slugManuallyUpdated = true;
  })

  slug.on('keyup', function() {
    clearTimeout(slugTypingTimer);
    slugTypingTimer = setTimeout(function() {
      tryEnablingSubmit();
    }, doneTypingInterval);
  })

  slug.on('keydown', function() {
    clearTimeout(slugTypingTimer);
  })

  function toggleAndHideOthers(toggleOn, others = []) {
    if(errors.includes(toggleOn)) {
      toggleOn.closest('.form-group').addClass('-error')
    } else {
      toggleOn.closest('.form-group').removeClass('-error')
    }

    if(warnings.includes(toggleOn)) {
      toggleOn.closest('.form-group').addClass('-warning')
    } else {
      toggleOn.closest('.form-group').removeClass('-warning')
    }

    for(const element of others) {
      element.addClass('hidden')
    }

    toggleOn.removeClass('hidden')
  }

  function hideAll(elements = []) {
    for(const element of elements) {
      element.closest('.form-group').removeClass('-error')
      element.addClass('hidden')
    }
  }

  function tryEnablingSubmit() {
    submitButton.prop('disabled', true);

    let titleIsGood = false;
    if(pageTitle.val() === '') {
      toggleAndHideOthers(emptyPageTitleError, [])
    } else {
      hideAll([emptyPageTitleError]);
      titleIsGood = true;
    }

    if(slug.val() === '') {
      toggleAndHideOthers(emptySlugError, [slugHint, invalidSlugError]);
    } else {
      $.post(checkSlugValidityApiPath, { slug: slug.val() })
        .done(function(data, status) {
          if(!data.slug_is_valid) {
            toggleAndHideOthers(invalidSlugError, [slugSuggestion, slugHint, emptySlugError])
            let list = '<ul>'
            for(const error of data.errors) {
              list += '<li>' + error + '</li>'
            }
            list += '</ul>'
            invalidSlugError.html(list)
          } else {
            toggleAndHideOthers(slugHint, [slugSuggestion, invalidSlugError, emptySlugError])
            if(titleIsGood) submitButton.prop('disabled', false);
          }
        })
        .fail(function() {
          // Fail quietly. This will be double-checked on the back end.
          if(titleIsGood) submitButton.prop('disabled', false);
        })
    }
  }

})
