import { KEY_NAMES, KEY_NAMES_LEGACY } from './aria';
import { Tributable } from './tributable';
import {
  isHtmlElementNode,
  isPrintableCharacter,
  isTextNode,
  toggleHidden,
} from './utils';

export type TextInput = HTMLInputElement | HTMLTextAreaElement;
export type InteractiveInput = HTMLElement;
export type Placeholder = HTMLElement;

declare type DeprecatedWindow = Window &
  typeof globalThis & { clipboardData: ClipboardEvent['clipboardData'] };

// Normally this would have ended with (?<!\.): can't use negative lookbehind because ie/safari
// It would also start with (?<=\s|\w|^), but  can't use positive lookbehind because ie/safari
// This should match the Regex in app/models/concerns/taggable.rb
const LINK_REGEX =
  /\b(?:https?:\/\/|www\.)(?:[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,}|localhost:3000)\b([-a-zA-Z0-9@:%_+.~#?/&=*!"$'(),;^>[\]\\`{}|]*[-a-zA-Z0-9@%+#/&=$()^>[\]\\`{}|])?/i;

// Normally this would have started with negative lookbehind
const HASHTAG_REGEX = /(?<entity>(?!\b)|&)#[-_\w]+/;

function tag(content: string, type: string, value?: string): HTMLSpanElement {
  const element = document.createElement('span');
  element.classList.add('tag', `tag--${type}`);
  element.setAttribute('data-type', type);

  if (value) {
    element.setAttribute('data-value', value);
  }

  element.textContent = content;
  return element;
}

/**
 * This is a minimal implementation of a TextEditor without requiring ActiveText
 * or using a full-fledged package. It takes care of pasting HTML (removes it)
 * and enabled attributing. Mentions, Hashtags and Emojis are supported.
 *
 * If something more complex is required in the future, the following is
 * interesting:
 *
 * @see https://ckeditor.com/docs/ckeditor5/latest/examples/framework/chat-with-mentions.html
 *
 * In order to make something content editable, create a new instance with the
 * regular text input (input or textarea), the interactive input (any element
 * that should be turned into editable element) and an optional placeholder that
 * is shown when the interactive element is empty, and hidden otherwise.
 */
export class ContentEditable {
  private tribute: Tributable | undefined;

  /**
   * Create a new content editable element
   *
   * @param textInput the regular input where the content is stored
   * @param interactiveInput the content-editable input
   * @param placeholder optional placeholder
   */
  constructor(
    private readonly textInput: TextInput,
    private readonly interactiveInput: InteractiveInput,
    private readonly placeholder: Placeholder,
    public onLinkTag?: ((link: string) => void) | undefined
  ) {
    this.onKey = this.onKey.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onInput = this.onInput.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
    this.onStoreInput = this.onStoreInput.bind(this);
    this.onPaste = this.onPaste.bind(this);
    this.onDragEvent = this.onDragEvent.bind(this);

    this.retagNode = this.retagNode.bind(this);

    toggleHidden(this.textInput, true);
    toggleHidden(this.interactiveInput, false);
    toggleHidden(this.placeholder, this.content !== '');

    this.interactiveInput.contentEditable = 'true';

    this.interactiveInput.addEventListener('keydown', this.onKey);
    this.interactiveInput.addEventListener('keyup', this.onKeyUp);
    this.interactiveInput.addEventListener('input', this.onInput);
    this.interactiveInput.addEventListener('click', this.onInputClick);
    this.interactiveInput.addEventListener('blur', this.onStoreInput);
    this.interactiveInput.addEventListener('paste', this.onPaste);

    this.interactiveInput.addEventListener('dragover', this.onDragEvent);
    this.interactiveInput.addEventListener('drop', this.onDragEvent);
  }

  public destroy(): void {
    this.interactiveInput.removeEventListener('keydown', this.onKey);
    this.interactiveInput.removeEventListener('keyup', this.onKeyUp);
    this.interactiveInput.removeEventListener('input', this.onInput);
    this.interactiveInput.removeEventListener('click', this.onInputClick);
    this.interactiveInput.removeEventListener('blur', this.onStoreInput);
    this.interactiveInput.removeEventListener('paste', this.onPaste);

    this.interactiveInput.removeEventListener('dragover', this.onDragEvent);
    this.interactiveInput.removeEventListener('drop', this.onDragEvent);

    this.tribute?.destroy();
  }

  public clear(): void {
    while (this.interactiveInput.lastChild) {
      this.interactiveInput.removeChild(this.interactiveInput.lastChild);
    }

    this.textInput.value = '';
    toggleHidden(this.placeholder, false);
  }

  public focus(): void {
    this.interactiveInput.focus();
  }

  public retag(): void {
    this.interactiveInput.childNodes.forEach(this.retagNode);
    this.interactiveInput.normalize();
  }

  private retagNode(node: ChildNode): void {
    if (!isTextNode(node)) {
      if (isHtmlElementNode(node) && node.tagName === 'DIV') {
        node.childNodes.forEach(this.retagNode);
      }
      return;
    }

    const selection = window.getSelection();
    const range =
      (selection?.rangeCount || 0) > 0 ? selection?.getRangeAt(0) : null;

    const currentlyInside =
      selection?.containsNode(node) ||
      (selection?.isCollapsed && selection.focusNode === node);

    const text = node.textContent || '';

    const linkMatch = text.match(LINK_REGEX);
    if (linkMatch) {
      // Normally this would not be necessary with a negative lookbehind regex.
      const link =
        linkMatch[0].slice(-1) === '.'
          ? linkMatch[0].slice(0, -1)
          : linkMatch[0];
      const pre = text.substring(0, linkMatch.index!);
      const post = text.substring(linkMatch.index! + link.length);

      const value = link.startsWith('www.') ? `https://${link}` : undefined;
      const linkTag = tag(link, 'url', value);
      linkTag.setAttribute('contenteditable', 'false');

      if (!selection?.isCollapsed)
        console.error(
          'Invariant failed: should not be able to retag while a non-collapsed selection is enabled. Trying to continue.'
        );

      let selectionOffset: number | null = null;
      let shouldMoveToEnd = false;

      if (selection?.anchorNode == node) {
        selectionOffset = selection!.anchorOffset;
      }
      if (selection?.anchorNode == this.interactiveInput) {
        shouldMoveToEnd =
          this.interactiveInput.childNodes.length == selection?.anchorOffset;
      }

      if (this.onLinkTag) {
        this.onLinkTag(value || link);
      }

      const postTextNode = document.createTextNode(post);
      const preTextNode = document.createTextNode(pre);

      const parts: Node[] = [preTextNode, linkTag, postTextNode];
      node.replaceWith(...parts);

      // Restore original caret
      if (selectionOffset) {
        console.debug('[content-editable]', { selectionOffset });
        selection!.empty();
        if (selectionOffset <= preTextNode.nodeValue!.length) {
          selection!.setBaseAndExtent(
            preTextNode,
            selectionOffset,
            preTextNode,
            selectionOffset
          );
        } else {
          let postOffset = selectionOffset - pre.length - link.length;

          if (postOffset < 0) {
            postOffset = 0;
          }

          selection!.setBaseAndExtent(
            postTextNode,
            postOffset,
            postTextNode,
            postOffset
          );
        }
      }
      if (shouldMoveToEnd) {
        selection!.empty();
        selection!.setBaseAndExtent(
          postTextNode,
          postTextNode.length,
          postTextNode,
          postTextNode.length
        );
      }

      node.normalize();

      console.debug('[content-editable]', {
        selection: document.getSelection(),
      });
      return;
    }

    const hashtagMatch = text.match(HASHTAG_REGEX);
    if (hashtagMatch && hashtagMatch[0][0] !== '&') {
      const hashtag = hashtagMatch[0];
      const pre = text.substring(0, hashtagMatch.index!);
      const post = text.substring(hashtagMatch.index! + hashtag.length);

      const hashtagTag = tag(hashtag, 'hashtag');
      hashtagTag.setAttribute('contenteditable', 'false');

      if (!selection?.isCollapsed)
        console.error(
          'Invariant failed: should not be able to retag while a non-collapsed selection is enabled. Trying to continue.'
        );

      let selectionOffset: number | null = null;
      let shouldMoveToEnd = false;

      if (selection?.anchorNode == node) {
        selectionOffset = selection!.anchorOffset;
      }
      if (selection?.anchorNode == this.interactiveInput) {
        shouldMoveToEnd =
          this.interactiveInput.childNodes.length == selection?.anchorOffset;
      }

      const postTextNode = document.createTextNode(post);
      const preTextNode = document.createTextNode(pre);

      const parts: Node[] = [preTextNode, hashtagTag, postTextNode];
      node.replaceWith(...parts);

      // Restore original caret
      if (selectionOffset) {
        selection!.empty();
        if (selectionOffset <= preTextNode.nodeValue!.length) {
          selection!.setBaseAndExtent(
            preTextNode,
            selectionOffset,
            preTextNode,
            selectionOffset
          );
        } else {
          let postOffset = selectionOffset - pre.length - hashtag.length;
          if (postOffset < 0) {
            postOffset = 0;
          }
          selection!.setBaseAndExtent(
            postTextNode,
            postOffset,
            postTextNode,
            postOffset
          );
        }
      }
      if (shouldMoveToEnd) {
        selection!.empty();
        selection!.setBaseAndExtent(
          postTextNode,
          postTextNode.length,
          postTextNode,
          postTextNode.length
        );
      }

      node.normalize();

      console.debug('[content-editable]', {
        currentlyInside,
        selection,
        range,
        hashtagTag,
        s: hashtagTag.nextSibling,
      });

      return;
    }

    // More?
  }

  public get length(): number {
    return this.content.length;
  }

  public get maxLength(): number {
    return this.textInput.maxLength;
  }

  public set maxLength(next: number) {
    this.textInput.maxLength = next;
  }

  public get content(): string {
    const result = untag(this.interactiveInput);

    // No more than 2x newline, per "paragraph".
    return result
      .replace(/\n\n+/g, '\n\n')
      .replace(/[ ]+/g, ' ')
      .split('\n')
      .map((line = '') => {
        return line.trim();
      })
      .join('\n')
      .trim();
  }

  public get tributable(): boolean {
    return this.tribute !== undefined;
  }

  public set tributable(next: boolean) {
    if (this.tributable === next) {
      return;
    }

    if (next) {
      this.tribute = new Tributable(this.interactiveInput);
    } else {
      this.tribute!.destroy();
    }
  }

  public get autosubmit(): boolean {
    return this.interactiveInput.hasAttribute('data-autosubmit');
  }

  public get disabled(): boolean {
    return !this.interactiveInput;
  }

  public set disabled(next: boolean) {
    this.interactiveInput.setAttribute('contenteditable', String(!next));
  }

  public hidePlaceholder(): void {
    toggleHidden(this.placeholder, true);
  }

  private onPaste(event: ClipboardEvent): void {
    const clipboardData =
      event.clipboardData || (window as DeprecatedWindow).clipboardData;
    const pasted = clipboardData?.getData('text')?.trim();
    if (!pasted) {
      // Prevents pasting images etc.
      event.preventDefault();
      return;
    }

    const selection = window.getSelection();

    if (!selection || !selection.rangeCount) {
      return;
    }

    const pasteHelperNode = document.createElement('span');
    pasteHelperNode.innerHTML = pasted;

    const pasteText = pasteHelperNode.textContent || '';
    const pasteNode = document.createTextNode(pasteText);

    // Insert the pasted data
    const range = selection.getRangeAt(0)!;
    range.deleteContents();
    range.insertNode(pasteNode);

    // Preserve selection
    const nextRange = range.cloneRange();
    nextRange.setStartAfter(pasteNode);
    nextRange.collapse(true);
    selection.removeAllRanges();
    selection.addRange(nextRange);

    pasteNode.parentNode?.normalize();
    event.preventDefault();

    this.retag();

    toggleHidden(this.placeholder, this.content !== '');
  }

  private onDragEvent(e: DragEvent): void {
    e.preventDefault();
  }

  private onStoreInput(): void {
    this.textInput.value = this.content;

    this.retag();
  }

  private onInput(): void {
    // Tribute occasionally will keep nested spans, depending on the browser and
    // OS. Visually, text might be on two sides of those spans. Flattening the
    // tree makes it possible to interact with the content and transform it.
    for (let i = 0; i < this.interactiveInput.children.length; i++) {
      const child = this.interactiveInput.children.item(i);
      if (
        child &&
        child.tagName === 'SPAN' &&
        !child.hasAttribute('contenteditable')
      ) {
        child.replaceWith(document.createTextNode(child.textContent || ''));
      }
    }

    // This merges text nodes if they're next to eachother.
    this.interactiveInput.normalize();

    toggleHidden(this.placeholder, this.content !== '');
  }

  private onInputClick(e: MouseEvent): void {
    if (!(e.target instanceof Element)) {
      return;
    }

    const tag = e.target.closest('[contenteditable="false"]');
    if (!tag || !this.interactiveInput.contains(tag)) {
      return;
    }

    const selection = window.getSelection();
    if (!selection) {
      return;
    }

    const range = new Range();
    range.selectNode(tag);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  private onKey(e: KeyboardEvent): void {
    if (e.key === KEY_NAMES.BACKSPACE) {
      const selection = window.getSelection();
      if (selection?.isCollapsed) {
        // Delete tag (inside)
        if (
          isHtmlElementNode(selection.focusNode) &&
          selection.focusNode.hasAttribute('data-type')
        ) {
          selection.focusNode.remove();
          e.preventDefault();
          return;
        }

        // Delete tag (just beforte caret)
        if (selection.anchorNode == this.interactiveInput) {
          // Handle not being inside a text tag
          if (selection.anchorOffset == 0) return;

          const victim =
            this.interactiveInput.childNodes[selection.anchorOffset - 1];
          if (
            victim &&
            isHtmlElementNode(victim) &&
            victim.hasAttribute('data-type')
          ) {
            const caretDestination = victim.previousSibling;
            victim.remove();
            if (caretDestination) {
              // Set caret to the left of the thing we removed
              const length = isHtmlElementNode(caretDestination)
                ? caretDestination.childNodes.length
                : caretDestination.nodeValue!.length;
              selection.removeAllRanges();
              selection.collapse(caretDestination, length);
            }
            e.preventDefault();
            return;
          }
        }

        const previous = selection.focusNode?.previousSibling;
        if (
          selection.focusOffset == 0 &&
          isHtmlElementNode(previous) &&
          previous.hasAttribute('data-type')
        ) {
          previous.remove();
          e.preventDefault();
        }
        // End delete tag
      }
    }

    // Shift + return is the new Return; return might submit if it's autosubmit
    if (e.key !== KEY_NAMES.RETURN || e.shiftKey) {
      // If currently disabled, ignore
      if (this.disabled) {
        e.preventDefault();
      }

      // Limit the length
      if (
        isPrintableCharacter(e.key) &&
        this.maxLength !== -1 &&
        this.maxLength <= this.length
      ) {
        e.preventDefault();
      }
      return;
    }

    // Ignore enter when tribute is showing autocompletion options
    if (this.tribute?.isActive) {
      return;
    }

    // Ignore if shouldn't auto submit
    if (!this.autosubmit) {
      return;
    }

    this.onStoreInput();
    const form = this.textInput.form;
    if (!form) {
      return;
    }

    const action = form.querySelector<HTMLElement>(
      '[data-action="submit"], button[type="submit"], input[type="submit"]'
    );

    if (action) {
      action.click();
    } else {
      form.submit();
    }

    e.preventDefault();
  }

  private onKeyUp({ key }: KeyboardEvent): void {
    if (
      key === KEY_NAMES.RETURN ||
      key === KEY_NAMES.SPACE ||
      key === KEY_NAMES_LEGACY.SPACE
    ) {
      this.retag();
    }
  }
}

/**
 * Removes tags from a node (content-editable node), and returns the string
 * representation. This is what's sent to the backend and stored for posts.
 *
 * @param element
 */
function untag(element: Node): string {
  let result = '';

  // Derives the actual text content of the current input element. There is no
  // need to traverse the entire tree (of the contenteditable), because the
  // onInput and onPaste functions ensure that there is only 1 level of
  // span elements in this tree.
  //
  // Linebreaks need to be turned back into newlines, if existant, and tagged
  // content needs to be turned back into database-retaggable values.
  //
  const count = element.childNodes.length;
  for (let i = 0; i < count; i++) {
    const child = element.childNodes.item(i);
    if (!child) {
      continue;
    }

    // Text node (text outside of html elements)
    if (isTextNode(child)) {
      result += child.nodeValue;
      continue;
    }

    // HTMLElement
    if (isHtmlElementNode(child)) {
      const element = child;

      if (element.tagName === 'BR') {
        result += '\n';
        continue;
      }

      if (element.tagName === 'DIV') {
        result += untag(element);
        result += '\n';
        continue;
      }

      const type = element.getAttribute('data-type');

      if (!type) {
        result += untag(element);
        continue;
      }

      if (type === 'text' || !element.hasAttribute('data-value')) {
        result += element.textContent;
        continue;
      }

      const value = element.getAttribute('data-value') || '';

      /*if (value) {
        switch (type) {
          case 'mention': {
            CACHED_MENTION_TAGS[value] = element.textContent!;
            break;
          }
        }
      }*/

      result += value;
      continue;
    }
  }

  return result;
}

/**
 * Here is the JavaScript implementation of the server-side retagger that runs
 * on post content to highlight certain components.
 *
 * It's commented out because the current technique doesn't require it, but in
 * the next version, we'd probably want to build a state machine and/or use
 * this, to be confident about the outcome.
 */

/* ====== Collapse here ======
const LINK_REGEX = /(?<=\b)(https?:\/\/|www\.)[^ ]*[^ ?!.,:;)\]}"'>]/i;
const HASHTAG_REGEX = /(?!\b)(?<!&)#[-_\w]+/;
const MENTION_REGEX = /(?!\b)@[^ =~#@]*~[0-9]+/;
const CHANNEL_REGEX = /(?!\b)@[^ =~#@]*=[0-9]+/;

type Renderer = (text: string, value?: string) => Node;
type Lookup = (text: string) => string | undefined;

const RENDERERS: Record<string, Renderer> = {
  newline: (text: string) => document.createElement('br'),
  hashtag: (text: string) => tag(text, 'hashtag'),
  mention: (text: string, user?: string) =>
    user ? tag(user, 'mention', text) : document.createTextNode(text),
  channel: (text: string, channel?: string) =>
    channel ? tag(channel, 'mention', text) : document.createTextNode(text),
  url: (text: string, url?: string) => tag(text, 'url', url),
};

const CACHED_MENTION_TAGS: Record<string, string> = {};

const LOOKUP: Record<string, Lookup> = {
  newline: (text: string) => text,
  hashtag: (text: string) => text.slice(1),
  mention: (text: string) =>
    text.indexOf('~') === -1 ? undefined : CACHED_MENTION_TAGS[text],
  channel: (text: string) =>
    text.indexOf('=') === -1 ? undefined : CACHED_MENTION_TAGS[text],
  url: (text: string) =>
    text.startsWith('www.') ? `https://${text}` : undefined,
};


class PostToken {
  private readonly text: string;

  constructor(
    text: string,
    private readonly transformer?: Renderer | undefined,
    private readonly value?: string | undefined
  ) {
    this.text = text.replace(/\s/, ' ');
  }

  public get processed(): boolean {
    return !!this.transformer;
  }

  public get length(): number {
    return this.length;
  }

  public render(): Node {
    return this.transformer
      ? this.transformer(this.text, this.value)
      : document.createTextNode(this.text);
  }

  public splitOn(
    regex: RegExp,
    transformation: Lookup,
    renderer: Renderer
  ): PostToken[] {
    if (this.processed) {
      return [this];
    }

    let rest = this.text;
    let match: RegExpMatchArray | null | undefined;
    const result: PostToken[] = [];

    while ((match = regex.exec(rest))) {
      // Capture and store prefix
      if (match.index! > 0) {
        result.push(new PostToken(rest.substring(0, match.index)));
      }

      // Transform matched
      const matched = match[0];
      result.push(new PostToken(matched, renderer, transformation(matched)));

      rest = rest.substring(match.index! + matched.length);
    }

    if (rest.length > 0) {
      result.push(new PostToken(rest));
    }

    return result;
  }
}

function retag(
  content: string,
  renderers: Record<string, Renderer> = {}
): Node[] {
  const processors = [
    ['newline', /\n/],
    ['url', LINK_REGEX],
    ['hashtag', HASHTAG_REGEX],
    ['mention', MENTION_REGEX],
    ['channel', CHANNEL_REGEX],
  ] as const;

  let tokens = [new PostToken(content)];

  processors.forEach(([type, regex]) => {
    tokens = tokens.reduce((result, token) => {
      const renderer = renderers[type] || RENDERERS[type];
      result.push(...token.splitOn(regex, LOOKUP[type], renderer));
      return result;
    }, [] as PostToken[]);
  });

  return tokens.map((token) => token.render());
}
*/
