import { KEY_NAMES, KEY_NAMES_LEGACY } from './aria';
import { AbortController, fetch } from './fetch';
import { register, upgrade } from './ujs';

/**
 * Allows annotating a link with a "marking" url, which is trigger on regular
 * clicks, including keyboard events. Marking is ignored on "open in new tab" or
 * middle click.
 *
 * @see /app/views/connect/notifications/_notification.html.erb
 *
 * [data-markable=""] (and [data-markable]) makes a container markable. It is
 *   expected to also be focusable to make it interactive, using [tabindex="0"].
 *
 * [data-markable="target"] determines which link to follow after marking has
 *   been achieved.
 *
 * [data-markable="false"] can be used to opt-out of all the behaviour. This can
 *   be used to annotate a form or link that doesn't hit the marking href and
 *   doesn't then navigate to the target url.
 *
 * [data-mark-href] is an optional element with a href to POST to when the
 *   element is interacted with:
 *
 * - If a [data-mark-href] is present, upon clicking (mouse/keyboard) on any
 *     link inside the markable element, or pressing Return on the keyboard on
 *     the normally non-interactive markable element, the mark-href will be
 *     POSTed to and then the original link will be followed.
 * - If a [data-mark-href] is empty or not present, the link will be followed,
 *     including when a keyboad user presses Return on the markable element.
 *
 * @example
 *
 * <article
 *    tabindex="0"
 *    data-markable
 *    data-mark-href="https://example.org/posts/1/mark-as-read"
 * >
 *   <a href="https://example.org/posts/1" data-markable="target">...</a>
 *
 *   <form action="https://example.org/posts/1/mark-as-read"
 *         method="POST"
 *         data-remote="true"
 *         data-markable="false">
 *      <button type="submit">Mark as read</button>
 *   </form>
 * </article>
 *
 */
class Markable {
  private readonly abortController: AbortController;

  constructor(private readonly node: HTMLElement) {
    this.follow = this.follow.bind(this);
    this.gotoTargetUrl = this.gotoTargetUrl.bind(this);
    this.destroy = this.destroy.bind(this);

    this.onFollow = this.onFollow.bind(this);
    this.onKeyboardFollow = this.onKeyboardFollow.bind(this);

    this.node.addEventListener('click', this.onFollow);
    this.node.addEventListener('keypress', this.onKeyboardFollow);

    this.abortController = new AbortController();
  }

  public destroy(): void {
    this.node.removeEventListener('click', this.onFollow);
    this.node.removeEventListener('keypress', this.onKeyboardFollow);

    this.abortController.abort();
  }

  public follow(): void {
    if (!this.markUrl) {
      // Click the node
      this.gotoTargetUrl();
      return;
    }

    const csrfToken = document.querySelector<HTMLMetaElement>(
      "meta[name='csrf-token']"
    )?.content;

    if (!csrfToken) {
      // without a token, the POST will fail, so just continue without marking
      this.gotoTargetUrl();
      return;
    }

    fetch(this.markUrl, {
      signal: this.abortController.signal,
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        accept: 'application/javascript',
      },
    })
      .then(() => {
        this.gotoTargetUrl();
      })
      .catch((err) => {
        console.warn(err);
        this.gotoTargetUrl();
      });

    // Fetch has started, but remove mark url. This allows a user to click
    // twice to go directly. They're likely to ignore the first click. Good for
    // flaky connections.
    this.node.removeAttribute('data-mark-href');
  }

  private get markUrl(): string | null {
    return this.node.getAttribute('data-mark-href');
  }

  private onKeyboardFollow(e: KeyboardEvent): void {
    // Remove this event if keyboard clicks should also NOT trigger a
    // marking. Currently keyboard user _can_ follow the notification by
    // clicking on it using the keyboard.

    if (e.key !== KEY_NAMES.RETURN) {
      return;
    }

    // Only trigger the keyboard follow if it's on the "root" [data-markable]
    // that has [tabindex].
    if (e.target instanceof HTMLElement) {
      if (e.target !== this.node) {
        return;
      }
    }

    e.preventDefault();
    this.follow();
  }

  private onFollow(e: MouseEvent): void {
    // Opt-out of marking
    if (
      e.target instanceof HTMLElement &&
      e.target.closest('[data-markable="false"]')
    ) {
      return;
    }

    // Remove the following line if you want the entire card to link to the
    // notification post.
    if (
      e.target instanceof HTMLElement &&
      !e.target.closest('a') &&
      !e.target.closest('button')
    ) {
      return;
    }

    e.preventDefault();

    // Disable data-remote, or anything else
    e.stopPropagation();

    this.follow();
  }

  private gotoTargetUrl(): void {
    const target = this.node.querySelector<HTMLElement>(
      '[data-markable="target"]'
    );

    if (target?.tagName === 'A') {
      try {
        const safeLink = document.createElement('a');
        safeLink.href = (target as HTMLAnchorElement).href;
        safeLink.click();
      } catch {
        // security error for example
        document.location.href = (target as HTMLAnchorElement).href;
      }
    } else {
      target?.click();
    }
  }
}

export function markables(): void {
  upgrade('[data-markable=""]');
}

export function markable(component: HTMLElement): Markable {
  return new Markable(component);
}

register<HTMLElement>(
  '[data-markable=""]',
  ({ element }) => markable(element).destroy
);
