import { Controller } from '@hotwired/stimulus';
import { upgradeAll } from '../../src/brandless/ujs';

export default class extends Controller {
  click(event) {
    event.preventDefault();

    fetch(this.href, {
      headers: {
        Accept: this.element.dataset.accept,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        if (!html) return;

        // place the articles before the new content checker more button
        this.element.parentNode.parentNode.insertAdjacentHTML('afterend', html);
        upgradeAll();

        // as the new_content_checker will insert a new one
        this.element.remove();

        const firstArticle = document.querySelector('main article');
        if (!firstArticle) return;

        firstArticle.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      });
  }

  get href() {
    return this.element.href;
  }
}
