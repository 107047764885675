import onmount from 'onmount'
import $ from 'jquery'

onmount('.js-module-move-down', function(mountEvent) {
  const button = $(this)

  // Move the module when button is clicked.
  button.on('ajax:before', function(ajaxEvent) {
    const currentModule = button.parents('.js-page-module')
    const nextSibling   = currentModule.next('.js-page-module')

    if (nextSibling.length == 0) { return }

    currentModule.insertAfter(nextSibling)
  })
  
  // Move the module back if on-server move fails.
  button.on('ajax:error', function(ajaxEvent) {
    const currentModule = button.parents('.js-page-module')
    const prevSibling   = currentModule.prev('.js-page-module')

    if (prevSibling.length == 0) { return }

    currentModule.insertBefore(prevSibling)
  })
  
})
