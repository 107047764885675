import $ from 'jquery'
import onmount from 'onmount'
import Sortable from 'sortablejs'

///// Init
onmount('.js-channels-rail-fields', function(mountEvent) {
  ///// Init sortable.js for collected channel ul
  let sortableList = document.getElementById('js-sortable-channel-list')
  if (sortableList != null) { Sortable.create(sortableList) }

  var channelSelectize = $(this).find('.js-channel-selectize')
  channelSelectize.selectize()

  ///// Adding channels to collection after clicking the button
  $('#js-channel-add').click(function() {
    $.ajax({
      url: $(this).data('url') + "/?channel_id=" + channelSelectize.val(),
      type: 'GET',
      error: function(response) { console.log(response) },
      success: function(response) {
        let found = $('#js-channel-selection > ul > li > input[value=' + channelSelectize.val() + ']').length > 0
        if (!found) { $('#js-channel-selection > ul').append(response.html) }
      }
    });
  })
})
