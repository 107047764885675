import { Controller } from '@hotwired/stimulus';
import consumer from '../../src/channels/consumer';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'button',
    'form',
    'messages',
    'message',
    'permission',
    'permissionActions',
    'permissionMessage',
    'seen',
    'sentinel',
    'scrollable',
    'textarea',
    'unreadCounter',
  ];

  connect() {
    this.scrollToBottom({ immediate: true });
    this.subscribe();

    if (this.hasTextareaTarget) {
      this.textareaTarget.focus();
    }

    this.pinMessagesToBottom();
    this.validateUniqueExistence();
  }

  disconnect() {
    this.unsubscribe();
  }

  scrollToBottom({ immediate } = {}) {
    const { scrollHeight, clientHeight } = this.scrollableTarget;
    const scrollBy = scrollHeight - clientHeight;

    if (scrollHeight >= clientHeight) {
      if (!immediate && this.scrollableTarget.scrollBy) {
        this.scrollableTarget.scrollBy({
          top: scrollBy,
          behavior: 'smooth',
        });
      } else {
        this.scrollableTarget.scrollTop = scrollBy;
      }
    }

    if (
      this.hasSentinelTarget &&
      !this.sentinelTarget.hasAttribute('data-controller')
    ) {
      this.sentinelTarget.dataset.controller = 'frame';
    }
  }

  pinMessagesToBottom() {
    if (window.innerWidth < 768) return;

    const containerHeight = this.scrollableTarget.clientHeight;
    const messagesHeight = this.messagesTarget.clientHeight;

    if (messagesHeight > containerHeight - 48) {
      this.scrollableTarget.classList.remove('bottom');
    } else {
      this.scrollableTarget.classList.add('bottom');
    }
  }

  validateUniqueExistence() {
    const detail = { conversationId: this.conversationId };
    const init = { bubbles: true, cancelable: true, detail: detail };
    const evt = new CustomEvent('conversations:checkForDuplicates', init);

    document.dispatchEvent(evt);
  }

  onKeyDown(event) {
    if (event.keyCode == 13 && !event.shiftKey && window.innerWidth >= 992) {
      event.preventDefault();
      Rails.fire(this.formTarget, 'submit');
    }
  }

  unsubscribe() {
    if (this.subscription) consumer.subscriptions.remove(this.subscription);
  }

  subscribe() {
    const controller = this;
    const id = this.conversationId;
    this.subscription = consumer.subscriptions.create(
      { channel: 'ConversationChannel', conversation_id: id },
      {
        connected() {
          console.log(`Connected to conversation #${id}!`);
        },
        disconnected() {
          console.log(`Disconnected from conversation #${id}!`);
        },
        received(data) {
          const { limit_reached } = data;

          if (limit_reached) {
            controller.broadcastLimit();
          }

          controller.insertData(data);
          const unreadCounter = parseInt(
            controller.unreadCounterTarget.dataset.unread
          );
          const seenSentinel = controller.scrollableTarget.querySelector(
            '[data-controller="messaging--seen"]'
          );

          if (!seenSentinel) {
            controller.scrollableTarget.insertAdjacentHTML(
              'beforeend',
              `
              <div data-controller="messaging--seen" data-messaging--seen-conversation-id="${id}"></div>
            `
            );
          }

          const isMinimized =
            controller.element.classList.contains('minimized');

          controller.unreadCounterTarget.dataset.unread = isMinimized
            ? unreadCounter + 1
            : 0;
          controller.pinMessagesToBottom();
        },
      }
    );
  }

  disableSubmit() {
    this.buttonTarget.disabled = true;
  }

  broadcastLimit() {
    const init = { bubbles: true, cancelable: true, detail: null };
    const outboundEvent = new CustomEvent('messaging:disableSubmission', init);

    document.dispatchEvent(outboundEvent);
  }

  resetCounter() {
    this.unreadCounterTarget.dataset.unread = 0;
  }

  setPermission(event) {
    const message = event.currentTarget.dataset.message;
    this.formTarget.classList.remove('-hide');
    this.permissionMessageTarget.textContent =
      event.currentTarget.dataset.notice;
    this.permissionMessageTarget.classList.add('-align-left');
    this.permissionActionsTarget.remove();
    this.textareaTarget.value = message;
    this.setTextareaHeight(this.textareaTarget);
    this.textareaTarget.focus();
  }

  removePermission() {
    this.permissionTarget.remove();
  }

  focusBackOnInput() {
    this.textareaTarget.focus();
  }

  setTextareaHeight(event) {
    const maxHeight = 84;

    const { scrollHeight } = event.target || event;

    if (maxHeight < scrollHeight) {
      this.textareaTarget.parentElement.style.minHeight = `${maxHeight}px`; // Fixes safari height bug
      this.textareaTarget.style.height = `${maxHeight}px`;
    } else {
      this.textareaTarget.parentElement.style.minHeight = `${scrollHeight}px`; // Fixes safari height bug
      this.textareaTarget.style.height = `${scrollHeight}px`;
    }
  }

  insertData(data) {
    this.messagesTarget.insertAdjacentHTML('beforeend', data.html);
    this.scrollToBottom();

    if (data.html.indexOf('data-sender') > -1) {
      this.textareaTarget.value = '';
      this.textareaTarget.focus();
      this.textareaTarget.parentElement.removeAttribute('style');
      this.textareaTarget.removeAttribute('style');
      if (this.hasPermissionTarget) {
        this.removePermission();
      }
    }
  }

  get conversationId() {
    return this.data.get('id');
  }
}
