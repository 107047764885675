import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const conversationsWrapper = document.getElementById(
      'conversations-wrapper'
    );
    const conversation = conversationsWrapper.querySelector(
      `.dm-panel[data-conversation-id="${this.conversationId}"]`
    );

    if (conversation && !conversation.classList.contains('minimized')) {
      this.element.dataset.unread = 0;
    }
  }

  resetCounter(e) {
    const { conversationId } = e.detail;
    if (conversationId !== this.conversationId) return;

    this.element.dataset.unread = 0;
  }

  get conversationId() {
    return this.element.dataset.conversationId;
  }
}
