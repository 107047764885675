import $ from 'jquery'
import onmount from 'onmount'
import Sortable from 'sortablejs'
import '@selectize/selectize/dist/js/selectize.min.js'

///// Init
onmount('.js-video-rail-fields', function(mountEvent) {
  ///// Init sortable.js for collected video ul
  let sortableList = document.getElementById('js-sortable-video-list')
  if (sortableList != null) { Sortable.create(sortableList) }

  ///// Adding videos to collection after clicking the button
  $('#js-video-collect').click(function(e) {
    e.preventDefault()

    $.ajax({
      url: $(this).data('url') + "/?video_id=" + videoSelectize.val(),
      type: 'GET',
      error: function(response) { console.log(response) },
      success: function(response) {
        let found = $('#js-video-selection > ul > li > input[value=' + videoSelectize.val() + ']').length > 0
        if (!found) { $('#js-video-selection > ul').prepend(response.html) }
      }
    });
  })

  ///// VideoSelectBox posts the entered search_query and returns data
  var videoSelectize = $(this).find('.js-videosearch-selectize')
  videoSelectize.selectize({
    load: function(query, callback) {
      $.ajax({
        url: videoSelectize.data('url') + "/?search_query=" + encodeURIComponent(query),
        type: 'GET',
        error: function() { callback() },
        success: function(response) { callback(response.videos) }
      });
    },
    render: { option: function (item, escape) { return '<div>' + escape(item.title) + '</div>'; }},
    create: false,
    loadThrottle: 200,
    openOnFocus: true,
    valueField: 'id',
    labelField: 'title',
    searchField: ['title']
  })

  function disableAddVideoButton() {
    if(videoSelectize.val() === ''){
      $('#js-video-collect').prop('disabled', true)
    } else {
      $('#js-video-collect').prop('disabled', false)
    }
  }

  disableAddVideoButton()
  videoSelectize.change(function(){ disableAddVideoButton() })

  ///// Reactiveness of controls when changing the configuration.mode
  $('#channel_page_module_video_rail_module_configuration_mode').change(function() {
    var val = $(this)[0].value
    switch(val) {
      case 'latest':
        $('.js-videos-select').addClass('hidden')
        $('.js-topic-select').addClass('hidden')
        $('#js-video-selection').addClass('hidden')
        break
      case 'topic_id':
        $('.js-videos-select').addClass('hidden')
        $('#js-video-selection').addClass('hidden')
        $('.js-topic-select').removeClass('hidden')
        break
      case 'video_ids':
        $('.js-topic-select').addClass('hidden')
        $('.js-videos-select').removeClass('hidden')
        $('#js-video-selection').removeClass('hidden')
        break
      }
  })
})
