import { ConfirmDialog } from '../confirmable';
import { Dialog } from '../dialog';
import { humanFileSize, titleize } from '../utils';

/**
 * Sub-dialog that allows configuring an document attachment for a post
 *
 * In order to keep everything in sync, only use the open and close methods on
 * this element and not the global dialog helpers.
 */
export class AddDocumentDialog extends Dialog {
  private readonly actionClose: Element;
  private readonly actionConfirm: HTMLButtonElement;
  private readonly actionDismiss: Element;

  private readonly inputTitle: HTMLInputElement;
  private readonly inputDescription: HTMLInputElement | HTMLTextAreaElement;

  private file: File | undefined;

  private targetPreview: Element;
  private targetFilename: Element;
  private targetFilesize: Element;
  private targetAttachmentMessage: Element;
  private targetDescriptionMessage: Element;
  private targetDescriptionCount: Element;

  constructor(
    private readonly component: HTMLElement,
    private readonly parent: Dialog & {
      setDocument(title: string, description: string): void;
      clearDocument(): void;
    }
  ) {
    super(component.id);

    this.actionClose = this.component.querySelector('[data-action="close"]')!;
    this.actionDismiss = this.component.querySelector(
      '[data-action="dismiss"]'
    )!;
    this.actionConfirm = this.component.querySelector<HTMLButtonElement>(
      'button[data-action="confirm"]'
    )!;

    this.targetPreview = this.component.querySelector(
      '[data-target="document-preview"]'
    )!;
    this.targetFilename = this.component.querySelector(
      '[data-target="filename"]'
    )!;
    this.targetFilesize = this.component.querySelector(
      '[data-target="filesize"]'
    )!;
    this.targetAttachmentMessage = this.component.querySelector(
      '[data-target="attachment-message"]'
    )!;
    this.targetDescriptionMessage = this.component.querySelector(
      '[data-target="description-message"]'
    )!;
    this.targetDescriptionCount = this.component.querySelector(
      '[data-target="limit"]'
    )!;

    this.inputTitle = this.component.querySelector<HTMLInputElement>(
      'input[data-target="title"]'
    )!;

    this.inputDescription = this.component.querySelector<
      HTMLInputElement | HTMLTextAreaElement
    >('input[data-target="description"], textarea[data-target="description"]')!;

    this.open = this.open.bind(this);
    this.cancel = this.cancel.bind(this);
    this.close = this.close.bind(this);
    this.destroy = this.destroy.bind(this);

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onDescriptionInput = this.onDescriptionInput.bind(this);

    this.inputDescription.addEventListener('input', this.onDescriptionInput);

    this.actionDismiss.addEventListener('click', this.cancel);
    this.actionClose.addEventListener('click', this.cancel);
    this.actionConfirm.addEventListener('click', this.onConfirm);

    const form = this.component.closest<HTMLFormElement>('form')!;
    form.addEventListener('submit', this.onFormSubmit);
  }

  public get autofocus(): HTMLElement {
    return this.component.querySelector('input')!;
  }

  public destroy(): void {
    this.inputDescription.removeEventListener('input', this.onDescriptionInput);

    this.actionDismiss.removeEventListener('click', this.cancel);
    this.actionClose.removeEventListener('click', this.cancel);
    this.actionConfirm.removeEventListener('click', this.onConfirm);

    const form = this.component.closest<HTMLFormElement>('form')!;
    form.removeEventListener('submit', this.onFormSubmit);
  }

  /**
   * Opens itself, attempting to replace dialogs that are already out there,
   * namely the "base" create post dialog.
   *
   * @param source the source of opening the dialog, usually a button.
   */
  public open(
    source?: string | Node,
    focusFirst?: string | Node,
    clearOnOpen: boolean = false
  ): this {
    console.debug(`[new-post] add document`);

    // Can't open without a file
    if (!this.file) {
      return this;
    }

    return super.open(source, focusFirst, clearOnOpen);
  }

  /**
   * When closing this dialog, we want to go back to the original create post
   * dialog, instead of closing out everything.
   */
  public close(
    event?: Event,
    replaced: boolean = false,
    destroyed: boolean = false
  ): boolean {
    console.debug('[close] add document dialog', this);

    this.file = undefined;
    this.inputTitle.value = '';
    this.inputDescription.value = '';

    if (super.close(event, replaced, destroyed)) {
      this.parent.open();
      return true;
    }

    return false;
  }

  public cancel(): void {
    const dialog = new ConfirmDialog(
      'discard-document-dialog',
      this.inputDescription
    );

    // When it's confirmed, clear this (removing the dirty flag)
    dialog.onConfirm = (): void => {
      this.parent.clearDocument();

      this.clear();
      this.close(undefined, false);
    };

    dialog.open(this.inputDescription);
  }

  public clear(): void {
    this.targetFilename.textContent = '';
    this.targetFilesize.textContent = '';

    this.targetAttachmentMessage.textContent = '';
    this.targetDescriptionCount.textContent = '0';
    this.targetDescriptionMessage.textContent = '';

    this.actionConfirm.disabled = false;

    super.clear();
  }

  public withFile(file: File): this {
    this.file = file;

    // Set metadata
    this.targetFilename.textContent = file.name;
    this.targetFilesize.textContent = humanFileSize(file.size);

    const parts = file.name.split('.').slice(0, -1);
    this.inputTitle.value = titleize(parts.join(' ').replace(/_/g, '-'));

    const tooLarge = file.size > 10 * 1048576;
    this.targetAttachmentMessage.textContent = tooLarge
      ? '⚠ File size too big. Document must be less than 10mb.'
      : '';
    this.actionConfirm.disabled = tooLarge;

    // Set document preview
    // TODO icon?

    return this;
  }

  private onDescriptionInput(): void {
    this.targetDescriptionCount.textContent = String(
      this.inputDescription.value.length
    );
  }

  private onFormSubmit(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }

  private onConfirm(e: Event): void {
    if ((this.inputTitle.value || '').length === 0) {
      this.inputTitle.focus();
      this.inputTitle.reportValidity();
      return;
    }

    this.parent.setDocument(
      this.inputTitle.value || '',
      this.inputDescription.value || ''
    );

    this.close();
  }

  public get id(): string {
    return this.component.id;
  }

  public get isOpen(): boolean {
    return !this.component.hasAttribute('hidden');
  }
}
