import onmount from 'onmount'
import $ from 'jquery'
import '@selectize/selectize/dist/js/selectize.min.js'

onmount('select.js-video-selectize', function() {
  const meta = document.querySelector('meta[name=csrf-token]')
  const csrfToken = meta.getAttribute('content')

  $(this).selectize({
    options: [],
    valueField: 'id',
    labelField: 'title',
    searchField: 'title',
    maxItems: 1,
    create: false,
    render: {
      option: function(item, escape) {
        return `<div class="select-video-item">
        ${

          item.thumbnail_url ? '<div class="video"> <img src="' + escape(item.thumbnail_url) + '" class="img"/></div>' : ''
        }
          <div class="text">
            <div class="title">${escape(item.title)}</div>
            <div class="sub">#${escape(item.id)}</div>
          </div>
        </div>`
      }
    },
    load: function(query, callback) {
      if (!query.length) return callback()

      $.ajax({
        url: '/videos/search',
        type: 'GET',
        data: {
          q: query,
        },
        beforeSend: function(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', csrfToken)
        },
        error: function() {
          callback()
        }, success: function(response) {
          callback(response.videos.slice(0, 20))
        }
      })
    }
  })
})
