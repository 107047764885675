import onmount from 'onmount'
import $ from 'jquery'

onmount('.js-module-delete', function(mountEvent) {
  const button = $(this)

  // Hide the module when delete is clicked.
  button.on('ajax:before', function(ajaxEvent) {
    button.parents('.js-page-module').addClass('hidden')
  })

  // Delete the module if on-server delete is a success.
  button.on('ajax:success', function(ajaxEvent) {
    button.parents('.js-page-module').remove()
  })

  // Unhide the module if the on-server delete fails.
  button.on('ajax:error', function(ajaxEvent) {
    button.parents('.js-page-module').removeClass('hidden')
  })

})
