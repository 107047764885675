import $ from 'jquery'
import onmount from 'onmount'
import Sortable from 'sortablejs'
import '@selectize/selectize/dist/js/selectize.min.js'

///// Init
onmount('.js-speakers-fields', function(mountEvent) {
  ///// Init sortable.js for collected speaker ul
  let sortableList = document.getElementById('js-sortable-speakers-list')
  if (sortableList != null) { Sortable.create(sortableList) }

  ///// Adding speakers to collection after clicking the button
  let speakerSelectize = $(this).find('.js-speakersearch-selectize')
  $('#js-speaker-collect').click(function() {
    $.ajax({
      url: $(this).data('url') + "/?speaker_id=" + speakerSelectize.val(),
      type: 'GET',
      error: function(response) { console.log(response) },
      success: function(response) {
        let found = $('#js-speaker-selection > ul > li > input[value=' + speakerSelectize.val() + ']').length > 0
        if (!found) { $('#js-sortable-speakers-list').append(response.html) }
      }
    });
  })

  ///// SpeakerSelectBox posts the entered search_query and returns data
  speakerSelectize.selectize({
    load: function(query, callback) {
      $.ajax({
        url: speakerSelectize.data('url') + "/?search_query=" + encodeURIComponent(query),
        type: 'GET',
        error: function() { callback() },
        success: function(response) { callback(response.speakers) }
      });
    },
    render: { option: function (item, escape) { return '<div>' + escape(item.name) + '</div>'; }},
    create: false,
    maxItems: 1,
    loadThrottle: 200,
    openOnFocus: true,
    valueField: 'id',
    labelField: 'name',
    searchField: ['name']
  })

  ///// Reactiveness of controls when changing the configuration.mode
  $('#channel_page_module_speakers_module_configuration_mode').change(function() {
    let mode_selected = $(this)[0].value
    switch (mode_selected) {
      case 'event_id':
        $('.js-event-select').removeClass('hidden')
        $('.js-video-select').addClass('hidden')
        $('.js-speakers-select').addClass('hidden')
        $('#js-speaker-collect').addClass('hidden')
        $('#js-speaker-selection').addClass('hidden')
        break
      case 'video_id':
        $('.js-video-select').removeClass('hidden')
        $('.js-event-select').addClass('hidden')
        $('.js-speakers-select').addClass('hidden')
        $('#js-speaker-collect').addClass('hidden')
        $('#js-speaker-selection').addClass('hidden')
        break
      case 'speaker_ids':
        $('.js-speakers-select').removeClass('hidden')
        $('#js-speaker-collect').removeClass('hidden')
        $('#js-speaker-selection').removeClass('hidden')
        $('.js-event-select').addClass('hidden')
        $('.js-video-select').addClass('hidden')
        break
    }
  })
})
