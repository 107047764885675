import { Controller } from '@hotwired/stimulus';
import { emitter } from '../../src/brandless/emitter';
import { toggleHidden } from '../../src/brandless/utils';

export default class extends Controller {
  static targets = ['destroy', 'create', 'counter'];
  initialize() {
    this.onLike = this.onLike.bind(this);
  }

  connect() {
    emitter.on('like:video-comment', this.onLike);
  }

  disconnect() {
    emitter.off('like:video-comment', this.onLike);
  }

  onLike(event) {
    const { id, state } = event;

    if (!event) {
      return;
    }

    if (this.id !== String(id)) {
      return;
    }

    const change = state ? 1 : -1;
    this.setCount(change);
  }

  setCount(state) {
    if (!this.hasCounterTarget) return;

    const countEl = this.counterTarget.querySelector('[data-target="count"]');
    if (!countEl) return;

    const currentCount = Number(countEl.textContent);
    if (typeof currentCount !== 'number') return;

    const newCount = currentCount + state;
    countEl.textContent = newCount;

    toggleHidden(this.counterTarget, newCount === 0);
    toggleHidden(this.destroyTarget, state < 0);
    toggleHidden(this.createTarget, state > 0);
  }

  get id() {
    return this.element.dataset.id;
  }
}
