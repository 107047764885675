import onmount from 'onmount'

onmount<HTMLFormElement>('form.js-image-form', function() {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const form = this
  const input = this.querySelector<HTMLInputElement>('input.js-image-file')
  const image = this.querySelector<HTMLImageElement>('img.img')
  const submitButton = this.querySelector<HTMLInputElement>('input[type="submit"]')

  submitButton.disabled = true

  input.addEventListener('change', function(e) {
    const imageType = /jpg|jpeg|png|bmp|svg/
    const fileName = (e.target as HTMLInputElement).value.toLowerCase().split('.')
    const fileExtension = fileName[fileName.length - 1]

    if (!fileExtension.match(imageType)) {
      submitButton.insertAdjacentHTML('afterend', `<p class='error'><strong>Please upload a valid image file.</strong> <br />Supported file formats: bmp, jpeg, jpg, png, svg.</p>`)
      return
    } else {
      const error = form.querySelector('p.error')
      if (error) {
        error.remove()
      }
    }
    if (input.files && input.files[0]) {
      const reader = new FileReader()

      reader.onload = function(e): void {
        image.src = e.target.result as string
      }

      reader.readAsDataURL(input.files[0])

      submitButton.disabled = false
    }

  })

})