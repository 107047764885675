import onmount from 'onmount'
import $ from 'jquery'
import '@selectize/selectize/dist/js/selectize.min.js'

onmount('.js-email-frequency', function() {
  const checkBox = this.querySelector<HTMLInputElement>('input.js-email-frequency-checkbox')
  const selectFormGroup = this.querySelector('.js-email-frequency-input')
  const select = selectFormGroup.querySelector('.js-email-frequency-selectize')

  const selectize = ($(select) as any).selectize()
  checkBox.addEventListener('change', function() {
    checkBox.checked
      ? selectFormGroup.classList.remove('hidden')
      : selectFormGroup.classList.add('hidden')
  })
})
