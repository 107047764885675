import { Controller } from '@hotwired/stimulus';
import { upgradeAll } from '../../src/brandless/ujs';
import { ContentEditable } from '../../src/brandless/content-editable';
import { emitter } from '../../src/brandless/emitter';
import $ from 'jquery';

export default class extends Controller {
  static targets = [
    'form',
    'textarea',
    'list',
    'heading',
    'chainParams',
    'error',
  ];

  initialize() {
    this.onCommentCreated = this.onCommentCreated.bind(this);
    this.onCommentDeleted = this.onCommentDeleted.bind(this);
  }

  connect() {
    this.isLoggedIn =
      !this.formTarget.dataset.url.includes('/users/auth/auth0');

    if (this.isLoggedIn) {
      const placeholder = this.formTarget.querySelector(
        '[data-target="placeholder"]'
      );
      const input = this.formTarget.querySelector(
        '[data-target="comment-input"]'
      );
      console.log(placeholder, this.textareaTarget, input);
      this.interactiveInput = new ContentEditable(
        this.textareaTarget,
        input,
        placeholder
      );
      this.interactiveInput.tributable = true;
    }

    emitter.on('video_comment_create', this.onCommentCreated);
    emitter.on('video_comment_delete', this.onCommentDeleted);
  }

  disconnect() {
    emitter.off('video_comment_create', this.onCommentCreated);
    emitter.off('video_comment_delete', this.onCommentDeleted);
    if (this.interactiveInput) this.interactiveInput.destroy();
  }

  onCommentCreated(data) {
    this.listTarget.insertAdjacentHTML('afterbegin', data.html);
  }

  onCommentDeleted(data) {
    const result = this.listTarget.querySelector(
      `[data-comment="${data.comment_id}"]`
    );
    if (result) result.remove();
  }

  submitForm(e) {
    if (this.isLoggedIn) {
      e.preventDefault();
      this.postComment();
    } else {
      this.authAndPost();
    }
  }

  postComment() {
    const self = this;
    const meta = document.querySelector('meta[name=csrf-token]');
    const csrfToken = meta.getAttribute('content');

    $.ajax({
      url: this.formTarget.dataset.url,
      type: 'post',
      data: {
        video_id: this.formTarget.dataset.videoId,
        video_comment: { text: this.textareaTarget.value },
      },
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', csrfToken);
      },
      success: () => {
        self.errorTarget.innerHTML = '';
        self.interactiveInput.clear();
      },
      error: (xhr, _status, _error) => {
        self.errorTarget.innerHTML = xhr.responseText;
      },
    });
  }

  authAndPost() {
    const chainParams = {
      prompt: 'login',
      chain_params: {
        chain_origin: this.chainParamsTarget.dataset.chainOrigin,
        chain_action: this.chainParamsTarget.dataset.chainAction,
        chain_action_params: JSON.stringify({
          video_id: this.chainParamsTarget.dataset.videoId,
          video_comment_body: this.textareaTarget.value,
        }),
      },
    };

    const queryString = $.param(chainParams);
    const form = this.chainParamsTarget.parentElement;
    form.action = `${form.action}?${queryString}`;
  }

  onKeyDown(event) {
    if (this.isLoggedIn) {
      this.errorTarget.innerHTML = '';
    }

    if (event.keyCode == 13 && !event.shiftKey && window.innerWidth >= 992) {
      event.preventDefault();

      if (!this.textareaTarget.value.trim()) {
        return;
      }

      this.submitForm();
    }
  }

  setTextareaHeight(event) {
    const maxHeight = 84;

    const { scrollHeight } = event.target || event;

    if (maxHeight < scrollHeight) {
      this.textareaTarget.parentElement.style.minHeight = `${maxHeight}px`; // Fixes safari height bug
      this.textareaTarget.style.height = `${maxHeight}px`;
    } else {
      this.textareaTarget.parentElement.style.minHeight = `${scrollHeight}px`; // Fixes safari height bug
      this.textareaTarget.style.height = `${scrollHeight}px`;
    }
  }
}
