import onmount from 'onmount'

onmount('.js-anchor-scroll', function() {
  const element = document.querySelector(this.getAttribute('href'))

  this.addEventListener('click', function(e) {
    e.preventDefault()
    const block = this.dataset.block || 'center'

    element.scrollIntoView({
      behavior: 'smooth',
      block: block,
    })
  })
})
