import onmount from 'onmount'
import moment from 'moment'

onmount('.js-countdown-panel', function () {
  let date = this.getAttribute('data-datetime')

  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') > -1) {
    // Chrome
    } else {
      date = date.slice(0, -4);
    }
  }
  let eventDate: any = ''

  if (date.endsWith('UTC')) {
    const trim = date.substr(0, date.length - 4)
    eventDate = moment.utc(trim)
  } else {
    eventDate = moment.utc(date)
  }

  const momentUTC: any = moment.utc()
  const diffTime = eventDate - momentUTC

  const days = this.querySelector('[data-type="days"]')
  const hours = this.querySelector('[data-type="hours"]')
  const mins = this.querySelector('[data-type="mins"]')
  const secs = this.querySelector('[data-type="secs"]')

  let duration = moment.duration(diffTime, 'milliseconds')

  if (duration.asMilliseconds() <= 0) {
      days.innerHTML = '00'
      hours.innerHTML = '00'
      mins.innerHTML = '00'
      secs.innerHTML = '00'

  } else {

    if (duration.asMilliseconds() <= 0) {
      window.location.reload()
    }

    setInterval(function() {
      duration = moment.duration(duration.asMilliseconds() - 1000, 'milliseconds')
      days.innerHTML = String(Math.floor(moment.duration(duration).asDays()))
      hours.innerHTML = String(moment.duration(duration).hours())
      mins.innerHTML = String(moment.duration(duration).minutes())
      secs.innerHTML = String(moment.duration(duration).seconds())
      if (days.innerHTML.length == 1)
        days.innerHTML = ("00" + days.innerHTML).slice(-2)
      hours.innerHTML = ("00" + hours.innerHTML).slice(-2)
      mins.innerHTML = ("00" + mins.innerHTML).slice(-2)
      secs.innerHTML = ("00" + secs.innerHTML).slice(-2)
    }, 1000)

  }
})
