import onmount from 'onmount'

onmount('.gated-session-element', function(mountEvent) {
  const wrapper       = $(this);
  const videoId       = wrapper.attr('data-video-id');

  const apiEndpoint   = '/validate_unique_video_session';
  const errorEndpoint = '/unique_video_session_error';
  const redirectPath  = window.location.protocol +'//' + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
  const videoPath     = window.location.pathname;

  let wasValid        = false;

  function swapInErrorMessage() {
    const videoDiv = $('#' + videoId);
    const endPoint = errorEndpoint + "?" + $.param({ video_id: videoId });
    $.get(endPoint, function(data) {
      videoDiv.replaceWith(data);
    });
  }

  function sendHeartbeat() {
    console.log('Sending gated element heartbeat...');
    $.post(apiEndpoint, { video_id: videoId })
    .done(function(data) {
      if(data.is_valid_session) {
        if(window.location.pathname === videoPath) {
          console.log('Sending heartbeat...');
          setTimeout(sendHeartbeat, data.heartbeat_interval);
          wasValid = true;
        }
      } else if(!data.is_valid_session && wasValid) {
        // A forced session update was requested from another application :
        window.location.replace(redirectPath);
      } else if(!data.is_valid_session) {
        swapInErrorMessage();
        wasValid = false;
      }
    })
    .fail(function() {
      // Fails quietly. The user will be able to continue viewing the video.
      console.log('Unable to contact the heartbeat API.');
    })
  }

  sendHeartbeat();
})
