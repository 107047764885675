import { Controller } from '@hotwired/stimulus';
import consumer from '../../src/channels/consumer';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    const controller = this;
    this.channel = this.application.consumer.subscriptions.create(
      'UploadProcessingChannel',
      {
        connected() {
          // console.log('Connected to the UploadProcessingChannel')
        },
        received(data) {
          controller.publishProcessingPost(data);
        },
      }
    );
  }

  disconnect() {
    this.channel.unsubscribe();
  }

  publishProcessingPost(data) {
    const postId = data['post_id'];
    const postArticle = document.querySelector(`#post-${postId}`);

    if (!postArticle) return;

    $.ajax({
      url: `posts/${postId}`,
      data: { append_to_feed: true },
      success: function (response) {
        postArticle.outerHTML = response;
      },
      error: function (error) {
        console.warn(error);
      },
    });
  }
}
