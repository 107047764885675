import { Controller, Context } from '@hotwired/stimulus';

export default class Link extends Controller {
  private declare newPath: string;

  constructor(context: Context) {
    super(context);

    this.onClick = this.onClick.bind(this);

    this.setParams();
  }

  private setParams(): void {
    const href = this.element.getAttribute('href');

    const [base, stringParams] = href.split('?');
    const params = new URLSearchParams(stringParams);

    if (this.element.hasAttribute('data-exit-on-submit')) {
      params.set('exit_on_submit', 'true');
    }

    this.newPath = `${base}?${params.toString()}`;
  }

  public connect(): void {
    this.element.addEventListener('click', this.onClick);
  }

  public disconnect(): void {
    this.element.removeEventListener('click', this.onClick);
  }

  private onClick(e: MouseEvent): void {
    e.preventDefault();

    window.open(this.newPath, '_blank');
  }
}
