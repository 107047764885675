import { Controller } from '@hotwired/stimulus';

export default class NavbarController extends Controller {
  public static targets = ['iconOpen', 'iconClosed', 'menu', 'toggle'];

  private declare iconOpenTarget: HTMLElement;
  private declare iconClosedTarget: HTMLElement;
  private declare menuTarget: HTMLElement;
  private declare toggleTarget: HTMLElement;

  public get opened(): boolean {
    return this.toggleTarget.getAttribute('aria-expanded') === 'true';
  }

  public set opened(next: boolean) {
    this.toggleTarget.setAttribute('aria-expanded', String(next));

    this.iconOpenTarget.classList.toggle('hidden', !next);
    this.iconClosedTarget.classList.toggle('hidden', next);
    this.iconOpenTarget.classList.toggle('block', next);
    this.iconClosedTarget.classList.toggle('block', !next);

    this.menuTarget.classList.toggle('hidden', !next);
  }

  public onToggle(e: MouseEvent): void {
    if (e.currentTarget !== this.toggleTarget) {
      return;
    }

    this.opened = !this.opened;
  }
}
