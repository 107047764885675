import $ from 'jquery';

$(window).scroll(function () {
  const environment = $('.environment');
  environment.show();
  environment.css('opacity', 1);

  clearTimeout($.data(this, 'scrollTimer'));

  $.data(
    this,
    'scrollTimer',
    setTimeout(function () {
      environment.animate({ opacity: 0 }, 300, function () {
        environment.hide();
      });
    }, 2000)
  );
});
