import onmount from 'onmount'
import $ from 'jquery'
import '@selectize/selectize/dist/js/selectize.min.js'

onmount('.js-new-module-button', function(mountEvent) {
  const button = this

  $(button).on('click', function(clickEvent) {
    clickEvent.preventDefault()

    let newModuleForm = $('.js-new-module-form-wrapper').clone()
    newModuleForm = newModuleForm[0]

    // Set the ID of the module to precede.
    const precedeModuleID = $(button).data('precede-module-id')
    $(newModuleForm).find('.js-precede-module-id').val(precedeModuleID)

    // Place after all other modules if request made from global Add-button.
    if (precedeModuleID == '-1') {
      $('.page-modules').append(newModuleForm)
    }
    // Place before existing module if request made from module Insert-button.
    else {
      $('#page-module-' + precedeModuleID).before(newModuleForm)
    }

    onmount()

    $(newModuleForm).removeClass('hidden')
    $(newModuleForm).find('.js-plain-selectize').selectize()
  })

})
