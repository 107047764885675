import onmount from 'onmount'

onmount('.js-slider-grid-section', function() {
  const container = this.querySelector<HTMLElement>('.container')
  const children = container.querySelectorAll('b-cell')

  const resize = () => {
    const width = window.innerWidth

    if (!children.length) {
      return
    }

    if (width < 992) {
      const totalWidth = ((width * 0.58) * children.length) + (16 * (children.length + 1))
      container.style.width = totalWidth + 'px'
    } else {
      container.removeAttribute('style')
    }
  }

  resize()

  window.addEventListener('resize', () => resize())

})