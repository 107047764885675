import onmount from 'onmount'

onmount('.js-notice', function() {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const notice = this;
  const flashDuration = Number(this.getAttribute("data-flash-duration")) || 3000;

  setTimeout(() => {
    notice.classList.remove('-reveal');
  }, flashDuration);
})
