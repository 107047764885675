import { Controller } from '@hotwired/stimulus';
import { emitter } from '../../src/brandless/emitter';
import { toggleHidden } from '../../src/brandless/utils';

export default class extends Controller {
  static targets = ['register', 'cancel'];

  initialize() {
    this.onRegister = this.onRegister.bind(this);
  }

  connect() {
    emitter.on('register:event-series', this.onRegister);
  }

  disconnect() {
    emitter.off('register:event-series', this.onRegister);
  }

  onRegister(event) {
    console.log('Here is the event');

    const { state } = event;

    toggleHidden(this.registerTarget, state);
    toggleHidden(this.cancelTarget, !state);
  }
}
