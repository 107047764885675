import { Controller } from '@hotwired/stimulus';
import { toggleHidden } from '../../src/brandless/utils';
import $ from 'jquery';
export default class extends Controller {
  static targets = ['video'];

  connect() {
    this.renderPlayer();
  }

  renderPlayer() {
    $.ajax({
      url: this.element.dataset.playerVideoPath,
      beforeSend: () => {
        console.log('Fetching video data...');
      },
      success: (res) => {
        const player = new Player(this.element);
        const placeholder = this.element.querySelector('[data-placeholder]');

        const occurrence = new Occurrence(
          res.occurrence_id,
          this.element.dataset.slug
        );
        occurrence.fetchCollectorData();

        this.element.insertAdjacentHTML('beforeend', res.playerHtml);
        placeholder?.remove();

        if (this.hasVideoTarget) {
          player.initialize(res, this.videoTarget);

          window.parent.postMessage({
            source: 'video-player',
            slug: this.element.dataset.slug,
          });
        }
      },
      error: (err) => {
        const res = err.responseJSON;

        this.element.innerHTML = res.errorHtml;

        this.displayErrorMessage(err);
      },
    });
  }

  disableRightClick(e) {
    e.preventDefault();
    console.debug('Right click prevented');
  }

  displayErrorMessage(response) {
    const message = this.element.querySelector('.message');
    console.debug(message);

    if (response.status === 404) {
      message.innerHTML = `<h2>Video Not Found</h2>`;
    } else {
      message.innerHTML = `
        <p>${response.statusText}</p>
        <h4>It seems we're having some issues right now.</h4>
      `;
    }
  }
}

class Occurrence {
  constructor(occurrenceId, videoSlug) {
    this.occurrenceId = occurrenceId;
    this.videoSlug = videoSlug;
  }

  fetchCollectorData() {
    const interval = setInterval(() => {
      const script = document.querySelector('script[id="reachora-video"]');

      if (!script) {
        return;
      }

      const params = {
        rcid: script.dataset.rcid,
        tenant: script.dataset.reachoraTenant,
        sid: script.dataset.reachoraSid,
        tid: script.dataset.reachoraTid,
      };

      clearInterval(interval);
      this.postOccurrence(params, this.occurrenceId, this.videoSlug);
    }, 200);
  }

  postOccurrence(extendedParams, occurrenceId, videoSlug) {
    $.ajax({
      url: '/player/occurrences',
      type: 'POST',
      data: {
        occurrence_id: occurrenceId,
        video_slug: videoSlug,
        reachora_params: extendedParams,
      },
      success: (res) => {
        console.debug('Created occurrence');
        console.debug(res);
      },
      error: (res) => {
        console.debug('Error creating occurrence');
      },
    });
  }
}

const propagatedProgressInterval = 1;
const progressInterval = 5;

class Player {
  constructor(element) {
    this.element = element;
  }

  initialize(response, element) {
    import(/* webpackChunkName: "videojs" */ '../../ext/all-videojs').then(
      ({ videojs, videojsContribAds, videojsIma }) => {
        const { video, occurrence_id } = response;

        this.occurrenceId = occurrence_id;

        let sources = [
          {
            type: 'application/mp4',
            src: video.public_mp4_url,
          },
          {
            type: 'application/x-mpegurl',
            src: video.public_hls_url,
          },
        ].filter(({ src }) => src);

        this.isLive = JSON.parse(this.element.dataset.live.toLowerCase());

        const options = {
          controls: true,
          preload: 'auto',
          fluid: true,
          aspectRatio: this.element.dataset.aspectRatio || '16:9',
          liveui: this.element.hasAttribute('data-live'),
          sources: sources,
          poster: video.thumbnail_url,
          html5: {
            vhs: {
              experimentalLLHLS: true,
            },
          },
        };

        // Validates if the playback has reached a certain percentage
        this.progress25 = false;
        this.progress50 = false;
        this.progress75 = false;
        this.progress97 = false;

        this.blockbusterId = this.element.dataset.blockbusterId;
        this.videoCode = this.element.dataset.videoCode;
        this.durationSeconds = Number(this.element.dataset.videoDuration);
        this.player = videojs(element, options);
        this.handleEvents();
      }
    );
  }

  handleEvents() {
    this.lastPropagatedTime = Number(new Date());
    this.setStartPosition();
    this.nextProgressEventTime = progressInterval;
    this.extraData = '';
    this.video = this.element.querySelector('video');

    // this.player.on('ready', (e) => this.handleAutoplay(e))
    this.player.on('error', (e) => this.handleError(e));
    this.player.on('timeupdate', (e) => this.handleTimeUpdate(e));
    this.player.on('volumechange', (e) => this.addContentEvent(e));
    this.player.on('pause', (e) => this.addContentEvent(e));
    this.player.on('play', (e) => this.handlePlay(e));
    this.player.on('ended', (e) => this.handleEnd(e));
  }

  setStartPosition() {
    const startPosition = parseInt(this.element.dataset.startPosition) || 0;
    if (startPosition > this.player.currentTime()) {
      this.player.currentTime(startPosition);
      this.element.dataset.startPosition = 0;
    }
  }

  handleAutoplay() {
    import(/* webpackChunkName: "canAutoplay" */ '../../ext/all-videojs').then(
      ({ canAutoplay }) => {
        canAutoplay.video().then((obj) => {
          if (obj.result === true) {
            this.player.play();
          } else {
            console.log('Wondr video autoplay prevented');
          }
        });
      }
    );
  }

  addContentEvent(event, opts) {
    if (!this.occurrenceId) return;

    let eventType = event.type;
    let position = opts?.position || Number(this.player.currentTime());
    let seconds = Math.floor(position);

    if (eventType === 'timeupdate') {
      eventType === 'progress';
    }

    const img = new Image();
    const div = document.querySelector(
      `div[id="event_images"][data-blockbuster-id="${this.blockbusterId}"]`
    );

    if (!div) return;

    img.onload = function () {
      div.appendChild(img);
    };
    let src = `https://in.reachora.io/player/content_event/${this.occurrenceId}.png?`;
    src +=
      '&event_type=' +
      eventType +
      '&local_ts=' +
      new Date().getTime() +
      '&seconds=' +
      seconds +
      '&position=' +
      position;
    src += this.extraData;
    img.src = src;
  }

  handleError(event) {
    if (!this.occurrenceId) return;

    event.stopImmediatePropagation();
    const error = this.player.error();

    this.extraData += '&error_code=' + encodeURIComponent(error.code);
    this.extraData += '&error_message=' + encodeURIComponent(error.message);

    this.addContentEvent(event);
    console.error('Error', error, this.extraData);
  }

  handlePlay(event) {
    if (!this.play) {
      this.play = true;
    }

    this.addContentEvent(event);

    // LinkedIn
    window.lintrk('track', { conversion_id: 10318074 });

    this.dispatchPlayerEvent('start');
  }

  handleTimeUpdate(event) {
    const position = Number(this.player.currentTime());
    const seconds = Math.floor(position);
    const now = Number(new Date());

    if (this.recommendations) {
      toggleHidden(this.recommendations, true);
    }

    this.handleLinkedInTriggers(position);

    if (position < this.nextProgressEventTime) {
      return;
    } else {
      this.nextProgressEventTime = position + progressInterval;
    }

    const updateThreshold =
      this.lastPropagatedTime + propagatedProgressInterval * 1000;

    if (!this.play) return;
    if (now < updateThreshold) return;

    this.addContentEvent(event, { position });
    this.dispatchPlayerEvent('progress', { progress: seconds });
  }

  handleEnd(event) {
    this.recommendations = this.element.querySelector('[data-recommendations]');

    if (document.fullscreenElement) {
      this.player.exitFullscreen();
    }

    if (this.recommendations) {
      toggleHidden(this.recommendations, false);
    }

    this.addContentEvent(event);
    this.dispatchPlayerEvent('end');
  }

  fetchUtmParameters() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const utmParams = {
      utm_campaign: urlParams.get('utm_campaign'),
      utm_content: urlParams.get('utm_content'),
      utm_medium: urlParams.get('utm_medium'),
      utm_source: urlParams.get('utm_source'),
      utm_term: urlParams.get('utm_term'),
    };

    return utmParams;
  }

  handleLinkedInTriggers(position) {
    if (this.isLive || !this.durationSeconds) {
      return;
    }

    const percentage = (position / this.durationSeconds) * 100;

    if (percentage >= 25 && !this.progress25) {
      this.progress25 = true;
      window.lintrk('track', { conversion_id: 17443418 });
    }

    if (percentage >= 50 && !this.progress50) {
      this.progress50 = true;
      window.lintrk('track', { conversion_id: 17443426 });
    }

    if (percentage >= 75 && !this.progress75) {
      this.progress75 = true;
      window.lintrk('track', { conversion_id: 17443434 });
    }

    if (percentage >= 97 && !this.progress97) {
      this.progress97 = true;
      window.lintrk('track', { conversion_id: 17443442 });
    }
  }

  dispatchPlayerEvent(name, object = null) {
    if (!this.videoCode || !name) return;

    let detail = { video_code: this.videoCode };
    if (object) detail = { ...detail, ...object };

    const utmParams = this.fetchUtmParameters();
    const eventInit = { detail: { ...detail, ...utmParams } };

    const outboundEvent = new CustomEvent(`reachora:player:${name}`, eventInit);

    window.dispatchEvent(outboundEvent);
  }
}
