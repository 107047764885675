import { register, upgradeAll } from '../brandless/ujs';
import { fetch } from '../brandless/fetch';
import Sortable from 'sortablejs';

register('[data-sortable-card]', ({ element }) => {
  const removeButton = element.querySelector('[data-action="remove-item"]');

  removeButton.addEventListener('click', () => element.remove());
});

register('[data-add-and-sort]', ({ element }) => {
  const select = element.querySelector('[data-custom-select]');
  const selectValue = select.querySelector(
    'input[type="text"]'
  ) as HTMLInputElement;
  const selectValueId = select.querySelector(
    'input[type="hidden"]'
  ) as HTMLInputElement;
  const template = element.querySelector(
    'template#list-item'
  ) as HTMLTemplateElement;

  const sortableList = element.querySelector('[data-sortable-list]');
  const fetchUrl = element.getAttribute('data-fetch-url');
  const inputName = element.getAttribute('data-input-name');

  Sortable.create(sortableList);

  const addButton = element.querySelector('[data-button-add]');

  // Get value from select
  addButton.addEventListener('click', (event) => {
    event.preventDefault();

    const isDuplicate = sortableList.querySelector(
      `input[value="${selectValueId.value}"]`
    );

    if (!selectValueId.value) {
      return;
    }

    if (isDuplicate) {
      return;
    }

    fetch(
      `${fetchUrl}${selectValueId.value}?input_name=${encodeURIComponent(
        inputName
      )}`
    )
      .then((result) => result.text())
      .then((html) => {
        sortableList.insertAdjacentHTML('afterbegin', html);
        upgradeAll();
        selectValue.value = '';
        selectValueId.value = '';
      })
      .catch((error) => console.log('Ufg'));
  });
});
