import onmount from 'onmount'

onmount('.js-read-more', function() {
  const button = this.querySelector('.js-more-link')
  const showLessButton = this.querySelector('.js-less-link')
  const shortText = this.querySelector('.js-short')
  const longText = this.querySelector('.js-long')

  if (!button) {
    return
  }

  button.addEventListener('click', (e) => {
    e.preventDefault()

    shortText.classList.add('hidden')
    longText.classList.remove('hidden')
  })

  if (showLessButton) {
    showLessButton.addEventListener('click', (e) => {
      e.preventDefault()

      longText.classList.add('hidden')
      shortText.classList.remove('hidden')
      this.scrollIntoView({behavior: 'smooth', block: 'center'})
    })
  }
})