import onmount from 'onmount'
import $ from 'jquery'

onmount('.js-module-edit', function(mountEvent) {
  const button = $(this)

  button.on('ajax:success', function(response) {
    const replaceDiv = button.parents('.js-page-module')
    const editForm   = response.detail[0].html

    replaceDiv.replaceWith(editForm)

    // Run onmount again to pick up new module added to the page.
    onmount()
  })

})
