import { Controller } from '@hotwired/stimulus';
import { AutoRemoveDialog } from '../src/brandless/components/AutoRemoveDialog';
import { upgradeAll } from '../src/brandless/ujs';

export default class extends Controller {
  initialize() {
    this.onSuccess = this.onSuccess.bind(this);
  }
  connect() {
    this.element.addEventListener('ajax:success', this.onSuccess);
  }

  disconnect() {
    this.element.removeEventListener('ajax:success', this.onSuccess);
  }

  onSuccess(event) {
    const [data, status, xhr] = event.detail;
    const dialogElement = data.body.querySelector('[role="dialog"]');

    document.body.appendChild(dialogElement);
    const dialog = new AutoRemoveDialog(dialogElement.id);
    dialog.open(this.element);
    upgradeAll();
  }
}
