import onmount from 'onmount'
import $ from 'jquery'

onmount('.js-interest-tabs', function() {
  const $tabs = $(this).find('.js-interest-tab')

  $tabs.on('click', (e) => {
    e.preventDefault()

    const $tab = $(e.currentTarget)
    const target = $tab.data('target')
    const $content = $(`.js-interest-tab-content[data-tab-id=${target}]`)

    $('.js-interest-tab.-active').removeClass('-active')
    $('.js-interest-tab-content:not(.hidden)').addClass('hidden')

    $tab.addClass('-active')
    $content.removeClass('hidden')

    const $videosWrapper = $content.find('.video-grid')

    if ($videosWrapper.children().length == 0 && target != 'default') {
      $.ajax({
        type: 'GET',
        url: '/load_interest_videos',
        data: { id: target }
      })
    }
  })
})
