import { SingleActionDialog } from './components/SingleActionDialog';
import { closeCurrentDialog, Dialog } from './dialog';
import { onPageLoad } from './spa';

/**
 * Initializes the rebranding dialog, annotated as #rebrand-dialog.
 *
 * The dialog should be a form that is submittable, in order to make it work
 * without JavaScript. Under the hood, it will be upgraded using a
 * {SingleActionDialog}, which triggers the submit button on "close".
 */
function initialize(): void | (() => void) {
  const element = document.getElementById('rebrand-dialog');

  if (!element || element.hidden) {
    return;
  }

  const form = element.querySelector('form')!;
  const submit = form.submit.bind(form);
  const dialog = new SingleActionDialog('rebrand-dialog', submit);

  // On submission of the form, don't wait for an answer, but just close this.
  form.addEventListener('submit', closeCurrentDialog);

  dialog.open(
    // Replace with function to find first focusable element, or for example the
    // sidebar.
    document.activeElement ||
      document.querySelector(
        'button:not([hidden]), a:not([hidden]), [tabindex="0"]:not([hidden])'
      )!
  );

  return function cleanup(): void {
    dialog.dialogNode
      .querySelector('form')!
      .removeEventListener('submit', closeCurrentDialog);

    dialog.close();
  };
}

onPageLoad(initialize);
