import { Eventable } from './eventable';
import { register, upgrade } from './ujs';

export type FollowEvent = {
  id: string;
  state: boolean;
};

/**
 * Makes a component marked with [data-followable] respond to follow events.
 *
 * Items that can be followed, such as users, can have one or more actions to
 * follow or unfollow. These actions can be ujs-enabled forms, meaning they will
 * submit using XHR, and not refresh the page. These responses emit follow
 * events that can be captured.
 *
 * In order to make an element change its visibility, add the following data
 * attributes:
 *
 * - data-followable: 'true' | 'false'
 * - data-type: 'user' | 'publisher' | 'topic'
 * - data-id: 'id'
 *
 * The component marked with [data-followable] will hide itself when the follow
 * state matches its value ('true' or 'false').
 *
 * @example
 *
 * <form action="/users/1/follows"
 *       method="post"
 *       data-remote
 *       data-followable="true"
 *       data-followable-type="user"
 *       data-id="1">
 *   <button type="submit">Follow</button>
 * </form>
 */
export class Followable extends Eventable<FollowEvent> {
  constructor(component: HTMLElement) {
    super(component, 'followable', 'subscribe');
  }

  protected get targetType(): string {
    return this.component.getAttribute('data-followable-type')!;
  }

  protected get pairedElement(): HTMLElement | null {
    const close = super.pairedElement;
    if (close) {
      return close;
    }

    // Follow buttons are sometimes part of recommendations, where the toggle
    // pair is nested in a different parent than the current parent. At the
    // moment, the boundary is always a [data-replace-on][data-replace-id]
    // element, but when more types/structures are added, replace this with a
    // dedicated followable boundary attribute, like [data-followable-boundary].
    //
    // After it finds the boundary, it searches for the reverse/paired action.

    const boundary = this.component.closest(
      `[data-replace-on="${this.eventType}:${this.targetType}"][data-replace-id="${this.targetId}"]`
    );
    if (!boundary) {
      return null;
    }

    const fields = [
      `[data-${this.dataType}]:not([data-${this.dataType}="${this.activeState}"])`,
      `[data-followable-type="${this.targetType}"]`,
      `[data-id="${this.targetId}"]`,
    ].join('');

    return boundary.querySelector<HTMLAnchorElement | HTMLButtonElement>(
      [
        `a${fields}`,
        `button${fields}`,
        `input[type="submit"]${fields}`,
        `form${fields} > button`,
        `form${fields} > a`,
        `form${fields} > input[type="submit"]`,
      ].join(', ')
    );
  }
}

export function followables(): void {
  upgrade('[data-followable]');
}

export function followable(component: HTMLElement): Followable {
  return new Followable(component);
}

register('[data-followable]', ({ element }) => followable(element).destroy);
