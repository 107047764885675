import { KEY_NAMES, KEY_NAMES_LEGACY } from '../aria';
import { Dialog } from '../dialog';
import { fetchResource } from '../fetch';
import { isPrintableCharacter } from '../utils';

const TEMPLATE_TOPIC_PILL = 'template-add-post-topics-selected-pill';

function createTopicPill(label: string, value: string): Element {
  const template = document.getElementById(
    TEMPLATE_TOPIC_PILL
  ) as HTMLTemplateElement;

  const pill = document.importNode(template.content.firstElementChild!, true);
  const pillLabel = pill.querySelector('[data-target="label"]')!;
  const pillAction = pill.querySelector('[data-action="remove-topic"]')!;

  pillLabel.textContent = label;
  pillAction.setAttribute('data-value', value);
  return pill;
}

/**
 * Sub-dialog that allows adding topics to a post
 *
 * In order to keep everything in sync, only use the open and close methods on
 * this element and not the global dialog helpers, for now.
 */
export class AddPostTopicsDialog extends Dialog {
  private readonly searchInput: HTMLInputElement;
  private readonly searchOutput: HTMLElement;
  private readonly actionBack: Element;
  private readonly actionConfirm: Element;
  private readonly topicPills: HTMLElement;

  constructor(
    private readonly component: HTMLElement,
    private readonly parent: Dialog & {
      setTopics(topics: Record<string, string>): void;
    }
  ) {
    super(component.id);

    this.searchInput = component.querySelector<HTMLInputElement>(
      'input[type="search"], input[type="text"]'
    )!;
    this.searchOutput = component.querySelector<HTMLElement>(
      '[data-target="search-results"]'
    )!;
    this.topicPills = component.querySelector<HTMLElement>(
      '[data-target="active-topics"]'
    )!;

    this.actionBack = this.component.querySelector('[data-action="back"]')!;
    this.actionConfirm = this.component.querySelector(
      '[data-action="confirm"]'
    )!;

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.destroy = this.destroy.bind(this);
    this.removeTopic = this.removeTopic.bind(this);

    this.onComponentClick = this.onComponentClick.bind(this);
    this.onSearchFocus = this.onSearchFocus.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onSearchKey = this.onSearchKey.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onKey = this.onKey.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onOptionToggled = this.onOptionToggled.bind(this);
    this.onOptionRemoved = this.onOptionRemoved.bind(this);
    this.onOptionClick = this.onOptionClick.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onOptionsFetched = this.onOptionsFetched.bind(this);

    this.component.addEventListener('click', this.onComponentClick);
    this.actionBack.addEventListener('click', this.close);
    this.actionConfirm.addEventListener('click', this.onConfirm);

    const form = this.component.closest<HTMLFormElement>('form')!;
    form.addEventListener('submit', this.onFormSubmit);

    this.fillOptions();
  }

  public get autofocus(): HTMLElement {
    return this.component.querySelector('input')!;
  }

  public destroy(): void {
    this.topicPills.querySelectorAll('[data-value]').forEach((pill) => {
      this.removeTopic(pill.getAttribute('data-value')!);
    });

    this.component.removeEventListener('click', this.onComponentClick);
    this.actionBack.removeEventListener('click', this.close);
    this.actionConfirm.removeEventListener('click', this.onConfirm);

    const form = this.component.closest<HTMLFormElement>('form')!;
    form.removeEventListener('submit', this.onFormSubmit);
  }

  /**
   * Opens itself, attempting to replace dialogs that are already out there,
   * namely the "base" create post dialog.
   *
   * @param source the source of opening the dialog, usually a button.
   */
  public open(
    source?: string | Node,
    focusFirst?: string | Node,
    clearOnOpen: boolean = false
  ): this {
    console.debug(`[new-post] add topics`);

    this.searchInput.addEventListener('focus', this.onSearchFocus);
    this.searchInput.addEventListener('keydown', this.onSearchKey);
    this.searchInput.addEventListener('input', this.onSearch);

    this.searchOutput.addEventListener('keydown', this.onKey);
    this.searchOutput.addEventListener('keyup', this.onKeyUp);
    this.searchOutput.addEventListener('change', this.onOptionToggled);
    this.searchOutput.addEventListener('click', this.onOptionClick);

    this.topicPills.addEventListener('click', this.onOptionRemoved);

    return super.open(source, focusFirst, clearOnOpen);
  }

  /**
   * When closing this dialog, we want to go back to the original create post
   * dialog, instead of closing out everything.
   */
  public close(): boolean {
    console.debug('[close] add topics dialog', this);

    this.searchInput.removeEventListener('focus', this.onSearchFocus);
    this.searchInput.removeEventListener('keydown', this.onSearchKey);
    this.searchInput.removeEventListener('input', this.onSearch);

    this.searchOutput.removeEventListener('keydown', this.onKey);
    this.searchOutput.removeEventListener('keyup', this.onKeyUp);
    this.searchOutput.removeEventListener('change', this.onOptionToggled);
    this.searchOutput.removeEventListener('click', this.onOptionClick);

    this.topicPills.removeEventListener('click', this.onOptionRemoved);

    if (super.close()) {
      this.parent.open();
      return true;
    }

    return false;
  }

  public clear(): void {
    this.searchOutput
      .querySelectorAll<HTMLInputElement>('[name="post[topics][]"]')
      .forEach((option) => {
        this.removeTopic(option.value);
      });

    // Don't call super, because that will reset the form. The form in this
    // dialog is readonly data.
  }

  public get id(): string {
    return this.component.id;
  }

  public get isOpen(): boolean {
    return !this.component.hasAttribute('hidden');
  }

  public get isSearching(): boolean {
    return !!this.searchInput.value;
  }

  private onFormSubmit(e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    this.onSearch();
  }

  private onConfirm(e: Event): void {
    const topics: Record<string, string> = {};

    this.searchOutput
      .querySelectorAll<HTMLInputElement>('[name="post[topics][]"]')
      .forEach((option) => {
        if (option.checked) {
          const item = option.closest('.option')!;

          topics[option.value] =
            (item.querySelector('.type--name') || item)?.textContent || '';
        }
      });

    this.parent.setTopics(topics);
    this.close();
  }

  private onSearchKey(e: KeyboardEvent): void {
    switch (e.key) {
      case KEY_NAMES_LEGACY.DOWN:
      case KEY_NAMES.DOWN: {
        this.focusFirstOption();
        e.preventDefault();
        return;
      }

      case KEY_NAMES_LEGACY.UP:
      case KEY_NAMES.UP: {
        this.focusLastOption();
        e.preventDefault();
        return;
      }

      case KEY_NAMES.RETURN: {
        this.focusFirstOption();
        e.preventDefault();
        return;
      }

      case KEY_NAMES.BACKSPACE: {
        if (this.isSearching) {
          return;
        }
        const id = this.topicPills.lastElementChild
          ?.querySelector('[data-action="remove-topic"]')
          ?.getAttribute('data-value');

        if (id) {
          this.removeTopic(id);
          e.preventDefault();
        }
      }
    }
  }

  private focusFirstOption(): void {
    const option = this.searchOutput.querySelector<HTMLLIElement>(
      'li[role="option"]:not([hidden])'
    );

    if (!option) {
      this.focusSearch();
      return;
    }

    option.focus();
  }

  private focusLastOption(): void {
    const options = this.searchOutput.querySelectorAll<HTMLLIElement>(
      'li[role="option"]:not([hidden])'
    );
    if (options.length === 0) {
      this.focusSearch();
      return;
    }

    options.item(options.length - 1).focus();
  }

  private focusSearch(): void {
    this.searchOutput.focus();
  }

  private focusNext(wrap = true, start?: HTMLElement | null): void {
    let option: HTMLElement | undefined;

    if (start === undefined) {
      if (!document.activeElement) {
        return this.focusFirstOption();
      }

      option = document.activeElement.closest('[role="option"]') as
        | HTMLElement
        | undefined;
      start = option?.nextElementSibling as HTMLElement | undefined;
    } else {
      option = start?.closest('[role="option"]') as HTMLElement | undefined;
    }

    let nextVisible = start;

    while (nextVisible && nextVisible.hasAttribute('hidden')) {
      nextVisible = nextVisible.nextElementSibling as HTMLElement | undefined;
    }

    if (nextVisible) {
      nextVisible.focus();
      return;
    }

    // See if there is another list as a sibling of the parent
    if (option?.parentElement && option.parentElement.nextElementSibling) {
      let nextParent = option.parentElement.nextElementSibling as
        | HTMLElement
        | undefined;
      while (nextParent && !nextParent.querySelector('[role="option"]')) {
        nextParent = nextParent.nextElementSibling as HTMLElement | undefined;
      }

      const nextStart =
        nextParent?.querySelector<HTMLElement>('[role="option"]');

      if (nextStart) {
        return this.focusNext(wrap, nextStart);
      }
    }

    if (!wrap) {
      return;
    }

    this.focusFirstOption();
  }

  private focusNextPage(): void {
    for (let i = 0; i < 9; i++) {
      this.focusNext(false);
    }
  }

  private focusPrevious(wrap = true, start?: HTMLElement | null): void {
    let option: HTMLElement | undefined;

    if (start === undefined) {
      if (!document.activeElement) {
        return this.focusLastOption();
      }

      option = document.activeElement.closest('[role="option"]') as
        | HTMLElement
        | undefined;
      start = option?.previousElementSibling as HTMLElement | undefined;
    } else {
      option = start?.closest('[role="option"]') as HTMLElement | undefined;
    }

    let prevVisible = start;

    while (prevVisible && prevVisible.hasAttribute('hidden')) {
      prevVisible = prevVisible.previousElementSibling as
        | HTMLElement
        | undefined;
    }

    if (prevVisible) {
      prevVisible.focus();
      return;
    }

    // See if there is another list as a sibling of the parent
    if (option?.parentElement && option.parentElement.previousElementSibling) {
      let prevParent = option.parentElement.previousElementSibling as
        | HTMLElement
        | undefined;
      while (prevParent && !prevParent.querySelector('[role="option"]')) {
        prevParent = prevParent.previousElementSibling as
          | HTMLElement
          | undefined;
      }

      const prevStart =
        prevParent?.querySelector<HTMLElement>('[role="option"]');

      if (prevStart) {
        return this.focusPrevious(wrap, prevStart);
      }
    }

    if (!wrap) {
      return;
    }

    this.focusLastOption();
  }

  private focusPreviousPage(): void {
    for (let i = 0; i < 9; i++) {
      this.focusPrevious(false);
    }
  }

  private setFocusByFirstCharacter(char: string): void {
    return;
  }

  private toggleOption(): void {
    if (
      !document.activeElement ||
      document.activeElement.getAttribute('role') !== 'option'
    ) {
      return;
    }

    const option = document.activeElement;
    const input = option.querySelector('input')!;

    const next = !input.checked;
    input.checked = next;

    const realOption = input.closest<HTMLElement>('[role="option"]')!;
    realOption.setAttribute('aria-selected', String(next));

    const label = realOption.querySelector('.type--name');
    this.togglePill(next, input.value, (label || realOption).textContent);
  }

  public togglePill(checked: boolean, id: string, label: string | null): void {
    if (checked) {
      const text = label;

      this.topicPills.appendChild(createTopicPill(text || '', id));
      this.searchInput.value = '';
    } else {
      this.topicPills
        .querySelector(`[data-value="${id}"]`)
        ?.closest('.topic')
        ?.remove();
    }
  }

  private onKey(e: KeyboardEvent): void {
    let handled = false;

    switch (e.key) {
      case KEY_NAMES_LEGACY.ESC:
      case KEY_NAMES.ESC: {
        handled = true;
        break;
      }

      case KEY_NAMES_LEGACY.UP:
      case KEY_NAMES.UP: {
        this.focusPrevious();
        handled = true;
        break;
      }

      case KEY_NAMES_LEGACY.DOWN:
      case KEY_NAMES.DOWN: {
        this.focusNext();
        handled = true;
        break;
      }

      case KEY_NAMES.HOME: {
        this.focusFirstOption();
        handled = true;
        break;
      }

      case KEY_NAMES.PAGE_UP: {
        this.focusPreviousPage();
        handled = true;
        break;
      }

      case KEY_NAMES.END: {
        this.focusLastOption();
        handled = true;
        break;
      }

      case KEY_NAMES.PAGE_DOWN: {
        this.focusNextPage();
        handled = true;
        break;
      }

      case KEY_NAMES_LEGACY.SPACE:
      case KEY_NAMES.SPACE:
      case KEY_NAMES.RETURN: {
        this.toggleOption();
        handled = true;
        break;
      }

      case KEY_NAMES.TAB:
        break;

      default:
        if (isPrintableCharacter(e.key)) {
          this.setFocusByFirstCharacter(e.key);
          handled = true;
        }
        break;
    }

    if (handled) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
    }
  }

  private onKeyUp(e: KeyboardEvent): void {
    if (e.key === KEY_NAMES_LEGACY.ESC || e.key === KEY_NAMES.ESC) {
      e.preventDefault();
      e.stopPropagation();
      this.searchInput.focus();
    }
  }

  private onComponentClick(e: MouseEvent): void {
    if (
      e.target === this.searchOutput ||
      e.target === this.searchInput ||
      this.searchOutput.contains(e.target as Node)
    ) {
      return;
    }
  }

  private onOptionClick(e: MouseEvent): void {
    //
  }

  private onOptionRemoved(e: Event): void {
    if (!e.target) {
      return;
    }

    const clicked = e.target as HTMLElement;
    const pill = clicked.closest('.topic');

    if (!pill) {
      return;
    }

    const action = pill.querySelector('[data-action="remove-topic"]');

    if (!action) {
      return;
    }

    const id = action.getAttribute('data-value')!;
    this.removeTopic(id);
  }

  public removeTopic(id: string): void {
    const checkbox = this.searchOutput.querySelector<HTMLInputElement>(
      `input[value="${id}"]`
    )!;

    if (!checkbox) {
      return;
    }

    checkbox.checked = false;
    const option = checkbox.closest<HTMLElement>('[role="option"]')!;
    option.setAttribute('aria-selected', 'false');

    this.topicPills
      .querySelector(`[data-value="${id}"]`)
      ?.closest('.topic')
      ?.remove();
  }

  private onOptionToggled(e: Event): void {
    if (!e.target) {
      return;
    }

    const input = e.target as HTMLInputElement;
    const value = input.checked;

    const option = input.closest<HTMLElement>('[role="option"]')!;
    option.setAttribute('aria-selected', String(value));
    option.focus();

    const label = option.querySelector('.type--name');
    this.togglePill(value, input.value, (label || option).textContent);
  }

  private onSearchFocus(): void {
    if (!this.isOpen) {
      return;
    }
  }

  private onSearch(): void {
    if (!this.isOpen) {
      return;
    }

    const query = this.searchInput.value;
    const tokens = query
      .split(' ')
      .map((token) => token.trim().toLocaleLowerCase());

    this.searchOutput
      .querySelectorAll<HTMLLIElement>('[data-topic-id]')
      .forEach((element) => {
        const target = element.querySelector('[data-query-value]')!;
        const value = target!.getAttribute('data-query-value') || '';
        const matchValue = value.toLocaleLowerCase();
        const matches = tokens.every((token) => matchValue.includes(token));

        if (matches) {
          const highlightedValue = value;

          // TODO highlight matching tokens

          element.removeAttribute('hidden');
          target.innerHTML = highlightedValue;
        } else {
          element.setAttribute('hidden', '');
        }
      });
  }

  private fillOptions(): void {
    const url = this.component.getAttribute('data-topics-url');
    if (!url) {
      console.warn(
        '[topics] dialog expected url to fetch the topics to select'
      );
      return;
    }

    if (this.searchOutput.hasAttribute('data-filled')) {
      // ALready loaded
      return;
    }

    fetchResource('topics', url, {
      accept: 'application/vnd.reachora.topic.v1.collection',
    })
      .then((result) =>
        'content' in result.topics ? result.topics.content : null
      )
      .then(this.onOptionsFetched)
      .catch(console.error);
  }

  private onOptionsFetched(options: string | null): void {
    if (!options) {
      console.warn('[topics] dialog did not receive topics from topics url');

      return;
    }

    const result = document.createElement('div');
    result.innerHTML = options;

    while (result.firstChild) {
      this.searchOutput.append(result.firstChild);
    }

    this.searchOutput.setAttribute('data-filled', '');
  }
}
