import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['panel', 'conversation', 'boundary'];

  startConversation(event) {
    const { userId } = event.detail;
    if (!userId) return;

    Rails.ajax({
      url: this.data.get('new-url'),
      type: 'post',
      data: `conversation[user_id]=${userId}`,
      dataType: 'html',
      success: (html) => {
        this.boundaryTarget.insertAdjacentHTML('afterbegin', html);
        this.organiseTrays();
      },
    });
  }

  toggle() {
    if (window.innerWidth >= 768) {
      this.element.classList.remove('-hide');
      this.panelTarget.classList.remove('minimized');
    } else {
      this.element.classList.toggle('-show');
    }
  }

  hideOverlay() {
    if (window.innerWidth < 768) {
      this.element.classList.remove('-show');
      return;
    }

    this.element.classList.add('-hide');
    this.panelTarget.classList.add('minimized');

    if (this.conversationTargets.length > 0) {
      this.conversationTargets.forEach((conversation) => {
        conversation.remove();
      });
    }
  }

  showOverlay() {
    if (window.innerWidth < 768) {
      this.element.classList.add('-show');
      return;
    }

    this.element.classList.remove('-hide');
  }

  show(event) {
    const id = event.currentTarget.getAttribute('data-conversation-id');
    const url = event.currentTarget.getAttribute('data-src');

    const isOpened = this.openConversationIds.includes(id);

    if (!isOpened) {
      Rails.ajax({
        type: 'get',
        url: url,
        dataType: 'html',
        success: (data, status, xhr) => {
          this.boundaryTarget.insertAdjacentHTML('afterbegin', xhr.response);
          this.organiseTrays();
        },
      });
    } else {
      event.preventDefault();
      const target = this.element.querySelector(
        `[data-conversation-id="${id}"]`
      );
      target.classList.remove('minimized');
    }
  }

  organiseTrays() {
    if (window.innerWidth < 768) return;

    const { wrapper, conversationsWidth, wrapperWidth } = this.dimensions;

    if (conversationsWidth >= wrapperWidth) {
      const targets = wrapper.querySelectorAll('.dm-panel');
      const target = targets[targets.length - 1];
      target.remove();
    }
  }

  checkForDuplicates(event) {
    const { conversationId } = event.detail;
    const ids = this.openConversationIds.filter((id) => id === conversationId);
    if (ids.length <= 1) return;

    const conversations = [
      ...this.element.querySelectorAll('.dm-panel'),
    ].filter((c) => c.dataset.conversationId === conversationId);
    const removable = conversations.slice(1);

    removable.forEach((tray) => tray.remove());
  }

  purgeTrays() {
    if (window.innerWidth < 768) return;

    const { conversationsWidth, wrapperWidth } = this.dimensions;

    if (conversationsWidth > wrapperWidth - 60 && this.hasConversationTarget) {
      const target = this.conversationTargets[0];
      target.remove();
    }
  }

  get dimensions() {
    const wrapper = this.boundaryTarget;
    const wrapperWidth = wrapper.getBoundingClientRect().width;
    const trayWidth = 332; // Fixed
    const traysCount = this.openConversationIds.length;
    const totalGutter = 24 * traysCount;
    const conversationsWidth = traysCount * trayWidth + totalGutter;

    return { wrapper, conversationsWidth, wrapperWidth };
  }

  get openConversationIds() {
    return Array.prototype.map
      .call(
        this.element.querySelectorAll('.dm-panel'),
        (el) => el.dataset.conversationId
      )
      .filter((id) => id);
  }
}
