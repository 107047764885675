import { Controller } from '@hotwired/stimulus';
import { emitter } from '../../src/brandless/emitter';
import { upgradeAll } from '../../src/brandless/ujs';
export default class extends Controller {
  initialize() {
    this.onError = this.onError.bind(this);
    upgradeAll();
  }

  connect() {
    emitter.on('error:onboarding', this.onError);
  }

  disconnect() {
    emitter.off('error:onboarding', this.onError);
  }

  onChange(parent, input) {
    const eventType = input.type === 'text' ? 'keyup' : 'change';

    input.addEventListener(eventType, (event) => {
      if (event.target.value.trim()) {
        const helper = parent.querySelector('.helper');
        parent.classList.remove('-error');
        helper?.remove();
      }
    });
  }

  getUserAttribute(attr) {
    if (attr === 'institution_name') {
      return 'institution_id';
    }

    if (attr.match(/accepted_terms_at|accepted_privacy_policy_at/)) {
      return 'accept_terms_and_privacy';
    }

    return attr;
  }

  onError(event) {
    const { errors } = event;

    document
      .querySelectorAll('[data-js-error]')
      .forEach((previousError) => previousError.remove());

    Object.keys(errors).forEach((attr) => {
      const userAttr = this.getUserAttribute(attr);
      console.debug('error: ', userAttr);
      const label =
        document.querySelector(
          `label[for="user[${userAttr}]"], label[for="user_${userAttr}"]`
        ) || document.querySelector(`[data-for="user[${userAttr}]"]`);

      if (!label) {
        return;
      }

      const parent = label.parentElement;

      const queryAttr =
        userAttr === 'accept_terms_and_privacy' ? userAttr : attr;
      const inputs = parent.querySelectorAll(
        `input[name="user[${queryAttr}]"]`
      );
      inputs.forEach((input) => this.onChange(parent, input));

      const message =
        userAttr === 'accept_terms_and_privacy'
          ? 'Must be accepted'
          : errors[attr][0];
      const messageHtml = `<div data-js-error class='helper mt-2 text-red-400 col-span-2'>${message}</div>`;

      const helper = parent.querySelector('.helper');

      if (helper) {
        return;
      }

      parent.classList.add('-error');
      parent.insertAdjacentHTML('beforeend', messageHtml);
    });
  }
}
