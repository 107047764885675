import { KEY_NAMES, KEY_NAMES_LEGACY } from '../aria';
import { fetch } from '../fetch';
import { isPrintableCharacter } from '../utils';
import { toggleHidden } from '../utils';
import debounce from 'lodash.debounce';
import { upgradeAll } from '../ujs';

export const encodeParams = (
  params: Record<string, string | number | boolean>
): string => {
  return Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    )
    .join('&');
};

interface Options {
  onChange?: (name: string, value: string, input: HTMLInputElement) => void;
  onCreate?: (name: string, value: string) => void;
}

export class CustomSelect {
  private readonly searchInput: HTMLInputElement;
  private readonly searchOutput: HTMLElement;
  private readonly topicPills: HTMLElement;
  private readonly valueInput: HTMLInputElement;
  private readonly labelInput: HTMLInputElement | null;
  private readonly inputCaret: HTMLButtonElement;

  constructor(
    private readonly component: HTMLElement,
    private readonly options?: Options
  ) {
    this.searchInput = component.querySelector<HTMLInputElement>(
      'input[type="search"], input[type="text"]'
    )!;
    this.searchOutput = component.querySelector<HTMLElement>(
      '[data-target="search-results"]'
    )!;
    this.topicPills = component.querySelector<HTMLElement>(
      '[data-target="active-items"]'
    )!;
    this.valueInput = component.querySelector<HTMLInputElement>(
      '[data-primary-name]'
    )!;
    this.labelInput = component.querySelector<HTMLInputElement>(
      '[data-label-input-name]'
    );
    this.inputCaret = component.querySelector<HTMLButtonElement>(
      '[data-action="toggle-dropdown"]'
    )!;

    this.destroy = this.destroy.bind(this);
    this.removeTopic = this.removeTopic.bind(this);

    this.onChange = this.onChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onComponentClick = this.onComponentClick.bind(this);
    this.onInputToggle = this.onInputToggle.bind(this);
    this.onSearchFocus = this.onSearchFocus.bind(this);
    this.onSearchBlur = this.onSearchBlur.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onSearchKey = this.onSearchKey.bind(this);
    this.onKey = this.onKey.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onOptionToggled = this.onOptionToggled.bind(this);
    this.onOptionRemoved = this.onOptionRemoved.bind(this);
    this.onOptionClick = this.onOptionClick.bind(this);
    this.onOptionsFetched = this.onOptionsFetched.bind(this);
    this.onOutsideClicked = this.onOutsideClicked.bind(this);

    this.component.addEventListener('click', this.onComponentClick);

    this.initialize();
    this.fillOptions();
  }

  public get autofocus(): HTMLElement {
    return this.component.querySelector('input')!;
  }

  public initialize(): void {
    console.log('[custom-select] initialized');
    document.addEventListener('click', this.onOutsideClicked);
    this.searchOutput.addEventListener('keydown', this.onKey);
    this.searchInput.addEventListener('focus', this.onSearchFocus);
    this.searchInput.addEventListener('blur', this.onSearchBlur);
    this.searchInput.addEventListener('keydown', this.onSearchKey);
    this.searchInput.addEventListener('input', debounce(this.onSearch, 250));
    this.inputCaret.addEventListener('click', this.onInputToggle);

    this.searchOutput.addEventListener('keyup', this.onKeyUp);
    this.searchOutput.addEventListener('change', this.onOptionToggled);
    this.searchOutput.addEventListener('click', this.onOptionClick);

    this.topicPills.addEventListener('click', this.onOptionRemoved);
    this.searchOutput.classList.remove('no-js');
    toggleHidden(this.searchOutput, true);

    if (this.isMultiSelect() && !this.isAsyncQuery) {
      const preselectedItems = this.topicPills.querySelectorAll('input');

      if (!preselectedItems.length) {
        return;
      }

      const items = Array.prototype.map.call(
        preselectedItems,
        (item) => item.value
      );

      const options = this.searchOutput.querySelectorAll(
        'input[type="checkbox"]'
      );

      if (!options.length) {
        return;
      }

      options.forEach((option): void => {
        if (items.indexOf((option as HTMLInputElement).value) >= 0) {
          (option as HTMLInputElement).checked = true;
        }
      });
    }
  }

  public destroy(): void {
    this.topicPills.querySelectorAll('[data-value]').forEach((pill) => {
      this.removeTopic(pill.getAttribute('data-value')!);
    });

    this.component.removeEventListener('click', this.onComponentClick);
  }

  private onChange(option: HTMLElement, input: HTMLInputElement): void {
    const labelName = option.querySelector('.type--name') as HTMLElement;
    const labelValue = labelName!.getAttribute('data-query-value') as string;
    const labelDisplayName = labelName!.getAttribute(
      'data-display-name'
    ) as string;
    const autoSubmit = this.component.hasAttribute('data-auto-submit');
    const form = this.component.closest('form');

    const { value: inputValue } = input;

    this.searchInput.value = labelDisplayName || labelValue;
    this.valueInput.value = inputValue;

    if (this.labelInput) {
      this.labelInput.value = labelValue;
    }

    toggleHidden(this.searchOutput, true);

    if (autoSubmit && form) {
      form.submit();
    }

    if (this.options?.onChange) {
      this.options.onChange(labelValue, inputValue, this.valueInput);
    }
  }

  private onCreate(option: HTMLElement): void {
    const label = option.querySelector('.type--name');
    const labelValue = label!.getAttribute('data-query-value') as string;

    if (this.labelInput) {
      this.labelInput.value = labelValue;
      this.valueInput.value = '';
    } else {
      this.valueInput.value = labelValue;
    }

    this.searchInput.value = labelValue;

    if (this.options?.onCreate) {
      this.options.onCreate(labelValue, labelValue);
    }

    toggleHidden(this.searchOutput, true);
  }

  public get id(): string {
    return this.component.id;
  }

  public get isOpen(): boolean {
    return !this.component.hasAttribute('hidden');
  }

  private get isAsyncQuery(): boolean {
    return this.component.hasAttribute('data-async-query');
  }

  public get isSearching(): boolean {
    return !!this.searchInput.value;
  }

  private isMultiSelect(): boolean {
    return this.component.hasAttribute('data-multi-select');
  }

  private onOutsideClicked(event: MouseEvent): void {
    if (!(event.target as HTMLElement).closest('[data-custom-select]')) {
      toggleHidden(this.searchOutput, true);
    }
  }

  private resetSearchInput(): void {
    toggleHidden(this.searchOutput, true);
    this.searchInput.blur();
    this.searchInput.value = '';
    this.valueInput.value = '';

    if (this.labelInput) {
      this.labelInput.value = '';
    }

    this.searchOutput
      .querySelectorAll<HTMLLIElement>('[data-item-id]')
      .forEach((element) => element.setAttribute('hidden', ''));
  }

  private onInputToggle(): void {
    const isOutputOpen = !this.searchOutput.hasAttribute('hidden');

    if (!isOutputOpen) {
      this.searchInput.focus();
    }

    toggleHidden(this.searchOutput, isOutputOpen);
  }

  private onSearchKey(e: KeyboardEvent): void {
    switch (e.key) {
      case KEY_NAMES_LEGACY.ESC:
      case KEY_NAMES.ESC: {
        this.resetSearchInput();
        break;
      }

      case KEY_NAMES.TAB: {
        if (e.shiftKey) {
          toggleHidden(this.searchOutput, true);
          return;
        }

        this.focusFirstOption();
        e.preventDefault();
        return;
      }

      case KEY_NAMES_LEGACY.DOWN:
      case KEY_NAMES.DOWN: {
        this.focusFirstOption();
        e.preventDefault();
        return;
      }

      case KEY_NAMES_LEGACY.UP:
      case KEY_NAMES.UP: {
        this.focusLastOption();
        e.preventDefault();
        return;
      }

      case KEY_NAMES.RETURN: {
        this.focusFirstOption();
        e.preventDefault();
        return;
      }

      case KEY_NAMES.BACKSPACE: {
        if (this.isSearching) {
          return;
        }
        const id = this.topicPills.lastElementChild
          ?.querySelector('[data-action="remove-topic"]')
          ?.getAttribute('data-value');

        if (id) {
          this.removeTopic(id);
          e.preventDefault();
        }
      }
    }
  }

  private focusFirstOption(): void {
    const option = this.searchOutput.querySelector<HTMLLIElement>(
      'li[role="option"]:not([hidden])'
    );

    if (!option) {
      this.focusSearch();
      return;
    }

    option.focus();
  }

  private focusLastOption(): void {
    const options = this.searchOutput.querySelectorAll<HTMLLIElement>(
      'li[role="option"]:not([hidden])'
    );
    if (options.length === 0) {
      this.focusSearch();
      return;
    }

    options.item(options.length - 1).focus();
  }

  private focusSearch(): void {
    this.searchOutput.focus();
  }

  private focusNext(wrap = true, start?: HTMLElement | null): void {
    let option: HTMLElement | undefined;

    if (start === undefined) {
      if (!document.activeElement) {
        return this.focusFirstOption();
      }

      option = document.activeElement.closest('[role="option"]') as
        | HTMLElement
        | undefined;
      start = option?.nextElementSibling as HTMLElement | undefined;
    } else {
      option = start?.closest('[role="option"]') as HTMLElement | undefined;
    }

    let nextVisible = start;

    while (nextVisible && nextVisible.hasAttribute('hidden')) {
      nextVisible = nextVisible.nextElementSibling as HTMLElement | undefined;
    }

    if (nextVisible) {
      nextVisible.focus();
      return;
    }

    // See if there is another list as a sibling of the parent
    if (option?.parentElement && option.parentElement.nextElementSibling) {
      let nextParent = option.parentElement.nextElementSibling as
        | HTMLElement
        | undefined;
      while (nextParent && !nextParent.querySelector('[role="option"]')) {
        nextParent = nextParent.nextElementSibling as HTMLElement | undefined;
      }

      const nextStart =
        nextParent?.querySelector<HTMLElement>('[role="option"]');

      if (nextStart) {
        return this.focusNext(wrap, nextStart);
      }
    }

    if (!wrap) {
      return;
    }

    this.focusFirstOption();
  }

  private focusNextPage(): void {
    for (let i = 0; i < 9; i++) {
      this.focusNext(false);
    }
  }

  private focusPrevious(wrap = true, start?: HTMLElement | null): void {
    let option: HTMLElement | undefined;

    if (start === undefined) {
      if (!document.activeElement) {
        return this.focusLastOption();
      }

      option = document.activeElement.closest('[role="option"]') as
        | HTMLElement
        | undefined;
      start = option?.previousElementSibling as HTMLElement | undefined;
    } else {
      option = start?.closest('[role="option"]') as HTMLElement | undefined;
    }

    let prevVisible = start;

    while (prevVisible && prevVisible.hasAttribute('hidden')) {
      prevVisible = prevVisible.previousElementSibling as
        | HTMLElement
        | undefined;
    }

    if (prevVisible) {
      prevVisible.focus();
      return;
    }

    // See if there is another list as a sibling of the parent
    if (option?.parentElement && option.parentElement.previousElementSibling) {
      let prevParent = option.parentElement.previousElementSibling as
        | HTMLElement
        | undefined;
      while (prevParent && !prevParent.querySelector('[role="option"]')) {
        prevParent = prevParent.previousElementSibling as
          | HTMLElement
          | undefined;
      }

      const prevStart =
        prevParent?.querySelector<HTMLElement>('[role="option"]');

      if (prevStart) {
        return this.focusPrevious(wrap, prevStart);
      }
    }

    if (!wrap) {
      return;
    }

    this.focusLastOption();
  }

  private focusPreviousPage(): void {
    for (let i = 0; i < 9; i++) {
      this.focusPrevious(false);
    }
  }

  private setFocusByFirstCharacter(char: string): void {
    return;
  }

  private toggleOption(): void {
    if (
      !document.activeElement ||
      document.activeElement.getAttribute('role') !== 'option'
    ) {
      return;
    }

    const option = document.activeElement;
    const input = option.querySelector('input')!;

    const next = !input.checked;
    input.checked = next;

    const realOption = input.closest<HTMLElement>('[role="option"]')!;
    realOption.setAttribute('aria-selected', String(next));

    const label = realOption.querySelector('.type--name');

    if (this.isMultiSelect()) {
      this.togglePill(next, input.value, (label || realOption).textContent);
    } else {
      this.onChange(realOption, input);
    }
  }

  public togglePill(checked: boolean, id: string, label: string | null): void {
    if (checked) {
      const text = label;

      this.topicPills.appendChild(this.createTopicPill(text || '', id));
      this.searchInput.value = '';
    } else {
      this.topicPills
        .querySelector(`[data-value="${id}"]`)
        ?.closest('.item')
        ?.remove();
    }
  }

  private createTopicPill(label: string, value: string): Element {
    const template = this.component.querySelector(
      '#template-selected-pill'
    ) as HTMLTemplateElement;

    const pill = document.importNode(template.content.firstElementChild!, true);
    const pillLabel = pill.querySelector('[data-target="label"]')!;
    const pillAction = pill.querySelector('[data-action="remove-item"]')!;
    const pillInput = pill.querySelector(
      'input[type="hidden"]'
    )! as HTMLInputElement;

    pillLabel.textContent = label;
    pillInput.value = value;
    pillAction.setAttribute('data-value', value);

    return pill;
  }

  private onKey(e: KeyboardEvent): void {
    let handled = false;

    switch (e.key) {
      case KEY_NAMES_LEGACY.ESC:
      case KEY_NAMES.ESC: {
        handled = true;
        break;
      }

      case KEY_NAMES_LEGACY.UP:
      case KEY_NAMES.UP: {
        this.focusPrevious();
        handled = true;
        break;
      }

      case KEY_NAMES_LEGACY.DOWN:
      case KEY_NAMES.DOWN: {
        this.focusNext();
        handled = true;
        break;
      }

      case KEY_NAMES.HOME: {
        this.focusFirstOption();
        handled = true;
        break;
      }

      case KEY_NAMES.PAGE_UP: {
        this.focusPreviousPage();
        handled = true;
        break;
      }

      case KEY_NAMES.END: {
        this.focusLastOption();
        handled = true;
        break;
      }

      case KEY_NAMES.PAGE_DOWN: {
        this.focusNextPage();
        handled = true;
        break;
      }

      case KEY_NAMES_LEGACY.SPACE:
      case KEY_NAMES.SPACE:
      case KEY_NAMES.RETURN: {
        this.toggleOption();
        handled = true;
        break;
      }

      case KEY_NAMES.TAB:
        if (!this.searchOutput.hasAttribute('hidden')) {
          toggleHidden(this.searchOutput, true);
        }
        break;

      default:
        if (isPrintableCharacter(e.key)) {
          this.setFocusByFirstCharacter(e.key);
          handled = true;
        }
        break;
    }

    if (handled) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
    }
  }

  private onKeyUp(e: KeyboardEvent): void {
    if (e.key === KEY_NAMES_LEGACY.ESC || e.key === KEY_NAMES.ESC) {
      e.preventDefault();
      e.stopPropagation();
      this.searchInput.focus();
    }
  }

  private onComponentClick(e: MouseEvent): void {
    if (
      e.target === this.searchOutput ||
      e.target === this.searchInput ||
      this.searchOutput.contains(e.target as Node)
    ) {
      return;
    }
  }

  private onOptionClick(e: MouseEvent): void {
    //
  }

  private onOptionRemoved(e: Event): void {
    if (!e.target) {
      return;
    }

    const clicked = e.target as HTMLElement;
    const pill = clicked.closest('.item');

    if (!pill) {
      return;
    }

    const action = pill.querySelector('[data-action="remove-item"]');

    if (!action) {
      return;
    }

    const id = action.getAttribute('data-value')!;
    this.removeTopic(id);
  }

  public removeTopic(id: string): void {
    const checkbox = this.searchOutput.querySelector<HTMLInputElement>(
      `input[value="${id}"]`
    )!;

    if (!checkbox) {
      return;
    }

    checkbox.checked = false;
    const option = checkbox.closest<HTMLElement>('[role="option"]')!;
    option.setAttribute('aria-selected', 'false');

    this.topicPills
      .querySelector(`[data-value="${id}"]`)
      ?.closest('.item')
      ?.remove();
  }

  private onOptionToggled(e: Event): void {
    if (!e.target) {
      return;
    }

    const input = e.target as HTMLInputElement;
    const value = input.checked;

    const option = input.closest<HTMLElement>('[role="option"]')!;
    option.setAttribute('aria-selected', String(value));
    option.focus();

    const isCreatable = option.hasAttribute('data-creatable');
    const label = option.querySelector('.type--name');

    if (isCreatable) {
      this.onCreate(option);
      return;
    }

    if (!this.isMultiSelect()) {
      this.onChange(option, input);
    } else {
      this.togglePill(value, input.value, (label || option).textContent);
    }

    const helper = this.component.querySelector('.helper');
    helper?.remove();
  }

  private onSearchFocus(): void {
    toggleHidden(this.searchOutput, false);

    if (this.isAsyncQuery) {
      this.queryResults(this.searchInput.value);
    }
  }

  private onSearchBlur(e: FocusEvent): void {
    const { relatedTarget } = e;
    if (
      (relatedTarget as Element)?.matches('[role="option"]') ||
      (relatedTarget as Element)?.matches('[data-action="toggle-dropdown"]')
    ) {
      return;
    }

    if (!this.valueInput.value.trim() && this.valueInput.name) {
      this.searchInput.value = '';
      this.onSearch();
    } else if (this.valueInput.value.trim()) {
      const listItem = this.searchOutput.querySelector(
        `[data-item-id="${this.valueInput.value}"]`
      )!;
      const itemLabel = listItem.querySelector('[data-query-value]')!;

      this.searchInput.value = itemLabel.textContent as string;
    }

    toggleHidden(this.searchOutput, true);
  }

  private onSearch(): void {
    if (this.isAsyncQuery) {
      this.queryResults(this.searchInput.value);
      return;
    }

    if (!this.isOpen) {
      return;
    }

    const query = this.searchInput.value;
    const tokens = query
      .split(' ')
      .map((token) => token.trim().toLocaleLowerCase());

    this.searchOutput
      .querySelectorAll<HTMLLIElement>('[data-item-id]')
      .forEach((element) => {
        const target = element.querySelector('[data-query-value]')!;
        const value = target!.getAttribute('data-query-value') || '';
        const matchValue = value.toLocaleLowerCase();
        const matches = tokens.every((token) => matchValue.includes(token));

        if (matches) {
          const highlightedValue = value;

          // TODO highlight matching tokens

          element.removeAttribute('hidden');
          target.innerHTML = highlightedValue;
        } else {
          element.setAttribute('hidden', '');
        }
      });

    const options = this.searchOutput.querySelectorAll('[data-item-id]');
    const hiddenOptions = this.searchOutput.querySelectorAll(
      '[data-item-id][hidden]'
    );

    const message = this.searchOutput.querySelector(
      '[data-no-match]'
    ) as HTMLElement;

    if (!message) {
      return;
    }

    if (options.length === hiddenOptions.length) {
      message.innerHTML = `No results for <strong>'${query}'</strong>`;
      toggleHidden(message, false);
    } else {
      toggleHidden(message, true);
    }
  }

  private queryResults(query: string): void {
    const url = this.component.getAttribute('data-items-url')!;
    const queryUrl = `${url}?${encodeParams({ q: query })}`;

    fetch(queryUrl, {
      headers: {
        accept: 'text/html',
      },
    })
      .then((result: Response) => {
        return result.text();
      })
      .then((html: string) => {
        this.onOptionsFetched(html);
      })
      .catch((error: Error) => console.log('Error', error));
  }

  private fillOptions(): void {
    const url = this.component.getAttribute('data-items-url');
    if (!url) {
      console.warn('[items] dialog expected url to fetch the items to select');
      return;
    }

    if (this.searchOutput.hasAttribute('data-filled')) {
      // ALready loaded
      return;
    }

    fetch(url, {
      headers: {
        accept: 'text/html',
      },
    })
      .then((result: Response) => {
        return result.text();
      })
      .then((html: string) => this.onOptionsFetched(html))
      .catch((error: Error) => console.log('Error', error));
  }

  private onOptionsFetched(options: string | null): void {
    if (!options) {
      console.warn('[items] dialog did not receive items from items url');

      return;
    }

    this.searchOutput.innerHTML = `<ul>${options}</ul>`;

    this.searchOutput.setAttribute('data-filled', '');

    upgradeAll();
  }
}
