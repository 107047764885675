import { Controller } from '@hotwired/stimulus';
import { isFocusable } from '../src/brandless/aria';

export default class extends Controller {
  scrollToTarget(e) {
    e.preventDefault();

    const source =
      this.element.getAttribute('data-href') ||
      this.element.getAttribute('href');

    if (!source) return;

    const element = document.querySelector(source);

    if (isFocusable(element)) {
      element.focus();
    }

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}
