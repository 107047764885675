import { Controller } from '@hotwired/stimulus';
import consumer from '../../src/channels/consumer';
import Mark from 'mark.js/dist/mark.es6';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['viewersCount', 'viewersContainer', 'search', 'filterable'];

  connect() {
    this.page = document.querySelector('#video-page');
    this.loadIfCurrentViewerIsRegistered();
  }

  loadIfCurrentViewerIsRegistered() {
    if (this.page.hasAttribute('data-current-viewer-registered')) {
      this.loadViewers();
    } else {
      setTimeout(this.loadIfCurrentViewerIsRegistered.bind(this), 400);
    }
  }

  loadViewers() {
    const $viewersContainer = $(this.viewersContainerTarget);
    $.ajax({
      url: this.data.get('url'),
      method: 'GET',
      success: (data) => {
        this.viewersCountTarget.innerText = data.viewer_count;
        $viewersContainer.html(data.html);

        this.subscribe();
      },
    });
  }

  filter() {
    let query = this.searchTarget.value.toLowerCase();

    this.filterableTargets.forEach((e) => {
      let filterableKey = e.getAttribute('data-filter-key');
      e.classList.toggle('hidden', !filterableKey.includes(query));
    });
  }

  highlight() {
    let query = this.searchTarget.value.toLowerCase();
    let instance = new Mark(this.viewersContainerTarget);
    instance.unmark();
    instance.mark(query);
  }

  subscribe() {
    const $viewersContainer = $(this.viewersContainerTarget);

    this.subscription = consumer.subscriptions.create(
      {
        channel: 'LiveVideoViewersChannel',
        video_slug: this.data.get('slug'),
      },
      {
        received: (data) => {
          const $updatedViewer = $(data.html);
          const $existingViewer = $viewersContainer.find(
            `[data-viewer-id=${data.viewer.id}]`
          );

          $existingViewer.remove();
          this.insertViewer(data.viewer, $updatedViewer);
          this.viewersCountTarget.innerText =
            $viewersContainer.children().length;
        },
      }
    );
  }

  disconnect() {
    if (this.subscription) consumer.subscriptions.remove(this.subscription);
  }

  insertViewer(viewer, viewerEl) {
    const $viewersContainer = $(this.viewersContainerTarget);
    const stateDataAttr = viewer.active
      ? '[data-is-active]'
      : '[data-is-inactive]';
    const $moderatorViewers = $viewersContainer.find(
      `${stateDataAttr}[data-is-moderator]`
    );
    const $organiserViewers = $viewersContainer.find(
      `${stateDataAttr}[data-is-organiser]`
    );
    const $stateBasedRegularViewers = $viewersContainer
      .find(stateDataAttr)
      .not($moderatorViewers)
      .not($organiserViewers);
    const $stateBasedAllViewers = $viewersContainer.find(stateDataAttr);

    if (viewer.moderator) {
      if ($stateBasedAllViewers.length > 0) {
        $stateBasedAllViewers.first().before(viewerEl);
      } else {
        viewer.active
          ? $viewersContainer.prepend(viewerEl)
          : $viewersContainer.append(viewerEl);
      }
    } else if (viewer.organiser) {
      let $presentViewer =
        ($organiserViewers.length > 0 && $organiserViewers) ||
        ($stateBasedRegularViewers.length > 0 && $stateBasedRegularViewers);

      if ($moderatorViewers.length > 0) {
        $moderatorViewers.last().after(viewerEl);
      } else if ($presentViewer) {
        $presentViewer.first().before(viewerEl);
      } else {
        viewer.active
          ? $viewersContainer.prepend(viewerEl)
          : $viewersContainer.append(viewerEl);
      }
    } else {
      let $presentViewer =
        ($organiserViewers.length > 0 && $organiserViewers) ||
        ($moderatorViewers.length > 0 && $moderatorViewers);

      if ($stateBasedRegularViewers.length > 0) {
        $stateBasedRegularViewers.first().before(viewerEl);
      } else if ($presentViewer) {
        $presentViewer.last().after(viewerEl);
      } else {
        viewer.active
          ? $viewersContainer.prepend(viewerEl)
          : $viewersContainer.append(viewerEl);
      }
    }
  }
}
