import onmount from 'onmount'

onmount('.js-brand-ui-copy-video-url', function() {
  this.addEventListener('click', function() {
    const url = this.getAttribute('data-clipboard-text')
    const textArea = document.createElement("textarea");
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";
      console.log("Copy video url was " + msg);
    } catch (err) {
      console.error("Copy video url was ", err);
    }

    document.body.removeChild(textArea);
  })
})