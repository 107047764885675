import { register } from '../brandless/ujs';
import $ from 'jquery';
import 'slick-carousel';

register('#post-image-album-dialog', ({ element }) => {
  const prevArrow = element.querySelector('[data-arrow-prev]');
  const nextArrow = element.querySelector('[data-arrow-next]');

  const album = element.querySelector('[data-post-image-album-slideshow]');
  const initialSlide = album.getAttribute('data-order');

  // Closes dialog when clicked on any area except the image

  const images = album.querySelectorAll('img');
  images.forEach((image) => {
    image.addEventListener('click', (e) => {
      e.stopPropagation();
    });
  });

  ($(album) as any).slick({
    slidesToShow: 1,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    swipeToSlide: true,
    infinite: false,
    initialSlide: Number(initialSlide),
    rows: 0,
  });
});
