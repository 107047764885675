import { Context, Controller } from '@hotwired/stimulus';
import { toggleHidden } from 'src/brandless/utils';

export default class SearchController extends Controller {
  private input: HTMLInputElement;
  private menus: HTMLElement;
  private logo: HTMLElement;
  private searchWrapper: HTMLElement;
  private clearButton: HTMLElement;
  private searchIcon: HTMLElement;

  constructor(context: Context) {
    super(context);

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
  }

  public connect(): void {
    this.input = this.element.querySelector('input#search-input');
    this.menus = this.element.querySelector('[data-nav-menus]');
    this.logo = this.element.querySelector('header.logo');
    this.searchWrapper = this.element.querySelector('[data-search-wrapper]');
    this.searchIcon = this.element.querySelector('[data-search-icon]');
    this.clearButton = this.element.querySelector('[data-clear-button]');

    this.input.addEventListener('input', this.onChange);
    this.input.addEventListener('focus', this.onFocus);
    this.searchIcon.addEventListener('click', this.onSearchClick);
    // Used this instead of blur so other components can still be clicked/tapped
    document.addEventListener('mousedown', this.onBlur);
  }

  public disconnect(): void {
    this.input.removeEventListener('focus', this.onFocus);
    this.searchIcon.removeEventListener('click', this.onSearchClick);
    document.removeEventListener('mousedown', this.onBlur);
  }

  private onChange(): void {
    toggleHidden(this.clearButton, !this.input.value.trim().length);
  }

  private onFocus(): void {
    // toggleHidden(this.logo, true);
  }

  private onBlur(e): void {
    if (e.target.closest('[data-search-form]')) {
      return;
    }

    this.input.blur();
    this.searchWrapper.classList.remove('nav-search--expanded');
    toggleHidden(this.menus, false);
    toggleHidden(this.logo, false);
  }

  private onSearchClick(): void {
    this.searchWrapper.classList.add('nav-search--expanded');
    this.input.focus();
  }
}
