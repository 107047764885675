import { Eventable } from './eventable';
import { register, upgrade } from './ujs';

export type BookmarkEvent = {
  id: string;
  state: boolean;
};

/**
 * Makes a component marked with [data-bookmarkable] respond to bookmark events.
 *
 * Items that can be bookmarked, such as posts, can have one or more actions to
 * save or unsave such an item (bookmarking). These actions can be ujs-enabled
 * forms, meaning they will submit using XHR, and not refresh the page. These
 * responses emit bookmark events that can be captured.
 *
 * In order to make an element change its visibility, add the following data
 * attributes:
 *
 * - data-bookmarkable: 'true' | 'false'
 * - data-type: 'post'
 * - data-id: 'post_id'
 *
 * The component marked with [data-bookmarkable] will hide itself when the
 * bookmark state matches its value ('true' or 'false').
 *
 * @example
 *
 * <form action="/posts/1/bookmark"
 *       method="post"
 *       data-remote
 *       data-bookmarkable="true"
 *       data-type="post"
 *       data-id="1">
 *   <button type="submit">Save</button>
 * </form>
 */
export class Bookmarkable extends Eventable<BookmarkEvent> {
  constructor(component: HTMLElement) {
    super(component, 'bookmarkable', 'bookmark');
  }
}

export function bookmarkables(): void {
  upgrade('[data-bookmarkable]');
}

export function bookmarkable(component: HTMLElement): Bookmarkable {
  return new Bookmarkable(component);
}

register('[data-bookmarkable]', ({ element }) => bookmarkable(element).destroy);
