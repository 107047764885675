import $ from 'jquery'
import onmount from 'onmount'
import Sortable from 'sortablejs'
import '@selectize/selectize/dist/js/selectize.min.js'

///// Init
onmount('.js-events-agenda-fields', function(mountEvent) {
  ///// Init sortable.js for collected events_agenda ul
  let sortableList = document.getElementById('js-sortable-event-list')
  if (sortableList != null) { Sortable.create(sortableList) }

  var eventSelectize = $(this).find('.js-eventsearch-selectize')
  eventSelectize.selectize()

  ///// Adding videos to collection after clicking the button
  $('#js-event-add').click(function() {
    $.ajax({
      url: $(this).data('url') + "/?event_id=" + eventSelectize.val(),
      type: 'GET',
      error: function(response) { console.log(response) },
      success: function(response) {
        let found = $('#js-event-selection > ul > li > input[value=' + eventSelectize.val() + ']').length > 0
        if (!found) { $('#js-event-selection > ul').append(response.html) }
      }
    });
  })

  /// Reactiveness of controls when changing the configuration.mode
  $('#channel_page_module_events_agenda_module_configuration_mode').change(function() {
    var val = $(this)[0].value
    switch(val) {
      case 'event_ids':
        $('.js-event-select').removeClass('hidden')
        $('#js-event-selection').removeClass('hidden')
        break
      default:
        $('.js-event-select').addClass('hidden')
        $('#js-event-selection').addClass('hidden')
    }
  })
})
