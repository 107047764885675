import UuidController from './uuid_controller.js';

export default class extends UuidController {
  static targets = ['directMessageNotifications'];

  send(event) {
    const checkbox = this.directMessageNotificationsTarget;

    const uuid = this.getUuid(event);

    if (typeof window.fbq === 'undefined') {
      return;
    }

    if (checkbox.checked) {
      window.fbq(
        'trackCustom',
        'Subscribed to Marketing Emails',
        {},
        { eventID: uuid }
      );
      console.debug('Subscribed to Marketing Emails');
    } else {
      window.fbq(
        'trackCustom',
        'Unsubscribed from Marketing Emails',
        {},
        { eventID: uuid }
      );
      console.debug('Unsubscribed from Marketing Emails');
    }

    // There is no need to update the element, since the User Account page
    // is always reloaded after clicking the button to save these changes.
  }
}
