import { AddImageAlbumDialog } from './AddImageAlbumDialog';
import Turbolinks from 'turbolinks';
import { ConfirmDialog } from '../confirmable';
import { ContentEditable, Placeholder, TextInput } from '../content-editable';
import { Dialog, getCurrentDialog } from '../dialog';
import { fetch, fetchResource } from '../fetch';
import { Tributable } from '../tributable';
import { toggleHidden } from '../utils';
import { AddDocumentDialog } from './AddDocumentDialog';
import { AddImageDialog } from './AddImageDialog';
import { AddPostTopicsDialog } from './AddPostTopicsDialog';
import { AddVideoDialog } from './AddVideoDialog';

import Uppy, { UploadedUppyFile } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import Dashboard from '@uppy/dashboard';
import Webcam from '@uppy/webcam';

type PreviewResponse = {
  preview: {
    _links: {
      self: { href: string };
      subject: { href: string };
      thumbnail?: { href: string };
    };

    title: string;
    description: string;
    representation: string;
  };
};

/**
 * Base dialog to create a new post.
 *
 * In order to keep everything in sync, only use the open and close methods on
 * this element and not the global dialog helpers, for now.
 *
 * @see {AddPostTopicsDialog}
 */
export class CreateNewPostDialog extends Dialog {
  private readonly postInput: HTMLElement;
  private inputDocument: HTMLInputElement;
  private inputVideoSourceLocation: HTMLInputElement;
  private inputMultipleImages: HTMLInputElement;
  private readonly inputLink: HTMLInputElement;
  private readonly inputTitle: HTMLInputElement;
  private readonly inputDescription: HTMLInputElement;
  private readonly inputDocumentTemplate: HTMLInputElement;
  private readonly inputVideoTemplate: HTMLInputElement;
  private readonly richInput: ContentEditable;

  private readonly actionAddTopics: HTMLElement;
  private readonly actionClose: HTMLElement;
  private readonly actionMention: HTMLElement;
  private readonly actionHashtag: HTMLElement;
  private readonly actionAddImages: HTMLElement;

  private readonly postTopics: HTMLElement;

  private readonly previewLink: HTMLElement;
  private readonly previewImage: HTMLElement;
  private readonly previewDocument: HTMLElement;
  private readonly previewVideo: HTMLElement;
  private readonly uploadVideo: HTMLElement;

  private inputVideoFileName: string | undefined;
  private inputVideoFileSize: number | undefined;

  private readonly addTopicsDialog: Dialog & {
    removeTopic(id: string): void;
    autofocus?: HTMLElement;
  };
  private readonly addImageDialog: Dialog & {
    withFile(file: File): Dialog;
  };

  private readonly addImageAlbumDialog: Dialog & {
    uploader: Uppy.Uppy<Uppy.StrictTypes>;
  };

  private readonly addDocumentDialog: Dialog & {
    withFile(file: File): Dialog;
  };
  private readonly addVideoDialog: Dialog & {
    withFile(
      sourceLocation: string,
      fileName: string,
      fileSize: number
    ): Dialog;
  };

  public postType = 'article';

  private tribute!: Tributable;

  constructor(private readonly component: HTMLElement) {
    super(component.id);

    this.postInput = component.querySelector<HTMLElement>(
      '[data-target="input"]'
    )!;
    this.postTopics = component.querySelector<HTMLElement>(
      '[data-target="selected-topics"]'
    )!;
    this.actionMention = component.querySelector<HTMLElement>(
      '[data-action="mention"]'
    )!;
    this.actionHashtag = component.querySelector<HTMLElement>(
      '[data-action="hashtag"]'
    )!;
    this.actionAddTopics = component.querySelector<HTMLElement>(
      '[data-action="add-post-topics"]'
    )!;
    this.actionClose = component.querySelector<HTMLElement>(
      '[data-action="close"]'
    )!;
    this.actionAddImages = component.querySelector<HTMLElement>(
      '#post-upload-images'
    )!;
    this.inputLink = component.querySelector<HTMLInputElement>(
      'input[name="post[link_url]"]'
    )!;
    this.inputTitle = component.querySelector<HTMLInputElement>(
      'input[name="post[attachment_title]"]'
    )!;
    this.inputDescription = component.querySelector<HTMLInputElement>(
      'input[name="post[description]"]'
    )!;
    this.inputMultipleImages = component.querySelector<HTMLInputElement>(
      'input[name="post[images]"]'
    )!;
    this.inputDocument = component.querySelector<HTMLInputElement>(
      'input[name="post[document_attachment]"]'
    )!;
    this.uploadVideo =
      component.querySelector<HTMLElement>('#post-upload-video')!;
    this.inputVideoSourceLocation = component.querySelector<HTMLInputElement>(
      'input[name="post[video_source_location]"]'
    )!;
    this.previewLink = this.component.querySelector<HTMLElement>(
      '[data-target="share-target"]'
    )!;
    this.previewImage = this.component.querySelector<HTMLElement>(
      '[data-target="image-attachment"]'
    )!;
    this.previewDocument = this.component.querySelector<HTMLElement>(
      '[data-target="document-attachment"]'
    )!;
    this.previewVideo = this.component.querySelector<HTMLElement>(
      '[data-target="video-attachment"]'
    )!;

    // Make JavaScript-enabled
    toggleHidden(this.actionMention, false);
    toggleHidden(this.actionHashtag, false);
    toggleHidden(this.actionAddTopics, false);
    toggleHidden(this.actionAddImages, false);

    this.destroy = this.destroy.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.startAddingTopics = this.startAddingTopics.bind(this);
    this.startMention = this.startMention.bind(this);
    this.startHashtag = this.startHashtag.bind(this);
    this.startAddingImages = this.startAddingImages.bind(this);
    this.setTopics = this.setTopics.bind(this);

    this.onRemovePostTopic = this.onRemovePostTopic.bind(this);
    this.onDocumentChanged = this.onDocumentChanged.bind(this);
    this.onVideoChanged = this.onVideoChanged.bind(this);
    this.onDismissLink = this.onDismissLink.bind(this);
    this.onDismissImage = this.onDismissImage.bind(this);
    this.onDismissDocument = this.onDismissDocument.bind(this);
    this.onDismissVideo = this.onDismissVideo.bind(this);
    this.onLinkForPreview = this.onLinkForPreview.bind(this);

    // Copy file inputs. See refreshFileInput why.
    this.inputDocumentTemplate = this.inputDocument.cloneNode(
      true
    ) as HTMLInputElement;
    this.inputVideoTemplate = this.inputVideoSourceLocation.cloneNode(
      true
    ) as HTMLInputElement;

    // Initialize share-target. This _must_ happen after copying file inputs, or
    // the state that this is in will be propagated when the file inputs are
    // reset.
    this.fileAttachmentsEnabled = !this.inputLink.value;

    this.actionClose.addEventListener('click', this.close);
    this.actionAddTopics.addEventListener('click', this.startAddingTopics);
    this.actionMention.addEventListener('click', this.startMention);
    this.actionAddImages.addEventListener('click', this.startAddingImages);
    this.actionHashtag.addEventListener('click', this.startHashtag);

    this.postTopics.addEventListener('click', this.onRemovePostTopic);
    this.inputDocument.addEventListener('change', this.onDocumentChanged);
    this.inputVideoSourceLocation.addEventListener(
      'change',
      this.onVideoChanged
    );

    this.richInput = new ContentEditable(
      this.component.querySelector<TextInput>('[data-target="nojs-input"]')!,
      this.postInput,
      this.component.querySelector<Placeholder>('[data-target="placeholder"]')!,
      this.onLinkForPreview
    );

    this.addTopicsDialog = new AddPostTopicsDialog(
      document.querySelector<HTMLElement>('#add-post-topics-dialog')!,
      this
    );

    this.addImageDialog = new AddImageDialog(
      document.querySelector<HTMLElement>('#add-post-image-dialog')!,
      this
    );

    this.addImageAlbumDialog = new AddImageAlbumDialog(
      document.querySelector<HTMLElement>('#add-post-image-album-dialog')!,
      this
    );

    this.addDocumentDialog = new AddDocumentDialog(
      document.querySelector<HTMLElement>('#add-post-document-dialog')!,
      this
    );

    this.addVideoDialog = new AddVideoDialog(
      document.querySelector<HTMLElement>('#add-post-video-dialog')!,
      this
    );

    this.previewLink
      .querySelector('[data-action="dismiss"]')
      ?.addEventListener('click', this.onDismissLink);

    this.previewDocument
      .querySelector('[data-action="dismiss"]')
      ?.addEventListener('click', this.onDismissDocument);

    this.previewImage
      .querySelector('[data-action="dismiss"]')
      ?.addEventListener('click', this.onDismissImage);

    this.previewVideo
      .querySelector('[data-action="dismiss"]')
      ?.addEventListener('click', this.onDismissVideo);

    const endpoints = document.head.querySelector<HTMLMetaElement>(
      'meta[name="wondr-uppy-endpoints"]'
    )!.content;
    const videosEndpoint = JSON.parse(endpoints).videos;

    new Uppy({
      restrictions: {
        maxFileSize: 5 * 1024 * 1024 * 1024, // 5GB
        allowedFileTypes: ['video/mp4', 'video/x-m4v', 'video/*'],
        maxNumberOfFiles: 1,
      },
    })
      .use(Dashboard, {
        trigger: this.uploadVideo,
        closeAfterFinish: true,
        showProgressDetails: true,
        proudlyDisplayPoweredByUppy: false,
        fileManagerSelectionType: 'files',
        theme: 'auto',
      })
      .use(Webcam, {
        target: Dashboard,
        onBeforeSnapshot: () => Promise.resolve(),
        countdown: false,
        modes: ['video-only'],
        mirror: false,
        videoConstraints: {
          facingMode: 'environment',
          width: { min: 720, ideal: 1280, max: 1920 },
          height: { min: 480, ideal: 800, max: 1080 },
        },
        showRecordingLength: false,
        preferredVideoMimeType: ['video/mp4', 'video/x-m4v', 'video/*'],
      })
      .use(AwsS3, { companionUrl: videosEndpoint })
      .on('complete', (result) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const file = result.successful[0] as any;
        const sourceLocation = file.xhrUpload.endpoint + '/' + file.meta.key;

        this.inputVideoSourceLocation.value = sourceLocation;
        this.inputVideoFileName = file.name;
        this.inputVideoFileSize = file.progress.bytesTotal;

        this.inputVideoSourceLocation.dispatchEvent(new Event('change'));
      });
  }

  public destroy(): void {
    super.destroy();

    this.addTopicsDialog.destroy();
    this.addImageDialog.destroy();
    this.addDocumentDialog.destroy();
    this.addVideoDialog.destroy();

    this.actionClose.removeEventListener('click', this.close);
    this.actionAddTopics.removeEventListener('click', this.startAddingTopics);
    this.actionMention.removeEventListener('click', this.startMention);
    this.actionHashtag.removeEventListener('click', this.startHashtag);
    this.actionAddImages.removeEventListener('click', this.startAddingImages);

    this.postTopics.removeEventListener('click', this.onRemovePostTopic);
    this.inputDocument.removeEventListener('change', this.onDocumentChanged);
    this.inputVideoSourceLocation.removeEventListener(
      'change',
      this.onVideoChanged
    );
    this.richInput.destroy();

    this.previewLink
      .querySelector('[data-action="dismiss"]')
      ?.removeEventListener('click', this.onDismissLink);

    this.previewDocument
      .querySelector('[data-action="dismiss"]')
      ?.removeEventListener('click', this.onDismissDocument);

    this.previewImage
      .querySelector('[data-action="dismiss"]')
      ?.removeEventListener('click', this.onDismissImage);

    this.previewVideo
      .querySelector('[data-action="dismiss"]')
      ?.removeEventListener('click', this.onDismissVideo);
  }

  public get id(): string {
    return this.component.id;
  }

  public get isActive(): boolean {
    return !this.component.hidden;
  }

  /**
   * Opens itself, and enters the flow for the specific post type.
   *
   * @param source the source of opening the dialog, usually a button.
   * @param focusFirst the element to focus, defaults to the postInput
   * @param clearOnOpen clears the form on open, defauts to false
   */
  public open(
    source?: string | Node,
    focusFirst: string | Node = this.postInput,
    clearOnOpen: boolean = false
  ): this {
    console.debug(`[new-post] ${this.postType}`);

    this.tribute = new Tributable(this.postInput, this.component);
    super.open(source, focusFirst, clearOnOpen);

    switch (this.postType) {
      case 'image': {
        this.component
          .querySelector<HTMLInputElement>('input#post_images')!
          .click();
        this.close();
        break;
      }

      case 'document': {
        this.component
          .querySelector<HTMLInputElement>('input#post_document_attachment')!
          .click();
        break;
      }

      case 'video': {
        this.uploadVideo.click();
        break;
      }
    }

    this.postType = 'article';

    return this;
  }

  public close(
    event?: Event,
    replaced: boolean = false,
    destroyed: boolean = false
  ): boolean {
    this.tribute?.destroy();

    this.fileAttachmentsEnabled = true;

    if (getCurrentDialog() !== this || replaced || destroyed) {
      return super.close(event, replaced, destroyed);
    }

    // If this dialog is "dirty", confirm that we really want to close it.
    if (this.dirty) {
      const dialog = new ConfirmDialog('discard-draft-dialog', this.postInput);

      // When it's confirmed, clear this (removing the dirty flag)
      dialog.onConfirm = (): void => {
        this.clear();
        this.close(event, false);

        if (
          this.actionClose instanceof HTMLAnchorElement &&
          this.actionClose.href
        ) {
          Turbolinks.clearCache();
          Turbolinks.visit(this.actionClose.href, { action: 'replace' });
        }
      };

      dialog.open(this.postInput);

      // Handle event and prevent other handlers from taking over
      event?.stopImmediatePropagation();
      event?.stopPropagation();
      event?.preventDefault();
      return false;
    } else {
      // Always clear, even when not dirty, to reset state.
      this.clear();

      if (
        this.actionClose instanceof HTMLAnchorElement &&
        this.actionClose.href
      ) {
        Turbolinks.clearCache();
        Turbolinks.visit(this.actionClose.href, { action: 'replace' });
      }
    }

    return super.close(event, replaced, destroyed);
  }

  public clear(): void {
    this.richInput.clear();

    this.clearTopics();
    this.clearLink();
    this.clearImage();
    this.clearDocument();
    this.clearVideo();

    this.addTopicsDialog.clear();
    this.addImageDialog.clear();
    this.addImageAlbumDialog.clear();
    this.addDocumentDialog.clear();
    this.addVideoDialog.clear();

    super.clear();
  }

  public get dirty(): boolean {
    return this.richInput.length > 0 || this.hasAttachment || this.hasTopics;
  }

  public get hasTopics(): boolean {
    return !!this.postTopics.querySelector('option, .topic');
  }

  public get hasAttachment(): boolean {
    return !!(
      this.inputLink.value ||
      this.inputDocument.files?.item(0) ||
      this.inputMultipleImages.value ||
      this.inputVideoSourceLocation.value
    );
  }

  public set fileAttachmentsEnabled(value: boolean) {
    this.inputMultipleImages.disabled = !value;
    this.inputDocument.disabled = !value;
    this.inputVideoSourceLocation.disabled = !value;

    if (value) {
      this.inputMultipleImages.closest('label')?.setAttribute('tabindex', '0');
      this.inputDocument.closest('label')?.setAttribute('tabindex', '0');
      this.inputVideoSourceLocation
        .closest('label')
        ?.setAttribute('tabindex', '0');
    } else {
      this.inputMultipleImages.closest('label')?.removeAttribute('tabindex');
      this.inputDocument.closest('label')?.removeAttribute('tabindex');
      this.inputVideoSourceLocation
        .closest('label')
        ?.removeAttribute('tabindex');
    }
  }

  /**
   * Start adding topics, temporarily closing this dialog and replacing it with
   * the add post topics dialog.
   */
  public startAddingTopics(): Dialog {
    return this.replace(
      this.addTopicsDialog,
      this.actionAddTopics,
      this.addTopicsDialog.autofocus
    );
  }

  /**
   * Sets topics in this dialog.
   *
   * @note does not sync with topics dialog. Use {#removeTopic} instead.
   * @param nextTopics
   */
  public setTopics(nextTopics: Record<string, string>): void {
    const select = this.postTopics.querySelector('select')!;
    const list = this.postTopics.querySelector('ul')!;

    const values = Object.keys(nextTopics);
    toggleHidden(this.postTopics, values.length === 0);

    while (select.lastElementChild) {
      select.removeChild(select.lastElementChild);
    }

    while (list.lastElementChild) {
      list.removeChild(list.lastElementChild);
    }

    // TODO: use template
    values.forEach((value) => {
      const option = document.createElement('option');
      option.setAttribute('selected', '');
      option.value = value;

      select.append(option);

      const li = document.createElement('li');
      li.setAttribute('data-topic-id', value);
      li.classList.add('topic');

      const span = document.createElement('span');
      span.textContent = nextTopics[value];

      const button = document.createElement('button');
      button.setAttribute('type', 'button');
      button.setAttribute('aria-label', 'Remove this topic');
      button.setAttribute('data-action', 'remove-topic');
      button.setAttribute('data-value', value);
      button.innerHTML = '&times;';

      li.append(span);
      li.append(button);
      list.append(li);
    });
  }

  private onRemovePostTopic(e: Event): void {
    const clicked = (e.target as HTMLElement).closest('button');
    if (!clicked) {
      return;
    }

    const id = clicked.getAttribute('data-value')!;
    this.removeTopic(id);
  }

  public removeTopic(id: string): void {
    const pill = this.postTopics.querySelector(`.topic[data-topic-id="${id}"]`);
    pill?.remove();

    const option = this.postTopics.querySelector(`option[value="${id}"]`);
    option?.remove();

    this.addTopicsDialog.removeTopic(id);
    toggleHidden(this.postTopics, !this.hasTopics);
  }

  public clearTopics(): void {
    this.postTopics.querySelectorAll('option').forEach((option) => {
      const id = option.value;
      this.removeTopic(id!);
    });
  }

  private onDismissLink(e: Event): void {
    e.preventDefault();

    // Override data-dismissable. Normally this should be propagated, but
    // because the preview shouldn't _really_ be dismissed, this resolves that.
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.clearLink();
  }

  private onDismissImage(e: Event): void {
    this.clearImage();
  }

  private onDismissDocument(e: Event): void {
    this.clearDocument();
  }

  private onDismissVideo(e: Event): void {
    this.clearVideo();
  }

  private onLinkForPreview(link: string): void {
    if (
      this.inputLink.value ||
      this.inputVideoSourceLocation.classList.contains('attached')
    ) {
      return;
    }

    const previewUrl = this.component.getAttribute('data-preview-share-url');
    if (!previewUrl) {
      return;
    }

    const realUrl = previewUrl.replace(
      /{url}|%7Burl%7D/,
      encodeURIComponent(link)
    );

    const accept = [
      'application/vnd.reachora.preview.v1',
      'application/vnd.reachora.preview.v1+json',
    ].join(', ');

    fetchResource<'preview', PreviewResponse>('preview', realUrl, {
      accept,
    })
      .then(({ preview }) => {
        if (this.inputLink.value) {
          return;
        }

        this.inputLink.value = preview._links!.subject!.href;
        const html =
          'content' in preview ? preview.content : preview.representation;

        this.setLink(html);
      })
      .catch(() => {
        /* noop */
      });
  }

  private onDocumentChanged(e: Event): void {
    this.clearDocument(false);

    const input = e.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }

    this.replace(
      this.addDocumentDialog.withFile(input.files.item(0)!),
      this.inputDocument
    );
  }

  private onVideoChanged(e: Event): void {
    this.clearVideo(false);

    const input = e.target as HTMLInputElement;
    if (!input.value || !this.inputVideoFileName || !this.inputVideoFileSize) {
      return;
    }

    this.replace(
      this.addVideoDialog.withFile(
        this.inputVideoSourceLocation.value,
        this.inputVideoFileName,
        this.inputVideoFileSize
      ),
      this.inputVideoSourceLocation
    );
  }

  public clearLink(): void {
    while (this.previewLink?.lastChild) {
      this.previewLink.removeChild(this.previewLink.lastChild);
    }

    this.inputLink.value = '';
    this.inputDescription.value = '';
    this.inputTitle.value = '';

    this.previewLink.append(
      this.inputLink,
      this.inputTitle,
      this.inputDescription
    );

    this.fileAttachmentsEnabled = true;
  }

  public setLink(html: string): void {
    this.clearImage();
    this.clearDocument();
    this.clearVideo();

    const template = document.createElement('div');
    template.innerHTML = html;

    // Copy over values that appear in the preview
    [this.inputLink, this.inputTitle, this.inputDescription].forEach(
      (input) => {
        const newInput = template.querySelector<HTMLInputElement>(
          `input[id="${input.id}"]`
        );
        if (newInput) {
          input.value = newInput.value;
          newInput.remove();
        }
      }
    );

    while (template.firstChild) {
      this.previewLink.append(template.firstChild);
    }

    this.previewLink
      .querySelector('[data-action="dismiss"]')
      ?.addEventListener('click', this.onDismissLink);

    toggleHidden(this.previewLink, false);
  }

  public clearImage(): void {
    const path = document.head.querySelector<HTMLMetaElement>(
      'meta[name="post-images-route"]'
    )!.content;

    this.previewImage.querySelector('article')?.remove();
    toggleHidden(this.previewImage, true);

    if (!this.inputMultipleImages.value) {
      return;
    }

    const postImageIds = JSON.parse(this.inputMultipleImages.value) as number[];

    const csrfToken = document.querySelector<HTMLMetaElement>(
      "meta[name='csrf-token']"
    )!.content;

    if (!postImageIds || !postImageIds.length) {
      return;
    }

    postImageIds.forEach((id) => {
      fetch(`${path}/${id}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
      }).then((result) => {
        console.debug(`post image '${id}' deleted`);
      });
    });

    this.inputMultipleImages.value = '';
  }

  public setImage(description: string, previewImage: string): void {
    this.clearLink();
    this.clearDocument();
    this.clearVideo();

    this.inputDescription.value = description;

    const image = new Image();
    image.src = previewImage;
    image.alt = description;

    // TODO move to CSS
    image.style.maxWidth = '100%';
    image.style.maxHeight = '200px';
    image.style.width = '100%';
    image.style.background = '#222';
    image.style.objectFit = 'contain';

    this.previewImage.prepend(image);
    toggleHidden(this.previewImage, false);
  }

  public setImages(images: Array<{ alt?: string; url: string }>): void {
    this.clearLink();
    this.clearDocument();
    this.clearVideo();

    // Clear existing
    this.previewImage.querySelector('article')?.remove();

    this.inputMultipleImages.value = JSON.stringify(images);

    const article = document.createElement('article');

    // Update
    if (images.length === 1) {
      const image = new Image();
      const data = images[0];

      image.src = data.url;
      image.alt = data.alt || '';

      image.style.maxHeight = '200px';
      article.style.width = '100%';
      article.classList.add('post__image');

      article.append(image);
    } else if (images.length > 1) {
      article.classList.add('post__photoset');
      article.setAttribute('data-count', images.length.toString());

      images.forEach((file) => {
        const figure = document.createElement('figure');
        const img = new Image();
        img.src = file.url;
        img.alt = file.alt || '';

        figure.prepend(img);

        article.append(figure);
      });
    }

    this.previewImage.prepend(article);
    toggleHidden(this.previewImage, false);
  }

  public clearDocument(fileInput = true): void {
    if (fileInput) {
      this.inputDocument = this.refreshFileInput(
        this.inputDocument,
        this.inputDocumentTemplate,
        this.onDocumentChanged
      );
    }

    this.inputTitle.value = '';
    this.inputDescription.value = '';
    this.inputDocument.classList.remove('attached');

    toggleHidden(this.previewDocument, true);
  }

  public setDocument(title: string, description: string): void {
    this.clearLink();
    this.clearImage();
    this.clearVideo();

    this.inputTitle.value = title;
    this.inputDescription.value = description;
    this.inputDocument.classList.add('attached');

    this.previewDocument.querySelector(
      '[data-target="document-title"]'
    )!.textContent = title;
    this.previewDocument.querySelector(
      '[data-target="document-description"]'
    )!.textContent = description || '<no description>';

    toggleHidden(this.previewDocument, false);
  }

  public clearVideo(fileInput = true): void {
    if (fileInput) {
      this.inputVideoSourceLocation = this.refreshFileInput(
        this.inputVideoSourceLocation,
        this.inputVideoTemplate,
        this.onVideoChanged
      );
    }

    this.inputTitle.value = '';
    this.inputDescription.value = '';
    this.inputVideoSourceLocation.classList.remove('attached');

    toggleHidden(this.previewVideo, true);
  }

  public setVideo(title: string, description: string): void {
    this.clearLink();
    this.clearDocument();
    this.clearImage();

    this.inputTitle.value = title;
    this.inputDescription.value = description;
    this.inputVideoSourceLocation.classList.add('attached');

    this.previewVideo.querySelector(
      '[data-target="video-title"]'
    )!.textContent = title;
    this.previewVideo.querySelector(
      '[data-target="video-description"]'
    )!.textContent = description || '<no description>';

    toggleHidden(this.previewVideo, false);
  }

  private refreshFileInput<T extends Element>(
    target: T,
    template: T,
    changed: (e: Event) => void
  ): T {
    // So here is the deal.
    //
    // Resetting a file input is not straight-forward. jQuery has many branches
    // which over the years only grew because different browsers handle this
    // differently. Most modern browsers allow you to reset it by using:
    //
    //  input.files = null
    //
    // But that won't work everywhere. In some version of chrome, it will yield
    // in a reset(ted) file input, but won't be included in the form data if the
    // form is then submitted. Then there is:
    //
    //  input.value = ''
    //
    // In some browsers, this raises a SecurityException, and in others it will
    // also result in the above behaviour. Technically, specwise this is a
    // read-only property for file inputs.
    //
    // Sure-fire way to reset it, is to recreate it (by their initial state),
    // and then re-attaching event handlers. That's exactly what's happening
    // here.
    const clone = template.cloneNode(true) as T;
    clone.addEventListener('change', changed);
    target.replaceWith(clone);
    return clone;
  }

  public startMention(): void {
    this.tribute.show('mentions');
    this.richInput.hidePlaceholder();
  }

  public startHashtag(): void {
    this.tribute.show('hashtags');
    this.richInput.hidePlaceholder();
  }

  public startAddingImages(): void {
    // Returns undefined
    this.addImageAlbumDialog.open(this.actionAddImages);
    this.close();
  }
}
