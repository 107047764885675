import onmount from 'onmount'

onmount('.js-char-counter', function() {
  const textarea = this.querySelector<HTMLTextAreaElement>('.js-counter-textarea')
  const parent = textarea.parentElement
  const helper = this.querySelector('.js-counter-helper')
  const currentCount = this.querySelector('.js-current')
  const totalCount = this.querySelector('.js-total')

  currentCount.innerHTML = String(textarea.value.length)

  textarea.addEventListener('keyup', (e) => {
    const { value } = e.target as HTMLTextAreaElement

    currentCount.innerHTML = String(value.length)

    if (value.length > parseInt(totalCount.textContent)) {
      helper.classList.add('-error')
    } else {
      helper.classList.remove('-error')

      if (parent.classList.contains('-error')) {
        parent.classList.remove('-error')
      }
    }
  })
})
