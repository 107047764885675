import { Controller, Context } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class SortableController extends Controller {
  static targets = ['items'];

  connect() {
    Sortable.create(this.itemsTarget, {
      dragClass: 'shadow-lg',
      onEnd: (evt) => {
        // Update position values after drag-and-drop
        const items = this.itemsTarget.querySelectorAll('li[data-item]');
        items.forEach((item, index) => {
          const positionField = item.querySelector('input[name*="[position]"]');
          positionField.value = index + 1;
        });
      },
    });
  }
}
