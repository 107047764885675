import { CreateNewPostForm } from './components/CreatePostForm';
import { RecommendationsFeedItem } from './components/RecommendationsFeedItem';
import { closeCurrentDialog, getCurrentDialog } from './dialog';
import { onNavigate } from './spa';
import { register } from './ujs';

type UpgradeEvent = { element: HTMLElement };

// Looking for all the other logic for the feed? It can be found across various
// files. For example, following channels is inside followable. The reason is
// that other pages might want to be able to follow channels as well, and can
// safely re-use that abstracted component.
//
// Even the components in THIS file can be re-used accross the platform.

function onLoadPostForm({ element }: UpgradeEvent): () => void {
  const component = new CreateNewPostForm(element);

  if (component.isActive) {
    component.open(element, 'share');
  }

  return component.destroy;
}

function onLoadRecommendations({ element }: UpgradeEvent): () => void {
  return new RecommendationsFeedItem(element).destroy;
}

onNavigate(function closeAllDialogs() {
  // Close all dialogs
  //
  // We might want to scope this to only the post creation dialogs at some
  // point.
  while (getCurrentDialog()) {
    closeCurrentDialog();
  }
});

register('[data-component="recommendations-feed-item"]', onLoadRecommendations);
register('[data-component="post-form"]', onLoadPostForm);
