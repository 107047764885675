import onmount from 'onmount'

onmount<HTMLFormElement>('form.js-badges-form', function() {
  const fileField = this.querySelector<HTMLInputElement>('.js-badges-file-field')
  const submitButton = this.querySelector<HTMLInputElement>('input[type="submit"]')

  submitButton.disabled = true

  fileField.addEventListener('change', function(e) {
    if (fileField.files.length > 0) {
      submitButton.disabled = false
    }
  })
})
