import onmount from 'onmount'
import moment from 'moment'

onmount('.js-countdown-timer', function() {
  const eventDate = moment.utc(this.getAttribute('data-datetime'), 'YYYY-MM-DD HH:mm:ss Z')

  const $timer = $(this)
  let duration = moment.duration(eventDate.diff(moment.utc()))
  const interval = 1000

  if(duration.asMilliseconds() <= 0) {
    $timer.html("00M:00D:00H:00M:00S")
  } else {
    setInterval(function(){
      if (duration.asMilliseconds() <= 0) {
        window.location.reload()
      }

      duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds')
      const months = moment.duration(duration).months()
      const days = moment.duration(duration).days()
      const hours = moment.duration(duration).hours()
      const minutes = moment.duration(duration).minutes()
      const seconds = moment.duration(duration).seconds()
      
      let durationString

      if(months <= 0) {
        durationString = days + "D:" + hours + 'H:' + minutes + 'M:' + seconds + 'S'
      } else {
        durationString = months + "M:" + days + "D:" + hours + 'H:' + minutes + 'M:' + seconds + 'S'
      }

      $timer.html(durationString)
    }, interval);
  }
});
