import { fetch } from '../brandless/fetch';

interface CustomEventInit extends EventInit {
  detail?: Record<string, unknown>;
}

interface Payload {
  video_id: string;
  identifier: string;
  source: string;
  progress?: number;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
}

const events = ['start', 'progress', 'end'];

const appendPlatformParamToEventURL = (eventURL: string): string => {
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);

  if (queryParams.get('platform')) {
    const newURL = new URL(eventURL);
    newURL.searchParams.append('platform', 'rooms');
    return newURL.toString();
  }

  return eventURL;
};

const startEvent = (payload: Payload, session: HTMLElement): void => {
  const csrfToken = document.querySelector<HTMLMetaElement>(
    "meta[name='csrf-token']"
  ).content;

  const eventURL = appendPlatformParamToEventURL(
    window.location.origin + '/video_sessions/start_event'
  );

  fetch(eventURL, {
    method: 'POST',
    headers: {
      'X-CSRF-TOKEN': csrfToken,
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then((result) => {
      if (result.ok) {
        console.debug('Video session start!', payload);
      }
    })
    .catch((error) => {
      console.error('Video session start error', payload);

      if (error.status === 422) {
        // Client error, stop sending events, since something on our side is wrong
        session.setAttribute('data-identifier', null);
      }
    });
};

const progressEvent = (payload: Payload, session: HTMLElement): void => {
  session.setAttribute('data-progress', payload.progress.toString());
  const lastSavedProgress = Number(session.dataset.lastSavedProgress) || 0;

  // progressUpdater()

  const csrfToken = document.querySelector<HTMLMetaElement>(
    "meta[name='csrf-token']"
  ).content;

  if (payload.progress < lastSavedProgress + 5) {
    return;
  }

  const eventURL = appendPlatformParamToEventURL(
    window.location.origin + '/video_sessions/progress_event'
  );

  fetch(eventURL, {
    method: 'POST',
    headers: {
      'X-CSRF-TOKEN': csrfToken,
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then((result) => {
      if (result.ok) {
        console.debug('Video session progress', payload);
        session.setAttribute(
          'data-last-saved-progress',
          payload.progress.toString()
        );
      }
    })
    .catch((error) => {
      console.error('Video session progress error', payload, error);

      if (error.status === 422) {
        session.setAttribute('data-identifier', null);
      }
    });
};

const endEvent = (payload: Payload, session: HTMLElement): void => {
  const csrfToken = document.querySelector<HTMLMetaElement>(
    "meta[name='csrf-token']"
  ).content;

  const eventURL = appendPlatformParamToEventURL(
    window.location.origin + '/video_sessions/end_event'
  );

  fetch(eventURL, {
    method: 'POST',
    headers: {
      'X-CSRF-TOKEN': csrfToken,
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then((result) => {
      if (result.ok) {
        console.debug('Video session ended!', payload);
      }
    })
    .catch((error) => {
      console.error('Video session end error!', payload);

      if (error.status === 422) {
        session.setAttribute('data-identifier', null);
      }
    });
};

events.forEach((eventName: 'start' | 'progress' | 'end') => {
  window.addEventListener(
    `reachora:player:${eventName}`,
    (event: CustomEventInit) => {
      const reachoraVideoCode = event.detail.video_code;
      const reachoraBlockbusterId = event.detail.blockbuster_id;
      let session;
      let progressBar;

      if (reachoraVideoCode) {
        session = document.querySelector(
          `.js-video-session[data-video-code="${reachoraVideoCode}"]`
        );
        progressBar = document.querySelector(
          `.js-progress[data-video-code="${reachoraVideoCode}"]`
        );
      } else if (reachoraBlockbusterId) {
        session = document.querySelector(
          `.js-video-session[data-blockbuster-id="${reachoraBlockbusterId}"]`
        );
        progressBar = document.querySelector(
          `.js-progress[data-blockbuster-id="${reachoraBlockbusterId}"]`
        );
      }

      if (!session) {
        return;
      }

      const identifier = session.dataset.identifier;
      const videoId = session.dataset.videoId;
      const source = session.dataset.source;
      const progress = event.detail.progress as number;
      const utm_campaign = event.detail.utm_campaign as string;
      const utm_content = event.detail.utm_content as string;
      const utm_medium = event.detail.utm_medium as string;
      const utm_source = event.detail.utm_source as string;
      const utm_term = event.detail.utm_term as string;

      if (!identifier && !videoId) {
        return;
      }

      const payload = {
        video_id: videoId,
        identifier,
        source,
        utm_campaign,
        utm_content,
        utm_medium,
        utm_source,
        utm_term,
      };

      switch (eventName) {
        case 'start':
          progressBar?.remove();
          startEvent(payload, session);
          return;
        case 'progress':
          progressEvent({ ...payload, progress }, session);
          return;
        case 'end':
          endEvent(payload, session);
          return;
        default:
          return;
      }
    }
  );
});
