import { Context, Controller } from '@hotwired/stimulus';

export default class TailwindRadioSet extends Controller {
  public static targets = [];
  public static values = { name: String, style: Object };

  private declare nameValue: string;
  private declare styleValue: {
    wrapper: { checked: string; unchecked: string };
    label: { checked: string; unchecked: string };
    description: { checked: string; unchecked: string };
  };

  constructor(context: Context) {
    super(context);

    this.onChange = this.onChange.bind(this);
  }

  private options(): NodeListOf<HTMLInputElement> {
    return this.element.querySelectorAll<HTMLInputElement>(
      `input[type="radio"][name="${this.nameValue}"]`
    );
  }

  public get current(): string {
    let value = '';

    this.options().forEach((item) => {
      if (item.checked) {
        value = item.value;
      }
    });

    return value;
  }

  public set current(next: string) {
    this.options().forEach((item) => {
      item.checked = next === item.value;
    });
  }

  public connect(): void {
    this.options().forEach((item) => {
      item.addEventListener('change', this.onChange);
    });

    this.onChange();
  }

  public disconnect(): void {
    this.options().forEach((item) => {
      item.removeEventListener('change', this.onChange);
    });
  }

  private onChange(): void {
    this.options().forEach((item) => {
      const activeKey = item.checked ? 'checked' : 'unchecked';
      const inactiveKey = item.checked ? 'unchecked' : 'checked';

      // Find the related elements, forcing accessibility.
      const elements = {
        wrapper: item.closest('label'),
        label: document.getElementById(
          item.getAttribute('aria-labelledby') || ''
        ),
        description: document.getElementById(
          item.getAttribute('aria-describedby') || ''
        ),
      };

      // For each potential element, set the correct classes.
      (['wrapper', 'label', 'description'] as const).forEach((element) => {
        const resolved = elements[element];
        if (!resolved) {
          return;
        }

        resolved.classList.remove(
          ...this.styleValue[element][inactiveKey].split(' ')
        );
        resolved.classList.add(
          ...this.styleValue[element][activeKey].split(' ')
        );
      });
    });
  }
}
