import { Controller } from '@hotwired/stimulus';
import { toggleHidden } from '../../src/brandless/utils';

export default class extends Controller {
  static targets = ['accepted', 'rejected', 'rejectedText'];

  decide(event) {
    const element = event.srcElement;
    const { response } = element.dataset;

    if (this.hasAcceptedTarget && this.hasRejectedTarget) {
      toggleHidden(this.acceptedTarget, response === 'reject');
      toggleHidden(this.rejectedTarget, response === 'accept');
    }

    if (response === 'reject') {
      if (this.hasRejectedTextTarget)
        this.rejectedTextTarget.textContent = 'Opted Out';
    }
  }
}
