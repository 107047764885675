import { CreateNewPostDialog } from './CreateNewPostDialog';

/**
 * Controls the feed element that allows "starting" to create a new post. Isn't
 * a dialog, but a menubar with different style buttons.
 */
export class CreateNewPostForm {
  private readonly dialog: CreateNewPostDialog;

  constructor(private readonly component: HTMLElement) {
    this.dialog = new CreateNewPostDialog(
      document.querySelector<HTMLElement>('#create-new-post-dialog')!
    );

    this.component
      .querySelectorAll<HTMLButtonElement>('[data-post-type]')
      .forEach((button) => {
        button.addEventListener('click', (e) => {
          e.preventDefault();

          const postType = button.getAttribute('data-post-type')!;
          this.open(button, postType);
        });
      });

    this.destroy = this.destroy.bind(this);
    this.open = this.open.bind(this);
  }

  public get isActive(): boolean {
    return this.dialog.isActive;
  }

  public destroy(): void {
    this.dialog.destroy();
  }

  public open(source: HTMLElement, postType: string): void {
    this.dialog.postType = postType;
    this.dialog.open(source);
  }
}
