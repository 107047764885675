import onmount from 'onmount'

onmount('.js-square-ratio', function() {
  const thumbnail = this.getBoundingClientRect()
  this.setAttribute('style', `height: ${thumbnail.width}px`)

  window.addEventListener('resize', () => {
    const thumbnail = this.getBoundingClientRect()
    this.setAttribute('style', `height: ${thumbnail.width}px`)
  })
})