import { register } from '../brandless/ujs';
import * as d3 from 'd3';

register('[data-user-retention-all-metrics-chart]', ({ element }) => {
  const data = JSON.parse(element.dataset.chartData) as Array<{
    date: string;
    [Metric: string]: string | number;
  }>;
  const metrics = JSON.parse(element.dataset.chartMetrics);
  const filters = JSON.parse(element.dataset.chartFilters);

  // set the dimensions and margins of the graph
  const margin = { top: 50, right: 10, bottom: 100, left: 50 };
  const width = 800 - margin.left - margin.right;
  const height = 500 - margin.top - margin.bottom;

  let dateFormat;

  switch (filters['timeframe']) {
    case 'month':
      dateFormat = d3.timeFormat('%Y-%m');
      break;
    case 'year':
      dateFormat = d3.timeFormat('%Y');
      break;
    default:
      dateFormat = d3.timeFormat('%Y-%m-%d');
  }

  const parseTime = d3.timeParse(dateFormat);

  const metricColors = d3
    .scaleOrdinal<any>()
    .domain(metrics)
    .range(d3.schemeSet2);

  const svg = d3
    .select(element)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);

  // prepare tooltip
  const tooltip = d3
    .select(element)
    .append('div')
    .attr('class', 'p-2 z-0 bg-black text-white')
    .style('position', 'absolute')
    .style('opacity', 0);

  // X-Axis
  const dlxs = d3
    .scaleTime()
    .domain(
      d3.extent(data, function (d) {
        return parseTime(d.date);
      })
    )
    .range([0, width]);

  svg
    .append('g')
    .attr('transform', `translate(0,${height})`)
    .attr('fill', 'none')
    .attr('font-size', 10)
    .attr('font-family', 'sans-serif')
    .attr('text-anchor', 'end')
    .call(d3.axisBottom(dlxs).tickFormat(dateFormat))
    .selectAll('text')
    .style('text-anchor', 'end')
    .attr('dx', '-1em')
    .attr('dy', '-0.7em')
    .attr('transform', 'rotate(-90)');

  // X-Axis label
  svg
    .append('text')
    .attr('text-anchor', 'end')
    .attr('x', width / 2)
    .attr('y', height + 80)
    .text('Date');

  // Y-Axis
  const dlys = d3
    .scaleLinear()
    .domain(
      d3.extent<any>(
        data.flatMap(function (d) {
          return [
            Number(d['active_count']),
            Number(d['retained_count']),
            Number(d['infinitely_retained_count']),
          ];
        })
      )
    )
    .range([height, 0]);
  svg
    .append('g')
    .attr('fill', 'none')
    .attr('font-size', 10)
    .attr('font-family', 'sans-serif')
    .attr('text-anchor', 'end')
    .call(d3.axisLeft(dlys));

  metrics.forEach(function (metric) {
    // Lines
    const countLine = svg
      .append('g')
      .append('path')
      .datum(data)
      .attr(
        'd',
        d3
          .line<typeof data[number]>()
          .x(function (d) {
            return dlxs(parseTime(d.date));
          })
          .y(function (d) {
            return dlys(Number(d[metric]));
          })
          .curve(d3.curveMonotoneX)
      )
      .style('stroke', metricColors(metric))
      .style('stroke-width', 2)
      .style('fill', 'none');

    // Data points
    const countCircles = svg
      .append('g')
      .selectAll('.dot')
      .attr('class', 'top-stat')
      .data(data)
      .enter()
      .append('circle') // Uses the enter().append() method
      .attr('stroke', metricColors(metric))
      .attr('cx', function (d) {
        return dlxs(parseTime(d.date));
      })
      .attr('cy', function (d) {
        return dlys(Number(d[metric]));
      })
      .attr('r', 4)
      .on('mouseover', function (d) {
        d3.select(this).attr('r', 5);

        tooltip
          .style('opacity', 1)
          .style('left', d3.event.pageX - 60 + 'px')
          .style('top', d3.event.pageY + 'px');
        tooltip
          .append('p')
          .attr('class', 'text-sm')
          .text(`${metric}: ${d[metric]}`)
          .append('p')
          .attr('class', 'text-sm')
          .text(`Date: ${d.date}`);
      })
      .on('mouseleave', function () {
        d3.select(this).style('opacity', 1);
        d3.select(this).attr('r', 4);

        tooltip.style('opacity', 0);
        tooltip.text('');
        tooltip.style('left', 0).style('top', 0);
      });
  });
});
