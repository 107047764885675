import onmount from 'onmount'
import $ from 'jquery'

onmount<HTMLTextAreaElement>('.js-video-comment-body textarea', function() {
  const $form = $('.js-video-comment')
  const $textarea = $(this)
  const $button = $form.find('button[type="submit"]')

  $($button).attr('disabled', 'true')
  $textarea.val('')

  $textarea.on('input', function(e) {
    if (e.target.value.trim().length > 0) {
      $button.attr('disabled', 'false')
    } else {
      $button.attr('disabled','true')
    }
  })
})
