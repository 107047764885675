import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    const meta = document.querySelector('meta[name=csrf-token]');
    const csrfToken = meta.getAttribute('content');

    $.ajax({
      url: this.data.get('viewers-url'),
      method: 'POST',
      dataType: 'html',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', csrfToken);
      },
      success: () => {
        this.element.setAttribute('data-current-viewer-registered', true);
      },
    });
  }

  disconnect() {
    const meta = document.querySelector('meta[name=csrf-token]');
    const csrfToken = meta.getAttribute('content');

    $.ajax({
      url: this.data.get('viewers-url'),
      method: 'PATCH',
      dataType: 'html',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', csrfToken);
      },
    });
  }
}
