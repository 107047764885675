import { Context, Controller } from '@hotwired/stimulus';

export default class FilePreviewController extends Controller {
  public static targets = ['label', 'input'];

  private declare labelTarget: HTMLLabelElement;
  private declare inputTarget: HTMLInputElement;

  private objectUrl: string | undefined;
  private emptyLabel: Node | undefined;
  private form: HTMLFormElement | undefined;

  constructor(context: Context) {
    super(context);

    this.onFileChanged = this.onFileChanged.bind(this);
    this.onFileDropped = this.onFileDropped.bind(this);
    this.onFileDragOver = this.onFileDragOver.bind(this);
  }

  public connect(): void {
    this.form = this.element.closest('form')!;

    this.inputTarget.addEventListener('change', this.onFileChanged);
    this.labelTarget.addEventListener('drop', this.onFileDropped);
    this.form.addEventListener('drop', this.onFileDropped);
    this.form.addEventListener('dragover', this.onFileDragOver);

    this.emptyLabel = this.labelTarget.cloneNode(true);
  }

  public disconnect(): void {
    this.inputTarget.removeEventListener('change', this.onFileChanged);
    this.labelTarget.removeEventListener('drop', this.onFileDropped);
    this.form!.removeEventListener('drop', this.onFileDropped);
    this.form!.removeEventListener('dragover', this.onFileDragOver);
  }

  public clear(): void {
    // Move the input
    this.element.appendChild(this.inputTarget);

    while (this.labelTarget.lastElementChild) {
      this.labelTarget.removeChild(this.labelTarget.lastElementChild);
    }

    const newLabel = this.emptyLabel!.cloneNode(true);

    while (newLabel.lastChild) {
      this.labelTarget.prepend(newLabel.lastChild);
    }

    // Replace the input with the already initialized one
    this.labelTarget
      .querySelector('input[type="file"]')
      ?.replaceWith(this.inputTarget);
  }

  public fill(): void {
    if (!this.objectUrl) {
      return;
    }

    // Move the input
    this.element.appendChild(this.inputTarget);

    // Clear label
    while (this.labelTarget.lastElementChild) {
      this.labelTarget.removeChild(this.labelTarget.lastElementChild);
    }

    const image = new Image();
    image.src = this.objectUrl;
    image.classList.add(
      'w-full',
      'h-full',
      'object-center',
      'object-cover',
      'hover:opacity-75',
      'group-hover:opacity-75'
    );

    this.labelTarget.append(image, this.inputTarget);
  }

  private onFileChanged(): void {
    if (!this.inputTarget || !this.inputTarget.files) {
      return;
    }

    const file = this.inputTarget.files[0];
    this.clear();

    if (!file) {
      return;
    }

    if (this.objectUrl) {
      URL.revokeObjectURL(this.objectUrl);
    }

    this.objectUrl = URL.createObjectURL(file);

    this.fill();
  }

  private onFileDropped(event: DragEvent): void {
    if (!event.dataTransfer) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.inputTarget.files = event.dataTransfer.files;
    this.onFileChanged();
  }

  private onFileDragOver(event: DragEvent): void {
    event.preventDefault();
  }
}
