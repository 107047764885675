import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['chapter', 'chapters'];

  addChapter() {
    const template = this.element.querySelector('template').content;
    const fields = template.querySelector('[data-chapter-fields]');
    const newChapterFields = fields.cloneNode(true);

    this.chaptersTarget.appendChild(newChapterFields);
  }

  removeChapter(event) {
    const button = event.currentTarget;
    const chapter = button.parentNode;

    chapter.remove();
  }
}
