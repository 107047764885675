import { toggleHidden } from '../brandless/utils';
import { CustomSelect } from '../brandless/components/CustomSelect';
import { register, upgradeAll } from '../brandless/ujs';
import { fetch } from '../brandless/fetch';

register('[data-custom-select="default"]', ({ element }) => {
  new CustomSelect(element);
});

register('[data-toggle-mode]', ({ element }) => {
  const component = element.querySelector(
    '[data-custom-select="toggle_mode"]'
  ) as HTMLElement;

  new CustomSelect(component, {
    onChange: (name, value): void => {
      const modes = element.querySelectorAll('[data-mode]');

      modes.forEach((mode) => {
        if (mode.getAttribute('data-mode') === value) {
          mode.removeAttribute('hidden');
        } else {
          mode.setAttribute('hidden', '');
        }
      });
    },
  });
});

const encodeParams = (p: Record<string, unknown>): string =>
  Object.entries(p)
    .map((kv) => kv.map(encodeURIComponent).join('='))
    .join('&');

register('[data-select-institution]', ({ element }) => {
  const component = element.querySelector(
    '[data-custom-select="Institution"]'
  ) as HTMLElement;
  const selectCountryComponent = element.querySelector(
    '[data-select-country]'
  ) as HTMLElement;

  new CustomSelect(component, {
    onChange: (_name, _value): void => {
      toggleHidden(selectCountryComponent, true);
    },
    onCreate: (_name, _value): void => {
      toggleHidden(selectCountryComponent, false);
    },
  });
});

const addSpecialization = (value: string, element: HTMLElement): void => {
  const url = element.getAttribute('data-specializations-url');
  const params = {
    role_id: value,
  };

  fetch(`${url}?${encodeParams(params)}`, {
    headers: {
      accept: 'text/html',
    },
  })
    .then((response: Response) => {
      if (response.ok) {
        return response.text();
      }
    })
    .then((html: string) => {
      const specialtyField = document.querySelector(
        '[data-select-specialization]'
      );

      if (html && !specialtyField) {
        element.insertAdjacentHTML('afterend', html);
        upgradeAll();
      } else if (!html) {
        specialtyField?.remove();
      }
    })
    .catch(() => {
      console.error(`Error validating if Specialty is required.`);
    });
};

const addExperience = (value: string, element: HTMLElement): void => {
  const url = element.getAttribute('data-experience-url');
  const params = {
    role_id: value,
  };

  fetch(`${url}?${encodeParams(params)}`, {
    headers: {
      accept: 'text/html',
    },
  })
    .then((response: Response) => {
      if (response.ok) {
        return response.text();
      }
    })
    .then((html: string) => {
      const experiencesField = document.querySelector(
        '[data-select-experience]'
      );

      const lastField = document.querySelector('.check-form');

      if (experiencesField && !html) {
        experiencesField.remove();
      } else if (!experiencesField && html) {
        lastField.insertAdjacentHTML('beforebegin', html);
        upgradeAll();
      }
    })
    .catch((error) => {
      console.error('Error validating if Years of Experience is required');
      console.error(error);
    });
};

const userDropdowns = [
  {
    target: '[data-select-role]',
    selectName: 'Role',
    subCategoryTargets: ['[data-select-occupation]'],
    onValueUpdate: (value: string, element: HTMLElement): void => {
      addSpecialization(value, element);
      addExperience(value, element);
    },
  },
  {
    target: '[data-select-specialization]',
    selectName: 'Specialization',
    subCategoryTargets: ['[data-select-subspecialization]'],
    onValueUpdate: (): null => null,
  },
];

userDropdowns.forEach(
  ({ target, selectName, subCategoryTargets, onValueUpdate }) => {
    register(target, ({ element }) => {
      const component = element.querySelector<HTMLElement>(
        `[data-custom-select="${selectName}"]`
      )!;
      const fetchUrl = element.getAttribute('data-url');

      if (!fetchUrl) {
        return;
      }

      new CustomSelect(component, {
        onChange: (_name, value, _input): void => {
          // Check if it requires a subcategory
          fetch(`${fetchUrl}/${value}`, {
            headers: {
              accept: 'text/html',
            },
          })
            .then((response: Response) => {
              if (response.ok) {
                return response.text();
              }
            })
            .then((html: string) => {
              const subCategoriesFields = Array.prototype.map
                .call(subCategoryTargets, (target) =>
                  element.querySelector(target)
                )
                .filter((target) => target);

              if (subCategoriesFields.length) {
                subCategoriesFields.forEach((field) => field.remove());
              }

              if (html) {
                element.insertAdjacentHTML('beforeend', html);
                upgradeAll();
              }
            })
            .catch((error: Error) => {
              console.error(`Error on '${selectName}' dropdown!`);
            });

          // Optional function
          onValueUpdate(value, element);
        },
      });
    });
  }
);
