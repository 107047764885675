import { emitter } from '../emitter';
import { PageResponse, fetchPage } from '../infinitable';
import { upgradeAll } from '../ujs';

export class RecommendationsFeedItem {
  private readonly abortController: AbortController;
  private readonly target: HTMLElement;

  private items: Node[];
  private promise: undefined | Promise<void>;
  private destroyed = false;

  private timeouts: Record<string, number> = {};

  constructor(private readonly component: HTMLElement) {
    this.target = this.component.querySelector<HTMLElement>(
      '[data-target="replace"]'
    )!;

    this.destroy = this.destroy.bind(this);
    this.replace = this.replace.bind(this);

    this.onLoadMore = this.onLoadMore.bind(this);
    this.onLoadError = this.onLoadError.bind(this);
    this.onLoaded = this.onLoaded.bind(this);
    this.onDismissItem = this.onDismissItem.bind(this);
    this.onReplaceItem = this.onReplaceItem.bind(this);

    component.addEventListener('click', this.onDismissItem);

    this.abortController = new AbortController();
    this.items = [];

    emitter.on('*', this.onReplaceItem);
  }

  public destroy(): void {
    this.abortController.abort();
    this.destroyed = true;

    Object.keys(this.timeouts).forEach((key) => {
      clearTimeout(this.timeouts[key]);
      delete this.timeouts[key];
    });

    this.component
      .querySelectorAll('[data-replace-on].replacing')
      .forEach((element) => element.classList.remove('replacing'));
  }

  private get href(): string | null | undefined {
    return this.component.getAttribute('data-more-href');
  }

  private set href(next: string | null | undefined) {
    if (!next) {
      this.component.removeAttribute('data-more-href');
      return;
    }

    this.component.setAttribute('data-more-href', next);
  }

  private get accept(): string {
    return this.component.getAttribute('data-more-accept')!;
  }

  private get method(): string {
    return this.component.getAttribute('data-more-method') || 'get';
  }

  private get replaceTimeout(): number {
    return 1000 * 3;
  }

  private onDismissItem(e: Event): void {
    const action =
      e.target instanceof Element &&
      e.target.closest('[data-action~="dismiss"]');
    if (!action) {
      return;
    }

    const boundary = action.closest('[data-replaceable]');
    if (!boundary) {
      return;
    }

    this.replace(boundary).catch(this.onLoadError);
  }

  private onReplaceItem(type: string | symbol, event?: unknown): void {
    if (typeof event !== 'object' || !event || !('id' in event)) {
      return;
    }
    const id = (event as { id: string }).id;

    const boundary = this.component.querySelector(
      `[data-replace-on="${type.toString()}"][data-replace-id="${id}"]`
    );

    if (!boundary) {
      return;
    }

    const key = `${type.toString()}/${id}`;

    // Remove older timeout
    if (this.timeouts[key]) {
      boundary.classList.remove('replacing');

      clearTimeout(this.timeouts[key]);
      delete this.timeouts[key];
    }

    if ('state' in event && !(event as { state: boolean }).state) {
      return;
    }

    boundary.classList.add('replacing');

    this.timeouts[key] = setTimeout(() => {
      if (this.destroyed) {
        return;
      }

      this.replace(boundary).catch(this.onLoadError);
    }, this.replaceTimeout);
  }

  private onLoadMore(e: Event): void {
    e.preventDefault();

    // Disable data-remote, or anything else
    e.stopPropagation();

    this.loadMore();
  }

  private onLoadError(error: Error): void {
    console.error('[infinite] something went wrong', error);
    this.promise = undefined;
  }

  private onLoaded({ page: { content, _links } }: PageResponse): void {
    console.debug(`[infinite] received content ${_links?.self?.href || ''}`);
    this.promise = undefined;

    // The following is a security risk with user-generated content. It is the
    // server's responsibility to correctly escape any user-generated content.
    const hydrator = document.createElement('div');
    hydrator.innerHTML = content;

    while (hydrator.lastElementChild) {
      const id = hydrator.lastElementChild.id;
      hydrator.lastElementChild.remove();

      if (id && document.getElementById(id)) {
        console.warn(`[infinite] received duplicate item (id: ${id})`);
        continue;
      }

      this.items.push(hydrator.lastElementChild);
    }

    this.href = _links?.next?.href;
  }

  public async replace(element: Element): Promise<void> {
    const placeholder = element.cloneNode(false) as HTMLElement;
    element.replaceWith(placeholder);

    // Already fetching
    if (this.promise) {
      await this.promise;
    }

    // Nothing left to replace with
    if (this.items.length === 0) {
      await this.loadMore();
    }

    // Zero items retrieved
    const item = this.items.shift();
    if (!item) {
      placeholder.remove();
      return;
    }

    // Check if already exists
    if (item instanceof HTMLElement && item.hasAttribute('data-replace-on')) {
      const key = ['data-replace-on', 'data-replace-id']
        .map((attribute) => `[${attribute}="${item.getAttribute(attribute)}"]`)
        .join('');

      if (this.component.querySelector(key)) {
        placeholder.replaceWith(element);
        this.replace(element);
        return;
      }
    }

    placeholder.replaceWith(item);
    upgradeAll();
  }

  public loadMore(): Promise<void> {
    if (this.promise) {
      return this.promise;
    }

    const href = this.href;
    if (!href) {
      return Promise.resolve();
    }

    this.promise = fetchPage(
      href,
      this.method,
      this.accept,
      this.abortController.signal
    )
      .then(this.onLoaded)
      .catch(this.onLoadError);

    return this.promise;
  }
}
