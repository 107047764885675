import { register, upgradeAll } from '../brandless/ujs';
import { fetch } from '../brandless/fetch';

register('[data-topic-video-rail]', ({ element }) => {
  const { topicReachoraId } = element.dataset;

  if (!topicReachoraId) {
    return;
  }

  const csrfToken = document.querySelector<HTMLMetaElement>(
    "meta[name='csrf-token']"
  ).content;

  fetch(`/topics/${topicReachoraId}/videos`, {
    method: 'GET',
    headers: {
      'X-CSRF-TOKEN': csrfToken,
      'Content-Type': 'application/json',
      accept: 'text/html',
    },
  })
    .then((result) => {
      if (result.ok) {
        return result.text();
      }
    })
    .then((data) => {
      if (data) {
        element.insertAdjacentHTML('beforeend', data);
        upgradeAll();
      }
    })
    .catch((error) => {
      console.error('Error fetching html', error);
    });
});
