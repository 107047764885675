import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['privacyPolicyBody'];

  connect() {
    document.body.style.overflow = 'hidden';
  }

  accept() {
    // Make sure they aren't also being prompted to accept the Terms of Service:
    if (this.privacyPolicyBodyTarget.parentNode.childElementCount === 1) {
      this.element.parentNode.parentNode.classList.remove('active');
      document.body.removeAttribute('style');
    }
    this.element.remove();
  }
}
