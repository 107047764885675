import { Controller } from '@hotwired/stimulus';
import { v4 as uuidv4 } from 'uuid';

export default class extends Controller {
  // This controller is for tracking Facebook events that have no 1:1 backend counterpart.
  // For most Facebook events you will:
  // 1. Create a Stimulus controller that inherits from UuidController;
  //    this will submit the event to Facebook Pixel.
  // 3. Add a backend call to ApplicationController#submit_event_to_fb_conversions_api;
  //    this will submit the event to the FB Conversions API.

  static values = {
    name: String,
  };

  send(event) {
    // Create a fresh UUID for each occurrence of the event :
    const uuid = uuidv4();

    if (typeof window.fbq === 'undefined') {
      console.debug('window.fbq is undefined');
      return;
    }

    window.fbq('trackCustom', this.nameValue, {}, { eventID: uuid });
    console.debug('FBQ :  Custom event triggered : ' + this.nameValue);
    console.debug('FBQ : window.fbq : ' + window.fbq);
    console.debug('FBQ : window.fbq?.loaded : ' + window.fbq?.loaded);

    const postBody = {
      event: this.nameValue,
      uuid: uuid,
    };

    fetch('/fb_conversions_api/event', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        console.debug('Facebook event logged.');
      })
      .catch((error) => console.debug('Failed to log Facebook event.', error));
  }
}
