import { KEY_NAMES, KEY_NAMES_LEGACY } from './aria';
import { register, upgrade } from './ujs';
import { toggleHidden } from './utils';

/**
 * Makes an element marked with [data-action="copy"] write to the clipboard
 *
 * Sometimes, content should be copyable, but by more means than just a keyboard
 * combination (such as CTRL + C or CMD + C), and perhaps not visible content.
 *
 * Copyable content expects the element to have the following:
 *
 * - data-action: 'copy'
 * - data-copy: 'content to copy'
 *
 * The element copies the [data-copy] content to the clipboard on 'click', or
 * when the element has focus and one of the following is pressed:
 *
 * - CTRL + C
 * - CMD + C
 * - Enter
 * - Space
 *
 * It is recommended to use buttons, unless you're making the copyable content a
 * URL, in which case an anchor element is preferred, so that right click still
 * yields a context menu to copy the link.
 *
 * @example
 *
 * <a href="/posts/1" data-copy="https://domain.tld/posts/1" data-action="copy">
 *   Copy this post url
 * </a>
 */
export class Copyable {
  constructor(private readonly component: HTMLElement) {
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.destroy = this.destroy.bind(this);

    // Hide element if clipboard isn't supported
    if (!('clipboard' in navigator)) {
      toggleHidden(this.component, true);

      const role = this.component.parentElement!.getAttribute('role');
      // If wrapped by an element that has no role (only to satisfy the semantic
      // HTML spec, eg in menu-lists), it should just be removed all together.
      if (role === 'none' || role === 'presentation') {
        this.component.parentElement!.remove();
      }
    }

    this.component.addEventListener('click', this.onCopy);
    this.component.addEventListener('focus', this.onFocus);
    this.component.addEventListener('blur', this.onBlur);
  }

  public destroy(): void {
    this.component.removeEventListener('click', this.onCopy);
    this.component.removeEventListener('focus', this.onFocus);
    this.component.removeEventListener('blur', this.onBlur);
  }

  public get content(): string {
    return this.component.getAttribute('data-copy') || '';
  }

  public copy(): void {
    /**
     * Originally clipboard.js was used to accomplish this behaviour. Under the
     * hood, that uses execCommand('copy'). This uses the newer Clipboard API,
     * which is supported in all browsers except for IE.
     *
     * Browser support for execCommand is great, but its implementation is
     * shady. It's necessary to create a fake textarea, put the copyable text
     * inside, select the contents and execute this command.
     *
     * MDN markes execCommand as obsolete, and copy as deprecated and "not for
     * new websites".
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/document/execCommand#Commands
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Clipboard
     */
    navigator.clipboard.writeText(this.content);
  }

  private onFocus(): void {
    document.addEventListener('keydown', this.onKeyPress);
  }

  private onBlur(): void {
    document.removeEventListener('keydown', this.onKeyPress);
  }

  private onCopy(e: Event): void {
    e.preventDefault();
    this.copy();
  }

  private onKeyPress(e: KeyboardEvent): void {
    if ((e.code === 'KeyC' || e.key === 'c') && (e.ctrlKey || e.metaKey)) {
      this.onCopy(e);
      return;
    }

    if (e.key === KEY_NAMES_LEGACY.SPACE || e.key === KEY_NAMES.SPACE) {
      e.preventDefault();
      this.component.click();
      return;
    }
  }
}

export function copyables(): void {
  upgrade('[data-action="copy"]');
}

export function copyable(component: HTMLElement): Copyable {
  return new Copyable(component);
}

register('[data-action="copy"]', ({ element }) => copyable(element).destroy);
