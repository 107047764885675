import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  timeUpdate(e) {
    e.preventDefault();

    const videoPage = document.getElementById('video-page');
    const video = videoPage.querySelector('video');
    const iframe = videoPage.querySelector('iframe');
    const duration = Number(this.element.dataset.seconds);

    if (!duration) {
      return;
    }

    // Old player
    if (iframe) {
      iframe.contentWindow.postMessage(
        {
          source: 'reachora_player',
          event_type: 'timeskip',
          duration: duration,
        },
        'https://in.reachora.io'
      );
      // New player
    } else if (video) {
      video.currentTime = duration;
    } else {
      console.debug('Video not available');
    }
  }
}
