import { register } from '../brandless/ujs';
import * as d3 from 'd3';

register('[data-visual-report]', ({ element }) => {
  const frequency = element.dataset.frequency as 'daily' | 'monthly' | 'weekly';
  const data = JSON.parse(element.dataset.json) as Array<{
    date: string;
    count: number;
  }>;

  let dateFormat: string;

  switch (frequency.toLowerCase()) {
    case 'daily': {
      dateFormat = '%Y-%m-%d';
      break;
    }

    case 'monthly': {
      dateFormat = '%Y-%m';
      break;
    }

    case 'weekly': {
      dateFormat = '%G-%V';
      break;
    }
  }

  const parseTime = d3.timeParse(dateFormat);
  const mappedData = data.map((d) => ({
    date: parseTime(d.date),
    count: +d.count,
  }));

  // createChart(data, element);
  interactiveChart(mappedData, element, dateFormat);
});

function interactiveChart(
  data: Array<{ date: Date; count: number }>,
  element: HTMLElement,
  format: string
): void {
  // set the dimensions and margins of the graph
  const margin = { top: 50, right: 10, bottom: 100, left: 50 };
  const width = 1200 - margin.left - margin.right;
  const height = 600 - margin.top - margin.bottom;

  d3.select(element).select('svg').remove();
  const svg = d3
    .select(element)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .attr(
      'viewBox',
      `0 0 ${width + margin.left + margin.right} ${
        height + margin.top + margin.bottom
      }`
    )
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);

  // prepare tooltip
  const tooltip = d3
    .select(element)
    .append('div')
    .attr('class', 'p-2 z-0 bg-black text-white bg-opacity-80 rounded')
    .style('position', 'absolute')
    .style('opacity', 0);

  // X-Axis
  const dlxs = d3
    .scaleTime()
    .domain(
      d3.extent(data, function (d) {
        return d.date;
      })
    )
    .range([0, width]);

  svg
    .append('g')
    .attr('transform', `translate(0,${height})`)
    .attr('fill', 'none')
    .attr('font-size', 10)
    .attr('font-family', 'sans-serif')
    .attr('text-anchor', 'end')
    .call(d3.axisBottom(dlxs).tickFormat(d3.timeFormat(format)))
    .selectAll('text')
    .style('text-anchor', 'end')
    .attr('dx', '-1em')
    .attr('dy', '-0.7em')
    .attr('transform', 'rotate(-90)');

  // X-Axis label
  svg
    .append('text')
    .attr('text-anchor', 'end')
    .attr('x', width / 2)
    .attr('y', height + 80)
    .text('Date');

  // Y-Axis
  const dlys = d3
    .scaleLinear()
    .domain(
      d3.extent(data, function (d) {
        return d.count;
      })
    )
    .range([height, 0]);
  svg
    .append('g')
    .attr('fill', 'none')
    .attr('font-size', 10)
    .attr('font-family', 'sans-serif')
    .attr('text-anchor', 'end')
    .call(d3.axisLeft(dlys));

  // Lines
  const lineFactory = d3
    .line<typeof data[number]>()
    .x(function (d) {
      return dlxs(d.date);
    })
    .y(function (d) {
      return dlys(d.count);
    })
    .curve(d3.curveMonotoneX);

  const countLine = svg
    .append('g')
    .append('path')
    .datum(data)
    .attr('d', lineFactory)
    .style('stroke', '#095fd8')
    .style('stroke-width', 2)
    .style('fill', 'none');

  // Data points
  const countCircles = svg
    .append('g')
    .selectAll('.dot')
    .attr('class', 'top-stat')
    .data(data)
    .enter()
    .append('circle') // Uses the enter().append() method
    .attr('stroke', '#095fd8')
    .attr('stroke-width', 2)
    .attr('fill', '#ffffff')
    .attr('cx', function (d) {
      return dlxs(d.date);
    })
    .attr('cy', function (d) {
      return dlys(d.count);
    })
    .attr('r', 4)
    .on('mouseover', function (d) {
      d3.select(this).attr('r', 6);
      d3.select(this).attr('stroke-width', 3);

      tooltip
        .style('opacity', 1)
        .style('left', d3.event.pageX - 60 + 'px')
        .style('top', d3.event.pageY + 'px');
      tooltip
        .append('p')
        .attr('class', 'text-sm')
        .text(`Count: ${d.count}`)
        .append('p')
        .attr('class', 'text-sm')
        .text(`Date: ${d3.timeFormat(format)(d.date)}`);
    })
    .on('mouseleave', function () {
      d3.select(this).style('opacity', 1);
      d3.select(this).attr('r', 4);
      d3.select(this).attr('stroke-width', 2);

      tooltip.style('opacity', 0);
      tooltip.text('');
      tooltip.style('left', 0).style('top', 0);
    });
}
