import { Dialog } from '../dialog';

/**
 * Dialog that executes its primary action on escape, cancel, backdrop.
 */
export class SingleActionDialog extends Dialog {
  protected readonly actionsConfirm: NodeListOf<Element>;
  protected readonly actionsClose: NodeListOf<Element>;

  constructor(dialogId: string, public onConfirm: () => void) {
    super(dialogId);

    this.actionsConfirm = this.dialogNode.querySelectorAll(
      '[data-action="confirm"]'
    )!;
    this.actionsClose = this.dialogNode.querySelectorAll(
      '[data-action="close"]'
    )!;

    this.confirm = this.confirm.bind(this);

    this.actionsConfirm.forEach((action) =>
      action.addEventListener('click', this.confirm)
    );
    this.actionsClose.forEach((action) =>
      action.addEventListener('click', this.cancel)
    );
  }

  public destroy(): void {
    console.debug('[confirm] destroy');

    this.actionsConfirm.forEach((action) =>
      action.removeEventListener('click', this.confirm)
    );
    this.actionsClose.forEach((action) =>
      action.removeEventListener('click', this.confirm)
    );

    super.destroy();
  }

  public confirm(event?: Event): void {
    console.debug('[confirm] confirm');
    this.onConfirm();
    this.close(event);
  }

  public cancel(event?: Event): void {
    console.debug('[confirm] cancel');
    this.confirm(event);
  }

  public close(
    event?: Event,
    _replaced: boolean = false,
    _destroyed: boolean = false
  ): boolean {
    console.debug('[confirm] close');

    if (super.close(event, _replaced, _destroyed)) {
      // Confirmation dialogs are throwaway, so this can clean-up itself
      this.destroy();
      return true;
    }

    return false;
  }
}
