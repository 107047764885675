import onmount from 'onmount'
import { ajax } from 'jquery'

onmount<HTMLElement>('.js-load-more-followers', function() {
  const meta: HTMLMetaElement = document.querySelector('meta[name=csrf-token]')
  const csrfToken = meta.getAttribute('content') as any

  const recommendations = this.querySelector('.js-recommendations')

  const loadMoreBtn = recommendations.querySelector<HTMLButtonElement>('.js-load-more')
  const url = loadMoreBtn.getAttribute('href')
  const isLazyLoading = loadMoreBtn.hasAttribute('data-lazy-load')
  const length = parseInt(loadMoreBtn.dataset.length)

  const userList = recommendations.querySelector<HTMLElement>('.js-list')
  const userId = userList.getAttribute('data-user-id')

  let pageNumber = 2 // Initial request is always 2
  let isEndOfList = false
  let canSendRequest = true

  const ajaxRequest = () => {

    $.ajax({
      url: url,
      type: 'GET',
      data: {
        page: pageNumber,
        id: userId
      },
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', csrfToken)
        loadMoreBtn.innerHTML = 'Loading...'
        canSendRequest = false
      },
      success: function(response) {
        userList.insertAdjacentHTML('beforeend', response)
        onmount()
        pageNumber += 1
        loadMoreBtn.innerHTML = window.innerWidth < 768 ? 'Scroll to load more' : 'Load more'
        const cardsLength = userList.querySelectorAll('.card').length
        if (cardsLength === length) {
          loadMoreBtn.innerHTML = 'End of list'
          loadMoreBtn.classList.add('-disabled')
          loadMoreBtn.disabled = true
          isEndOfList = true
          loadMoreBtn.remove()
          canSendRequest = false
        } else {
          canSendRequest = true
        }
      },
      error: function(_error) {
        console.log('Error loading list')
        loadMoreBtn.innerHTML = 'Error loading followers'
      }
    })
  }

  if ((window.innerWidth < 768) && isLazyLoading) {
    loadMoreBtn.innerHTML = 'Scroll to load more'
    window.addEventListener('scroll', (e) => {
      const btnTop = loadMoreBtn.getBoundingClientRect().top
      const btnHeight = loadMoreBtn.getBoundingClientRect().height
      const bottomPosition = btnTop + btnHeight + 32 // 32px - Bottom padding of container

      if ((bottomPosition <= window.innerHeight) && canSendRequest) {
        ajaxRequest()
      }
    })
  } else {
    loadMoreBtn.addEventListener('click', () => {
      if (isEndOfList) {
        return
      }
      ajaxRequest()
    })
  }
})
