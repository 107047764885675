import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    useIntersection(this, {
      threshold: 1.0,
    });
  }

  appear() {
    this.markMessagesAsRead();
    this.element.remove();
  }

  markMessagesAsRead() {
    const meta = document.querySelector('meta[name=csrf-token]');
    const csrfToken = meta.getAttribute('content');
    const controller = this;
    $.ajax({
      url: `/messaging/messages/mark_all_as_read`,
      data: {
        conversation_id: controller.conversationId,
      },
      method: 'PATCH',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', csrfToken);
      },
      success: function (response) {
        if (!response) return;

        console.debug(
          `${response.unread_count} message/s on conversation #${controller.conversationId} are marked as read!`
        );
      },
      error: function () {
        console.debug(
          `Error marking messages as read in Conversation#${controller.conversationId}`
        );
      },
    });
  }

  get conversationId() {
    return this.data.get('conversationId');
  }
}
