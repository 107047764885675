// Initialize Functions

import onmount from 'onmount'
import $ from 'jquery'
import Turbolinks from 'turbolinks'
import LocalTime from 'local-time'
import smoothscroll from 'smoothscroll-polyfill'

// Polyfills
import 'mdn-polyfills/Element.prototype.classList';
import 'mdn-polyfills/CustomEvent';
import 'mdn-polyfills/String.prototype.endsWith';
import 'mdn-polyfills/Node.prototype.remove';

// Vendor
import 'imgix.js'

$(document).on('turbolinks:load', function(event) {
  LocalTime.start()

  // Initializers
  onmount();
  (window as any).imgix.init()
  smoothscroll.polyfill()

  // AJAX forms with GET
  $('form[method=get][data-remote=true]').each(function () {
    const $form = $(this)

    $form.on('ajax:beforeSend', function(event) {
      event.preventDefault()
      const detail = event.detail
      Turbolinks.visit(detail[1].url)
    })
  })
})

$(window).on('load', function () {
  if ((window as any).webkit) {
    $(document).on('turbolinks:request-end', function (e) {
      const data = (e.originalEvent as any).data;

      if (!(window as any).webkit.messageHandlers.events) {
        return;
      }

      (window as any).webkit.messageHandlers.events.postMessage(
        JSON.stringify({
          type: 'turbolinks:request-end',
          url: data.url,
          status: data.xhr.status,
        })
      );
    });
  }
});

document.addEventListener('turbolinks:before-cache', () => {
  onmount.teardown();
});

// Folders
import './utils/index'
import './components/index'
import './pages/app'
import './wondr/index'

// Namespace for channels CMS
import './channels-cms/app';

// Service Worker Subscription for Web Push Notifications
// import './web_push'
