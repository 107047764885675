import onmount from 'onmount'

onmount<HTMLFormElement>('.js-avatar-form', function() {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const form = this
  const input = form.querySelector<HTMLInputElement>('.js-image-file')
  const action = form.querySelector<HTMLElement>('.action')
  const image = form.querySelector<HTMLElement>('.avatar')

  input.addEventListener('change', (e) => {
    const imageType = /jpg|jpeg|png|bmp|svg/
    const fileName = (e.target as HTMLInputElement).value.toLowerCase().split('.')
    const fileExtension = fileName[fileName.length - 1]

    if (!fileExtension.match(imageType)) {
      const message = `<p class='error'><strong>Please upload a valid image file.</strong> <br />Supported file formats: bmp, jpeg, jpg, png, svg.</p>`
      action.insertAdjacentHTML('beforeend', message)
      return
    } else {
      const error = action.querySelector('p.error')
      if (error) {
        error.remove()
      }
    }

    if (input.files && input.files[0]) {
      const reader = new FileReader()

      reader.onload = function(e) : void {
        image.style.backgroundImage = `url(${e.target.result})`;
        image.classList.add('avatar--url')
        const svg = image.querySelector('svg')
        if (svg) { svg.remove() }
      }

      reader.readAsDataURL(input.files[0])
    }
  })
})
