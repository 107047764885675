// These are files for Main Rutherford

require('@rails/actioncable');

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';

Rails.start();
Turbolinks.start();

// Main javascript entry point
import '../src/app';

// ActionCable features
import '../src/cable';

// TODO: Update stimulus controllers
import '../controllers/index';

// Utilities
import '../src/brandless/emitter';
import '../src/brandless/spa';
import '../src/brandless/ujs';

// Video Tracking
import '../src/analytics/video-tracking';

// ARIA components
import '../src/brandless/aria';
import '../src/brandless/dialog';
import '../src/brandless/menu';
import 'focus-visible';

// Traits and generic components
import '../src/brandless/bookmarkable';
import '../src/brandless/confirmable';
import '../src/brandless/content-editable';
import '../src/brandless/copyable';
import '../src/brandless/dismissable';
import '../src/brandless/followable';
import '../src/brandless/infinitable';
import '../src/brandless/iterable';
import '../src/brandless/likable';
import '../src/brandless/markable';
// import '../src/brandless/overflowable';

import '../src/brandless/replacable';
import '../src/brandless/toggleable';
import '../src/brandless/tributable';

// Specific components
import '../src/brandless/cookie-banner';
// import '../src/brandless/navigation';
// import '../src/brandless/notifications';
import '../src/brandless/feed';
// import '../src/brandless/post';
import '../src/brandless/rebranding';
import '../src/brandless/search';

// Redesign
// Fixes the inconsistent loading of the video rails
import '../src/redesign/topic_rail';
import '../src/redesign/imgix_loadable';

// TODO: Return to redesign.ts
import '../src/redesign/custom_select';
import '../src/redesign/channel_module_sortable';

import '../src/redesign/visual_report';
import '../src/charts';

// ViewComponent
import '../../components/organisms/dialogs/privacy_and_terms/chunk';
