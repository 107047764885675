import onmount from 'onmount'

onmount<HTMLElement>('.js-nav-tabs', function() {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const container = this
  const isSlidingContent = this.hasAttribute('data-sliding-content')
  const slidingContent = this.querySelector<HTMLElement>('.sliding-content')
  const tabs = this.querySelectorAll('.js-tab')
  const tabContents = this.querySelectorAll<HTMLElement>('[data-tab-content]')

  if (!tabs.length) {
    return
  }

  const initTab = tabs[0].getAttribute('data-target')
  const initContent = this.querySelector(`[data-tab-content="${initTab}"]`)

  const clearActiveTabs = () => {
    tabs.forEach((tab) => {
      tab.classList.remove('-active')
    })
  }

  const clearActiveContent = () => {
    if (!isSlidingContent) {
      tabContents.forEach((content) => {
        content.style.display = 'none'
      })
    }
  }

  tabs[0].classList.add('-active')
  clearActiveContent()

  // tabContents.forEach((content) => console.log(content))

  if (initContent) {
    initContent.removeAttribute('style')
  }

  tabs.forEach((tab) => {
    tab.addEventListener('click', (e) => {
      const target = tab.getAttribute('data-target')
      const content = container.querySelector<HTMLElement>(`[data-tab-content="${target}"]`)
      e.preventDefault()

      clearActiveTabs()
      clearActiveContent()
      tab.classList.add('-active')

      if (content) {
        content.removeAttribute('style')
      }

      if (isSlidingContent) {
        if (content.offsetLeft > 0) {
          slidingContent.style.transform = `translateX(-${content.offsetLeft}px)`
        } else if (content.offsetLeft < 0) {
          slidingContent.style.transform = `translateX(${content.offsetLeft}px)`
        } else {
          slidingContent.style.transform = `translateX(${content.offsetLeft})`
        }
      }

    })
  })
})