import UuidController from '../uuid_controller.js';
import { AutoRemoveDialog } from '../../src/brandless/components/AutoRemoveDialog';
import { upgradeAll } from '../../src/brandless/ujs';

export default class extends UuidController {
  register(event) {
    const uuid = this.getUuid(event);
    if (typeof window.fbq !== 'undefined') {
      window.fbq('trackCustom', 'Registered to Event', {}, { eventID: uuid });
    }
    console.debug('Registered to Event');
    this.updateElement(event);
    this.showConsent(event);
  }

  unregister(event) {
    const uuid = this.getUuid(event);

    if (typeof window.fbq !== 'undefined') {
      window.fbq(
        'trackCustom',
        'Unregistered from Event',
        {},
        { eventID: uuid }
      );
    }

    console.debug('Unregistered from Event');
    this.updateElement(event);
  }

  registerForSeries(event) {
    const uuid = this.getUuid(event);
    if (typeof window.fbq !== 'undefined') {
      window.fbq(
        'trackCustom',
        'Registered for Event Series',
        {},
        { eventID: uuid }
      );
    }
    console.debug('Registered to Event Series');
    this.updateElement(event);
  }

  unregisterFromSeries(event) {
    const uuid = this.getUuid(event);
    if (typeof window.fbq !== 'undefined') {
      window.fbq(
        'trackCustom',
        'Unregistered from Event Series',
        {},
        {
          eventID: uuid,
        }
      );
    }
    console.debug('Unregistered from Event Series');
    this.updateElement(event);
  }

  showConsent(event) {
    const id = event.currentTarget.getAttribute('data-id');
    console.log(id);

    if (!id) {
      return;
    }

    fetch(`/events/${id}/consent`, {
      headers: { accept: 'text/html' },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw response;
        }
      })
      .then((response) => {
        if (response) {
          const portal = document.getElementById('portal');
          portal.insertAdjacentHTML('beforeend', response);
        }
      });
  }
}
