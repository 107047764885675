import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    // Add a bit of delay
    const threshold = this.data.has('threshold')
      ? parseFloat(this.data.get('threshold'))
      : 1.0;
    useIntersection(this, {
      threshold: threshold,
    });
  }

  appear(entry) {
    $.ajax({
      type: 'GET',
      url: this.data.get('src'),
      success: (html) => {
        this.element.outerHTML = html;
      },
      error: (message) => {
        console.log('Frame controller error', message);
      },
    });
  }
}
