import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  toggle(event) {
    if (event.target.closest('.header [data-skip-minimize]')) return;

    if (window.innerWidth < 768) return;

    this.element.classList.toggle('minimized');

    if (!this.element.classList.contains('minimized')) {
      this.hideLiveChat();
    }
  }

  remove(event) {
    this.element.remove();
  }

  hideLiveChat() {
    const liveVideoPage = document.getElementById('video-page');

    if (!liveVideoPage) {
      return;
    }

    liveVideoPage.classList.add('chat-collapsed');

    const messagingContainer = document.querySelector('#messaging-container');
    messagingContainer.style.removeProperty('right');
    messagingContainer.style.removeProperty('width');
  }
}
