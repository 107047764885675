import { Context, Controller } from '@hotwired/stimulus';
import Turbolinks from 'turbolinks';

export default class SpaceSelectController extends Controller {
  public static targets = ['select', 'options', 'currentSpace'];
  public static values = { url: String, current: String };

  private declare optionsTarget: HTMLElement;
  private declare selectTarget: HTMLElement;
  private declare currentSpaceTarget: HTMLElement;
  private declare urlValue: string;
  private declare currentValue: string;

  private liveRegion: HTMLElement | null | undefined;
  private listBox: HTMLElement | null | undefined;
  private loading = false;
  private loaded = false;

  constructor(context: Context) {
    super(context);

    this.onClick = this.onClick.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onPreload = this.onPreload.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onOutsideClick = this.onOutsideClick.bind(this);

    this.toggle = this.toggle.bind(this);
  }

  public get active(): boolean {
    return this.selectTarget.getAttribute('aria-expanded') == 'true';
  }

  public set active(val: boolean) {
    if (val) {
      this.selectTarget.setAttribute('aria-expanded', 'true');
    } else {
      this.selectTarget.removeAttribute('aria-expanded');
    }
  }

  public get selected(): HTMLElement | undefined {
    const item = this.optionsTarget.querySelector('[aria-selected="true"]');
    if (item instanceof HTMLElement) {
      return item;
    }

    return undefined;
  }

  private set activeElement(next: HTMLElement) {
    const option = next.closest('[role="option"]');
    if (!option) {
      return;
    }

    // The aria-activedescendant property provides a method of managing focus
    // for assistive technologies on interactive elements when they contain
    // multiple focusable descendants, such as menus, grids, and toolbars.
    // Instead of the screen reader moving focus between owned elements,
    // aria-activedescendant can be used on container elements to refer to the
    // currently active element, informing assistive technology users of the
    // currently active element when focused.

    // With aria-activedescendant, the browser keeps the DOM focus on the
    // container element or on an input element that controls the container
    // element. However, the user agent communicates desktop focus events and
    // states to the assistive technology as if the element referenced by
    // aria-activedescendant has focus.
    this.selectTarget.setAttribute('aria-activedescendant', option.id);
  }

  public connect(): void {
    this.selectTarget.addEventListener('focus', this.onPreload);
    this.selectTarget.addEventListener('mouseenter', this.onPreload);
    this.selectTarget.addEventListener('click', this.onClick);
    this.selectTarget.addEventListener('keydown', this.onKeyPress);

    this.optionsTarget.addEventListener('keydown', this.onKeyPress);
    this.optionsTarget.addEventListener('focusin', this.onFocusChange);
    this.optionsTarget.addEventListener('change', this.onSelect);
    this.optionsTarget.addEventListener('mouseover', this.onHover);
    this.optionsTarget.addEventListener('click', this.onClick);

    this.liveRegion = this.element.querySelector<HTMLElement>(
      '[aria-live="polite"]'
    );
    this.listBox = this.element.querySelector<HTMLElement>('[role="listbox"]');
  }

  public disconnect(): void {
    this.selectTarget.removeEventListener('focus', this.onPreload);
    this.selectTarget.removeEventListener('mouseenter', this.onPreload);
    this.selectTarget.removeEventListener('click', this.onClick);
    this.selectTarget.removeEventListener('keydown', this.onKeyPress);

    this.optionsTarget.removeEventListener('keydown', this.onKeyPress);
    this.optionsTarget.removeEventListener('focusin', this.onFocusChange);
    this.optionsTarget.removeEventListener('change', this.onSelect);
    this.optionsTarget.removeEventListener('mouseover', this.onHover);
    this.optionsTarget.removeEventListener('click', this.onClick);

    document.removeEventListener('click', this.onOutsideClick);

    this.liveRegion = undefined;
    this.listBox = undefined;
  }

  public toggle(): void {
    if (this.active) {
      this.close();
    } else {
      this.open();
    }
  }

  public open(): void {
    console.debug('[space-select] open');
    this.active = true;

    // First show the box
    this.listBox!.classList.remove('hidden');
    this.listBox!.classList.add('flex');

    // Now make it translucent and apply animation
    this.listBox!.classList.add(
      'opacity-0',
      'transition',
      'ease-out',
      'duration-100'
    );

    requestAnimationFrame(() => {
      if (!this.listBox) {
        return;
      }

      // Animate to visible
      this.listBox.classList.remove('opacity-0');
      this.listBox.classList.add('opacity-100');

      // Move focus
      if (this.loaded) {
        this.focusFirst();
      } else {
        this.listBox.focus();
        this.load();
      }
    });

    document.addEventListener('click', this.onOutsideClick);
  }

  public close(): void {
    console.debug('[space-select] close');
    this.active = false;

    if (this.liveRegion) {
      this.liveRegion.textContent = '';
    }

    if (!this.listBox) {
      return;
    }

    // Prepare the animation
    this.listBox.classList.add(
      'opacity-100',
      'transition',
      'ease-in',
      'duration-100'
    );

    requestAnimationFrame(() => {
      if (!this.listBox) {
        return;
      }

      // Animate to hidden
      this.listBox.classList.remove('opacity-100');
      this.listBox.classList.add('opacity-0');

      setTimeout(() => {
        // If toggle on within 120ms, ignore this
        if (this.active || !this.listBox) {
          return;
        }

        // Finally, hide the box
        this.listBox.classList.add('hidden');
        this.listBox.classList.remove('flex');
      }, 105);
    });

    this.selectTarget.focus();

    document.removeEventListener('click', this.onOutsideClick);
  }

  public focusFirst(): void {
    const first = this.listBox!.querySelector(
      '[aria-selected] input, [aria-selected][tabIndex]'
    );

    if (first instanceof HTMLElement) {
      first.focus();
    }

    console.debug('[space-select] focus first', first);
  }

  public focusLast(): void {
    const items = this.listBox!.querySelectorAll(
      '[aria-selected] input, [aria-selected][tabIndex]'
    );

    const last = items.length > 0 ? items.item(items.length - 1) : undefined;

    if (last instanceof HTMLElement) {
      last.focus();
    }

    console.debug('[space-select] focus last', last);
  }

  private select(element: HTMLInputElement): void {
    const previous = this.optionsTarget.querySelector('[aria-selected="true"]');
    if (previous instanceof HTMLElement) {
      previous.setAttribute('aria-selected', 'false');
    }

    const current = element.closest<HTMLElement>('[aria-selected]')!;
    current.setAttribute('aria-selected', 'true');

    element.checked = true;
    console.debug('[space-select] selected change', current);
  }

  private load(): void {
    if (this.loading || !this.liveRegion) {
      return;
    }

    this.loading = true;
    this.liveRegion.textContent = 'Loading your workspaces';

    const expected = 'text/vnd.wondr.select-links';
    fetch(this.urlValue, {
      headers: {
        accept: `${expected}, application/problem+json; q=0.1`,
      },
    })
      .then((value) => {
        const contentType = value.headers.get('content-type') || '';

        if (contentType.startsWith(expected)) {
          return value.text();
        }

        if (contentType.includes(`variant=${expected}`)) {
          return value.text();
        }

        throw new Error(`Expected ${expected}, actual ${contentType}`);
      })
      .then((options) => {
        if (!this.element) {
          return;
        }

        const content = document.createElement('div');
        content.innerHTML = options;

        // Remove old content
        while (this.optionsTarget.lastElementChild) {
          this.optionsTarget.removeChild(this.optionsTarget.lastElementChild);
        }

        // Add content
        this.listBox = content.firstElementChild as HTMLElement;
        this.optionsTarget.appendChild(this.listBox);

        if (this.liveRegion) {
          this.liveRegion.textContent = `${this.listBox.childElementCount} workspaces found`;
        }

        const selected = this.optionsTarget.querySelector(
          `[data-value="${this.currentValue}"]`
        );

        if (selected) {
          this.select(selected.querySelector('input')!);
        }
      })
      .then(() => {
        this.loaded = true;
        this.loading = false;

        if (!this.listBox) {
          return;
        }

        // Currently hidden
        if (!this.active) {
          this.listBox.classList.add('hidden');
          this.listBox.classList.remove('flex');
        } else {
          this.focusFirst();
        }
      })
      .catch((error) => {
        this.loaded = false;
        this.loading = false;

        console.error(error);
      });
  }

  private onClick(e: MouseEvent): void {
    // CLicking on the top-element
    if (e.currentTarget === this.selectTarget) {
      e.preventDefault();

      this.toggle();

      if (this.active && this.loaded) {
        this.focusFirst();
      }
      return;
    }

    // Clicking on an option
    if (
      e.currentTarget === this.optionsTarget &&
      this.loaded &&
      e.target instanceof HTMLElement
    ) {
      const option = e.target.closest('[role="option"]');
      if (!option) {
        return;
      }

      const input = option.querySelector<HTMLInputElement>(
        '[aria-selected] input, [aria-selected][tabIndex]'
      );

      // Ignore if the keyboard was used to trigger a synthetic click on the
      // input. Thanks browsers...
      if (e.target === input) {
        return;
      }

      this.select(input!);
      this.onSubmit(e);
      return;
    }
  }

  private onSubmit(e: Event): void {
    e.stopImmediatePropagation();
    e.stopPropagation();

    this.close();
    console.debug('[space-select] submit', this.selected, e);

    if (!this.selected) {
      return;
    }

    // Nothing new selected
    const newValue = this.selected.getAttribute('data-value');
    if (this.currentValue === newValue || newValue === null) {
      return;
    }

    this.currentValue = newValue;

    /*
    const replacement = this.selected.querySelector(
      '[data-replacement="currentSpace"]'
    );
    if (replacement) {
      while (this.currentSpaceTarget.lastElementChild) {
        this.currentSpaceTarget.removeChild(
          this.currentSpaceTarget.lastElementChild
        );
      }

      replacement.childNodes.forEach((node) => {
        this.currentSpaceTarget.appendChild(node.cloneNode(true));
      });
    }
    */

    const newUrl = this.selected.querySelector('input')!.value;
    if (!Turbolinks.supported) {
      window.location.href = newUrl;
    } else {
      Turbolinks.visit(newUrl);
    }
  }

  private onOutsideClick(e: MouseEvent): void {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }

    // Click was inside
    if (this.element.contains(e.target)) {
      return;
    }

    // Ignore if already closed
    if (!this.active) {
      return;
    }

    this.close();
  }

  private onKeyPress(e: KeyboardEvent): void {
    if (e.currentTarget === this.selectTarget) {
      switch (e.key) {
        case 'ArrowDown': {
          e.preventDefault();

          this.open();
          this.focusFirst();
          return;
        }

        case 'ArrowUp': {
          e.preventDefault();
          this.open();
          this.focusLast();
        }
      }
      return;
    }

    if (e.currentTarget === this.optionsTarget) {
      if (e.key === 'Escape') {
        e.preventDefault();
        this.close();
        return;
      }

      if (e.key === 'Enter') {
        this.onSubmit(e);
        return;
      }
    }
  }

  private onPreload(): void {
    if (this.loaded) {
      return;
    }

    this.load();
  }

  private onFocusChange(e: FocusEvent): void {
    if (!(e.target instanceof HTMLInputElement)) {
      return;
    }

    const option = e.target.closest('[role="option"]');
    if (!option) {
      return;
    }

    const input = option.querySelector(
      '[aria-selected] input, [aria-selected][tabIndex]'
    );
    if (input instanceof HTMLElement) {
      this.activeElement = input;
    }

    // console.debug('[space-select] focus change', input);
  }

  private onSelect(e: Event): void {
    if (!(e.target instanceof HTMLInputElement)) {
      return;
    }

    this.select(e.target);
  }

  private onHover(e: MouseEvent): void {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }

    const option = e.target.closest('[role="option"]');
    if (!option) {
      return;
    }

    const input = option.querySelector(
      '[aria-selected] input, [aria-selected][tabIndex]'
    );
    if (input instanceof HTMLElement) {
      input.focus();
      this.activeElement = input;
    }
  }
}
