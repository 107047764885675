import onmount from 'onmount'

onmount('.js-new-page-form-wrapper', function(mountEvent) {
  const wrapper                  = $(this)
  const generateSlugApiPath      = '/ch/' + wrapper.attr('data-channel') + '/p/generate_slug.json'
  const checkSlugValidityApiPath = '/ch/' + wrapper.attr('data-channel') + '/p/check_slug_validity.json'
  const form                     = wrapper.find('.js-new-page-form')
  const pageTitle                = wrapper.find('.js-new-page-title')
  const emptyPageTitleError      = wrapper.find('.js-new-page-empty-title-error')
  const slugFormGroup            = wrapper.find('.js-new-page-slug-form-group')
  const slug                     = wrapper.find('.js-new-page-slug')
  const slugHint                 = wrapper.find('.js-new-page-slug-hint')
  const emptySlugError           = wrapper.find('.js-new-page-empty-slug-error')
  const invalidSlugError         = wrapper.find('.js-new-page-invalid-slug-error')
  const submitButton             = wrapper.find('.js-new-page-submit')

  slugHint.addClass('hidden')
  emptySlugError.addClass('hidden')
  invalidSlugError.addClass('hidden')
  emptyPageTitleError.addClass('hidden')
  submitButton.prop('disabled', true)

  const errors = [emptyPageTitleError, emptySlugError, invalidSlugError]
  let slugManuallyUpdated = false

  const doneTypingInterval = 500;
  let titleTypingTimer;
  let slugTypingTimer;

  let previousSlug = '';

  pageTitle.on('keyup', function() {
    clearTimeout(titleTypingTimer);
    titleTypingTimer = setTimeout(function() {
      if(!slugManuallyUpdated && !pageTitle === '') {
        $.post(generateSlugApiPath, { title: pageTitle.val() })
          .done(function(data) {
            slug.val(data.slug)
            tryEnablingSubmit();
          })
          .fail(function() {
            // Fail quietly. The user will have to enter the slug manually.
            slug.val('')
            tryEnablingSubmit();
          })
      } else {
        tryEnablingSubmit();
      }
    }, doneTypingInterval);
  })

  pageTitle.on('keydown', function() {
    clearTimeout(titleTypingTimer);
  })

  slug.on('keypress', function(event) {
    const char = String.fromCharCode(event.keyCode);

    // Char codes below allow, in order: backspace, left arrow key, right arrow key :
    if(char.match(/[^a-z0-9\-\x08\x37\x39]/)) event.preventDefault();

    slugManuallyUpdated = true;
  })

  slug.on('keyup', function() {
    clearTimeout(slugTypingTimer);
    slugTypingTimer = setTimeout(function() {
      tryEnablingSubmit();
    }, doneTypingInterval);
  })

  slug.on('keydown', function() {
    clearTimeout(slugTypingTimer);
  })

  function toggleAndHideOthers(toggleOn, others = []) {
    if(errors.includes(toggleOn)) {
      toggleOn.closest('.form-group').addClass('-error')
    } else {
      toggleOn.closest('.form-group').removeClass('-error')
    }

    for(const element of others) {
      element.addClass('hidden')
    }

    toggleOn.removeClass('hidden')
  }

  function hideAll(elements = []) {
    for(const element of elements) {
      element.closest('.form-group').removeClass('-error')
      element.addClass('hidden')
    }
  }

  function tryEnablingSubmit() {
    submitButton.prop('disabled', true);

    let titleIsGood = false;
    if(pageTitle.val() === '') {
      toggleAndHideOthers(emptyPageTitleError, [])
    } else {
      hideAll([emptyPageTitleError]);
      titleIsGood = true;
    }
    if(slug.val() === '' && previousSlug !== '') {
      toggleAndHideOthers(emptySlugError, [slugHint, invalidSlugError]);
    } else {
      $.post(checkSlugValidityApiPath, { slug: slug.val() })
        .done(function(data, status) {
          if(!data.slug_is_valid) {
            toggleAndHideOthers(invalidSlugError, [slugHint, emptySlugError])
            let list = '<ul>'
            for(const error of data.errors) {
              list += '<li>' + error + '</li>'
            }
            list += '</ul>'
            invalidSlugError.html(list)
          } else {
            toggleAndHideOthers(slugHint, [invalidSlugError, emptySlugError])
            if(titleIsGood) submitButton.prop('disabled', false);
          }
          previousSlug = slug.val()
        })
        .fail(function() {
          // Fail quietly. This will be double-checked on the back end.
          if(titleIsGood) submitButton.prop('disabled', false);
          previousSlug = slug.val()
        })
    }
  }

})
