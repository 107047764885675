import onmount from 'onmount'
import $ from 'jquery'

onmount('.js-validate-form', function() {
  const model = this.getAttribute('data-model')

  $(this).on('ajax:success', (event) => {
    const errors = event.detail[0].error

    if (!errors) {
      return
    }

    Object.keys(errors).forEach((key) => {
      const input = this.querySelector(`[name="${model}[${key}]"]`)
      const parent = input.parentElement

      if (parent.classList.contains('-error') && input.classList.contains('-error')) {
        return
      }

      parent.classList.add('-error')
      input.classList.add('-error')

      if (!parent.hasAttribute('data-disable-error-helper')) {
        const message = `<div class="helper">${errors[key][0]}</div>`
        parent.insertAdjacentHTML('beforeend', message)
      }
    })

  })
})