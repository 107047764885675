import { Controller } from '@hotwired/stimulus';
import { useAfterAjax } from 'lib/use-after-ajax';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['list', 'conversationItem', 'input'];

  connect() {
    this.inputTarget.focus();
    useAfterAjax(this);
  }

  disconnect() {
    console.log('Closed');
  }

  afterAjaxSuccess() {}

  search(event) {
    const value = event.target.value.trim();

    if (!value.length) {
      this.listTarget.innerHTML = '';
      return;
    }

    $.ajax({
      type: 'GET',
      url: this.data.get('src'),
      data: {
        q: event.target.value,
      },
      success: (response) => {
        const hasResults = response.trim().length;

        this.listTarget.innerHTML = hasResults
          ? response
          : `<div class='placeholder'>No results for '${value}'</div>`;
      },
      error: (error) => {
        console.log('new-conversation#search error', error);
      },
    });
  }
}
