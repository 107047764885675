import { Controller } from '@hotwired/stimulus';
import { toggleHidden } from '../src/brandless/utils';

export default class extends Controller {
  static targets = ['hidden', 'displayed'];

  display() {
    toggleHidden(this.hiddenTarget, true);
    toggleHidden(this.displayedTarget, false);
  }

  hide() {
    toggleHidden(this.hiddenTarget, false);
    toggleHidden(this.displayedTarget, true);
  }
}
