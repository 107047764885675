import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['topic', 'continue'];

  connect() {
    this.continueTarget.disabled = true;
  }

  onChange(e) {
    const form = this.continueTarget.form;

    const selectedTopics = this.topicTargets
      .map((checkbox) => {
        if (checkbox.checked) {
          return checkbox.value;
        }
      })
      .filter((topic) => topic);

    this.continueTarget.disabled = selectedTopics.length < 1;

    const chainParams = {
      prompt: 'login',
      screen_hint: 'signup',
      chain_params: {
        chain_origin: '/',
        chain_action: 'topic_cloud',
        chain_action_params: JSON.stringify({ topics: selectedTopics }),
      },
    };

    const formUrl = new URL(form.action);
    const queryString = $.param(chainParams);

    form.action = formUrl.pathname + '?' + queryString;
  }
}
