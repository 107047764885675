import { register, upgrade } from './ujs';

/**
 * Makes a component marked with [data-dismissable] dismissable from the DOM.
 *
 * Notifications, recommendations and other such items can be dismissed, by
 * pressing a button. Dismissing means that the item is removed from the page.
 *
 * In order to make an element dismissable, add the [data-dismissable] attribute
 * to the boundary (the element), and [data-action="dismiss"] to the button that
 * should dismiss the element.
 *
 * @example
 *
 * <section data-dismissable>
 *   <span>Some content</span>
 *   <button data-action="dismiss">Hide</button>
 * </section>
 */
export class Dismissable {
  private readonly actions: NodeListOf<HTMLElement>;

  constructor(private readonly component: HTMLElement) {
    this.actions = component.querySelectorAll<HTMLElement>(
      '[data-action~="dismiss"]'
    )!;

    this.dismiss = this.dismiss.bind(this);
    this.destroy = this.destroy.bind(this);

    this.actions.forEach((action) =>
      action.addEventListener('click', this.dismiss)
    );
  }

  public destroy(): void {
    this.actions.forEach((action) =>
      action.removeEventListener('click', this.dismiss)
    );
  }

  public dismiss(e?: Event): void {
    e?.preventDefault();

    this.component.remove();
  }
}

export function dismissables(): void {
  upgrade('[data-dismissable]');
}

export function dismissable(component: HTMLElement): Dismissable {
  return new Dismissable(component);
}

register('[data-dismissable]', ({ element }) => dismissable(element).destroy);
