import { onPageLoad } from './spa';
import { isHtmlElementNode, toggleHidden } from './utils';

/**
 * Initializes a cookie banner, annotated as [data-component="cookie-banner"].
 *
 * The cookie banner should have the class js-only, if its handler (accept, ok)
 * can only be executed if JavaScript is available. The cookie banner should
 * have [data-turbolinks-permanent] and a stable id, in order to make its state
 * permanent accross page navigation events.
 */
function initialize(): void | (() => void) {
  const cookie = document.querySelector<HTMLElement>(
    '[data-component="cookie-banner"]'
  );

  if (!cookie || cookie.hidden) {
    return;
  }

  const currentFocus = document.activeElement;

  /**
   * Accept the cookie. This instantly hides the cookie banner, and marks the
   * cookie as accepted. If the [data-action-accept] element has more event
   * handlers attached, they will be executed as well (because default isn't
   * prevented and propagation is not stopped).
   */
  const onSubmit = (): void => {
    toggleHidden(cookie, true);

    if (isHtmlElementNode(currentFocus)) {
      currentFocus?.focus();
    }
  };

  const submitButtons = cookie.querySelectorAll('[data-action="submit"]')!;

  submitButtons.forEach((submit) => {
    submit.addEventListener('click', onSubmit);
  });

  cookie.focus();

  return function cleanup(): void {
    submitButtons.forEach((submit) => {
      submit.removeEventListener('click', onSubmit);
    });
  };
}

onPageLoad(initialize);
