import { Controller } from '@hotwired/stimulus';

const dispatchEvent = (element, name, detail = {}) => {
  const init = { bubbles: true, cancelable: true, detail: detail };
  const evt = new CustomEvent(name, init);
  element.dispatchEvent(evt);
};

export default class extends Controller {
  dispatch() {
    const name = this.data.get('name');

    const detail = {};

    Object.keys(Object.assign({}, this.element.dataset))
      .filter((attr) => attr.startsWith('eventDetail'))
      .forEach((attr) => {
        const key = attr.slice(11).replace(/^\w/, (c) => c.toLowerCase());
        detail[key] = this.element.dataset[attr];
      });

    // console.log('detail', detail)
    dispatchEvent(document, name, detail);
  }
}
