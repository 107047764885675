import onmount from 'onmount'
import $ from 'jquery'

onmount('.js-new-module-form-wrapper', function(mountEvent) {
  const wrapper      = $(this)
  const form         = wrapper.find('.js-new-module-form')
  const cancelButton = wrapper.find('.js-cancel-new-module-button')

  // Do not send the form if no module type has been chosen.
  form.on('ajax:before', function(submitEvent) {
    if (form.find('#channel_page_module_module_type').val() == '') {

      submitEvent.preventDefault()

      // Un-Disable the submit button.
      form.find('[name=commit]').prop('disabled', false)
    }
  })

  form.on('ajax:success', function(response) {
    const precedeModuleID = form.find('.js-precede-module-id').val()
    const newModule       = response.detail[0].html

    // Place after all other modules if request made from global Add-button.
    if (precedeModuleID == '-1') {
      $('.page-modules').append(newModule)
    }
    // Place before existing module if request made from module Insert-button.
    else {
      $('#page-module-' + precedeModuleID).before(newModule)
    }

    // Run onmount again to pick up new module added to the page.
    onmount()

    wrapper.remove()
  })

  cancelButton.on('click', function(clickEvent) {
    clickEvent.preventDefault()

    wrapper.addClass('hidden')
  })

})
